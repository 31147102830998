import * as React from 'react'
import { useParams } from 'react-router';

import { Tabs } from 'app2/components';

import { EnrollmentRouteParams } from '../organizerRoutes';
import { OrganizerPage } from '../shared';

import { Participants } from './Participants';
import { Students } from './Students';

export function Families() {
  const { site: siteSlug } = useParams<EnrollmentRouteParams>();

  return <OrganizerPage title='Families'>
    <Tabs width="100%" baseUrl={`/organizer/${siteSlug}/families/:tab`} urlParameter='tab' arrows tabs={[
      {name:'students', label:'Students', content: <Students /> },
      {name:'contacts', label:'Contacts', content: <Participants /> }
    ]} />
  </OrganizerPage>
}
