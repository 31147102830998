import * as React from 'react';

import { CheckboxField, Panel, RepeatingSection, Field, FormModel, InputField, Section, useForm } from 'app2/components';
import { useSiteWithTrackingParametersQuery } from 'app2/views/shared'

import { useSiteQuery } from '../shared';

import { organizerSiteUpdateGeneralInfo } from './gql'

export function TrackingParameters() {
  const { site, id } = useSiteQuery(useSiteWithTrackingParametersQuery);
  const form = useForm(site, [site], {assignIds: false});
  
  function render() {
    return <Panel icon="Code" title="Source tracking" type="toggle" form={form} onOk={onOk}>
      <Section label='Enable source tracking' name='usingTrackingParameters' component={CheckboxField} />
      <RepeatingSection name='trackingParameters' add='Add tracking source' fields={[
        <Field label='Name' name='name' component={InputField} />,
        <Field label='Parameter' name='value' component={InputField} />,
        'remove'
      ]} />
      
    </Panel>
  }

  async function onOk(form:FormModel) {
    const [success] = await organizerSiteUpdateGeneralInfo({variables: {siteId: id, usingTrackingParameters: form.values.usingTrackingParameters, trackingParameters: form.values.trackingParameters}});

    return success;
  }
  
  return render();
}
