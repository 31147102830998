import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DomainAuthsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type DomainAuthsQuery = { homeroom?: { domainAuths?: Array<{ id: string; domain: string; provider: Types.DomainOauthProvider }> | null | undefined } | null | undefined };

export const DomainAuthsDocument = gql`
  query DomainAuths {
    homeroom {
      domainAuths {
        id
        domain
        provider
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type DomainAuthsArgs = MakeOptional<UseQueryArgs<DomainAuthsQueryVariables, DomainAuthsQuery>, 'query'>;

export function useDomainAuthsQuery(options: DomainAuthsArgs = {}) {
  return useQuery<DomainAuthsQueryVariables, DomainAuthsQuery>({ query: DomainAuthsDocument, ...options });
}

export type DomainAuthsOptions = ExecQueryOptions<DomainAuthsQueryVariables>;

export function domainAuths(options: DomainAuthsOptions) {
  return executeQuery<DomainAuthsQuery, DomainAuthsQueryVariables>(DomainAuthsDocument, options);
}

export type DomainAuthsSelections = DomainAuthsQuery['homeroom'];
