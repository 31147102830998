import * as React from 'react';

import { Course } from 'app2/api'
import { HBox, Link, FieldInfo, TextProps } from 'app2/components'

import { RouteRole, useRouteContext } from '../page'

import { CourseStatusTag } from './CourseStatusTag';
import { OptionalCourseTag } from './OptionalCourseTag';

type CourseWithName = Partial<Pick<Course, 'name' | 'disambiguatedName' | 'status' >>
type LinkCourse = Pick<Course, 'id'> & CourseWithName;

export interface CourseLinkProps extends TextProps {
  info?:FieldInfo<LinkCourse>;
  course?:LinkCourse;
  product?:LinkCourse;
  value?:any;
  role?: 'organizer' | 'public';
  suffix?:string;
  state?:any;
  // if this is part of course options, this will display an option tag
  option?:boolean;
  status?:boolean;
}

export function CourseLink(props:CourseLinkProps) {
  const {info, course:courseProp, product, value, role, suffix, option, children, status, display, maxWidth, ...remaining} = props;
  const course = courseProp || product || info?.record;
  const courseName = children ? children : typeof value == 'string' ? value : (course as CourseWithName)?.disambiguatedName || (course as CourseWithName)?.name;

  const url = getUrl();

  function render() {
    return <HBox gap='$4' vAlign='center' display={display} maxWidth={maxWidth}>
      <Link className='hr-value' text='body' to={`${url}/${course?.id}${suffix || ''}`} alignItems='left' display={display} {...remaining}>{courseName}</Link>
      {status && <CourseStatusTag status={course.status} small />}
      {option && <OptionalCourseTag />}
    </HBox>
  }

  function getUrl() {
    const route = useRouteContext();
    let roleToUse = role;

    if (!roleToUse) {
      roleToUse = route.routeRole == RouteRole.site || route.routeRole == RouteRole.company || route.routeRole == RouteRole.course ? 'organizer' : 'public';
    }

    return roleToUse == 'public' ? '/activities' : '/activities/manage';  
  }
  
  return render();
}

CourseLink.fieldProps = {
  fieldProperty: 'info',
  valueProperty: 'value'
}

CourseLink.defaultProps = {
  role: 'organizer'
}
