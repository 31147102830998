import { colId } from 'app2/components';

import { FieldWithOverrides } from './FieldWithOverrides';

export type FieldMap = Record<string, FieldWithOverrides>;
export type FieldArray = (string | FieldWithOverrides | FieldMap)[];
export type FieldList = FieldArray | FieldMap;

// fields can be handed off to useCols/useFields/getCols as:
//
//  ['amount', 'startDate']
//  or
//  {balance: {...CurrencyField, etc}}
//  or
//  a mixture of both ['amount', 'startDate', {balance: {...CurrencyField, etc}}]
//
//  this normalizes the input

export function normalizeFields(fieldList:FieldList):{paths:string[], fields:FieldMap} {
  let fields:FieldMap;

  if (Array.isArray(fieldList)) {
    fields = {}
    fieldList.forEach(nameOrFieldOrMap => {
      if (typeof nameOrFieldOrMap == 'string') {
        fields[nameOrFieldOrMap] = null
      }
      else
      if (nameOrFieldOrMap.name) {
        fields[colId(nameOrFieldOrMap)] = nameOrFieldOrMap;
      }
      else {
        for (const name in nameOrFieldOrMap) {
          const field:FieldWithOverrides = nameOrFieldOrMap[name];
          fields[colId(field) || name] = field;
        }
      }
    });
  }
  else {
    fields = fieldList;
  }

  const paths = Object.keys(fields);

  return {paths, fields}
}
