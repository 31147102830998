import { castArray } from 'lodash-es';

import { CompanyRole, OrganizerRole, CourseRole } from 'app2/api';

import { Roles, RouteProps } from './Route';

// since routes can be assigned many roles, this type represents the translation of the implied role from their combination
// such has company admin and company.member will be translated to company
// ... existing code ...

export enum RouteRole {
  'admin',
  'company',
  'site',
  'course', // this is course management, not public course
  'family',
  'public',
}

const routeToRole = new Map<string, RouteRole>();

export function getRouteRole(route:React.ReactElement<RouteProps>) {
  const path = route.props.path as string;
  let routeRole = routeToRole.get(path);

  if (!routeRole) {
    routeRole = rolesToRouteRole(route?.props?.role);
    routeToRole.set(path, routeRole);
  }

  return routeRole;
}

function rolesToRouteRole(roleOrRoles:Roles[] | Roles):RouteRole | undefined {
  if (!roleOrRoles) {
    return;
  }

  const roles = castArray(roleOrRoles);
  const topLevelRoles = new Set<RouteRole>();

  roles.forEach(r => topLevelRoles.add(roleToRouteRole[r]));

  return topLevelRoles.size != 1 ? undefined : Array.from(topLevelRoles)[0];
}

const roleToRouteRole:Record<Roles, RouteRole> = {
  'admin': RouteRole.admin,
  'parent': RouteRole.family,
  [CompanyRole.CompanyAdmin]: RouteRole.company,
  [CompanyRole.CompanyMember]: RouteRole.company,
  [OrganizerRole.Coordinator]: RouteRole.site,
  [OrganizerRole.SiteMember]: RouteRole.site,
  [CourseRole.Admin]: RouteRole.course,
  [CourseRole.Member]: RouteRole.course,
  'logged-in': RouteRole.public,
   'logged-out': RouteRole.public
};