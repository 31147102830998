import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonPayoutConfigQueryVariables = Types.Exact<{
  season: Types.Scalars['ID'];
}>;

export type SeasonPayoutConfigQuery = { season?: { payoutConfig: { timing?: Types.PayoutTiming | null | undefined } } | null | undefined };

export const SeasonPayoutConfigDocument = gql`
  query SeasonPayoutConfig($season: ID!) {
    season(season: $season) {
      payoutConfig {
        timing
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SeasonPayoutConfigArgs = MakeOptional<UseQueryArgs<SeasonPayoutConfigQueryVariables, SeasonPayoutConfigQuery>, 'query'>;

export function useSeasonPayoutConfigQuery(options: SeasonPayoutConfigArgs = {}) {
  return useQuery<SeasonPayoutConfigQueryVariables, SeasonPayoutConfigQuery>({ query: SeasonPayoutConfigDocument, ...options });
}

export type SeasonPayoutConfigOptions = ExecQueryOptions<SeasonPayoutConfigQueryVariables>;

export function seasonPayoutConfig(options: SeasonPayoutConfigOptions) {
  return executeQuery<SeasonPayoutConfigQuery, SeasonPayoutConfigQueryVariables>(SeasonPayoutConfigDocument, options);
}

export type SeasonPayoutConfigSelections = SeasonPayoutConfigQuery['season'];
