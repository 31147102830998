import * as React from 'react';
import pluralize from 'pluralize';

import { CourseKind, CourseUtils, EnrollmentStatusFilter, authorizedCoursePreferenceKeys, RateType, RosterGroupingKind } from 'app2/api';
import { DataTable, DataTableColumnSort, HBox, Icon, Link, MenuItem, useRef, VBox } from 'app2/components';
import { byActivity, CopyParentEmailsAction, downloadRoster, ContentType, GeneratedQueryHook, GeneratedQueryArgs, ReportView, ViewAttribute } from 'app2/views/shared';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CreateMessageAction, GroupActionButton } from '../../../roster';
import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { AddStudentAction, ChangeRecurringsAction, ChangeSeasonPlanAction, CustomChargeAction, renderMoveButton, RefundButton, VoidButton, renderRemoveStudentsButton, renderResendStudentsButton, ReenrollAction } from '../actions';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { DistinctEnrollmentsSelections, useDistinctEnrollmentsQuery, useBilledEnrollmentsQuery, useBilledAdvancedEnrollmentsQuery, useBilledUsageEnrollmentsQuery } from './gql';
import { BilledLaterUsage } from './BilledLaterUsage';
import { BilledLaterAdvanced } from './BilledLaterAdvanced';
import { RoundingIncrement } from './RoundingIncrement';
import { GracePeriod } from './GracePeriod';


const TABLE_PREFS_VERSION = '9';

interface Props {
  course: CourseSelections;
  billed?:boolean;
}

enum TableMode {
  enrolled,
  billed
}

export function Enrolled(props: Props) {
  const seasonId = props.course?.season?.id;
  const isLesson = props.course?.kind == CourseKind.LessonSet;
  const billingConfig = getBillingConfig();
  const cols = getCols();
  const tableRef = useRef<DataTable<DistinctEnrollmentsSelections>>();
  const basicPricing = !CourseUtils.hasBilledLaterRates(props.course?.rates) && !CourseUtils.hasBilledLaterEnrollments(props.course);
  const behavior = courseKindBehavior[props.course.kind];
  const behaviors = props?.course.behavior;

  function render() {
    return (
      <>
        <EnrollmentsTable<DistinctEnrollmentsSelections>
          course={props.course}
          header={{
            id: props.billed ? 'billed' : undefined,
            icon: props.billed ? null : 'Users',
            title: renderTitle(),
            'aria-label': renderAriaLabel(),
            units: renderUnits(),
            options: renderOptions(),
            primaryActions: renderPrimaryActions(),
            secondaryActions: renderSecondaryActions(),
            subtitle: renderSubtitle()
          }}
          table={{ cols, ref: tableRef, none: renderNone() }}
          view={{table:'course-enrolled'}}
          prefsVersion={TABLE_PREFS_VERSION}
          prefsKey={!props.billed ? authorizedCoursePreferenceKeys.paidEnrollmentsTable : billingConfig.prefsKey}
          queryHook={!props.billed ? useDistinctEnrollmentsQuery : billingConfig.queryHook}
          queryOptions={billingConfig.queryOptions}
        />
        {renderBilledLater()}
      </>
    );
  }

  function renderTitle() {
    return !props.billed 
      ? 'Enrolled' 
      : !billingConfig.billedLater || basicPricing
        ? 'Billed'
        : <>Billed<Link ml='$20' text='body' to='billing#billed-later'>Billed later<Icon name='ArrowDown' size='small' /></Link></>;
  }

  function renderAriaLabel() {
    return !props.billed ? renderTitle() as string : 'Billed'
  }

  function renderUnits() {
    return !props.billed ? behavior.terms.participant || 'student' : 'purchases';
  }

  function renderNone() {
    return !props.billed || !props.course?.hasEnrolledStudents ? 'No enrolled students' : 'No billed students';
  }

  function renderBilledLater() {
    if (!props.billed || !billingConfig.billedLater || basicPricing) {
      return <></>;
    }

    const BilledLater = billingConfig.billedLater;

    return <BilledLater course={props.course} />
  }

  function renderPrimaryActions() {
    const enrolled = [
      <AddStudentAction course={props.course} />
    ];

    return actionsForTableMode({ enrolled });
  }

  function renderOptions() {
    const enrolled = [
      !isLesson && behaviors.attendanceLink && <MenuItem label="Instructor attendance" to={`/activities/${props.course?.token}/attendance`} />,
      <CopyParentEmailsAction table={tableRef} />,
      <MenuItem label="Download roster PDF" onClick={handleDownloadRosterPDF} />,
      <MenuItem label="Download CSV" onClick={handleDownloadCSV} />
    ]

    const billed = [
      <CopyParentEmailsAction table={tableRef} />,
    ]

    return actionsForTableMode({ enrolled, billed });
  }

  function actionsForTableMode({ enrolled, billed }: { enrolled?: JSX.Element[]; billed?: JSX.Element[] }) {
    enrolled = enrolled || [];
    billed = billed || [];

    const actions = {
      [TableMode.enrolled]: enrolled,
      [TableMode.billed]: billed
    };

    return actions[getTableMode()];
  }

  function renderSecondaryActions() {
    if (!props.course) {
      return [];
    }

    const mode = getTableMode();

    const enrolled = [
      <ChangeRecurringsAction table={tableRef.current} course={props.course} />,
      <ChangeSeasonPlanAction table={tableRef.current} course={props.course} />,
      renderRemoveStudentsButton(props.course, tableRef.current),
      renderMoveButton(props.course, tableRef.current),
      renderResendStudentsButton(props.course, tableRef.current),
      behaviors.enrollmentsSetGroups && <GroupActionButton table={tableRef} season={seasonId} />,
      <CreateMessageAction course={props.course} table={tableRef} />,
      <CustomChargeAction course={props.course} table={tableRef.current} />,
    ].filter(Boolean)

    const billed = [
      <RefundButton course={props.course} table={tableRef.current} />,
      <VoidButton course={props.course} table={tableRef.current} />,
      renderRemoveStudentsButton(props.course, tableRef.current),
      <ReenrollAction course={props.course} table={tableRef.current as DataTable<any>} />
    ].filter(Boolean)

    const actions = {
      [TableMode.enrolled]: enrolled,
      [TableMode.billed]: billed
    }

    return actions[mode];
  }

  function renderSubtitle() {
    if (props.billed) {
      return (
        <VBox gap="$8">
          <RoundingIncrement course={props.course} />
          <GracePeriod course={props.course} />
        </VBox>
      );
    }

    return renderCartedEnrollments();
  }

  function renderCartedEnrollments() {
    const count = props.course?.cartedCount;

    if (!count) {
      return;
    }

    return <HBox text='body' vAlign='center' mt='-14px' gap='$8'><Icon name='ShoppingCart' />{count} {pluralize('family', count)} {pluralize('has', count)} this activity in their cart.</HBox>
  }

  function getCols() {
    const modeCols = {
      [TableMode.enrolled]:!isLesson ? enrolledCols : timeSlotCols,
      [TableMode.billed]:billingConfig.cols,
    }

    const cols = useCourseEnrollmentCols<DistinctEnrollmentsSelections>(modeCols[getTableMode()], props.course, getRosterType(), !props.billed, { name: 'billedDate', sort: DataTableColumnSort.descending });

    return cols;
  }

  function getTableMode() {    
    return !props.billed ? TableMode.enrolled : TableMode.billed;
  }

  function getBillingConfig() {
    const priceType = CourseUtils.getRateOrPriceType(props.course?.rates);

    if (priceType == RateType.basic || (CourseUtils.getValidConfigurableSeasonRatesOrPrices(props.course?.rates).length && !CourseUtils.getValidRecurringRatesOrPrices(props.course?.rates).length)) {
      return billingConfigs.season;
    }
  
    if (priceType == RateType.advanced) {
      return billingConfigs.recurring;
    }
  
    return billingConfigs.usage;
  }

  function handleDownloadRosterPDF() {
    // pdf uses byactivity to give a roster and uses byactivity columns not the current display columns
    return downloadRosterHelper(ContentType.PDF, byActivity, ['title', 'groups', 'cols']);
  }

  function handleDownloadCSV() {
    // csv is wysiwyget
    return downloadRosterHelper(ContentType.CSV);
  }

  function downloadRosterHelper(type:ContentType, view?:ReportView, viewAttributes?:ViewAttribute[]) {
    const variables = {groupingId: props.course?.id, groupingKind: RosterGroupingKind.Course, enrollmentStatus: getRosterType()};

    return downloadRoster(['Homeroom roster', props.course.name], type, 'rosterByGrouping', variables, tableRef.current, view, viewAttributes);
  }

  function getRosterType() {
    return props.billed ? EnrollmentStatusFilter.Billed : EnrollmentStatusFilter.RosteredAndInvited;
  }

  return render();
}

interface BillingConfigs {
  [key: string]: BillingConfig;
}

interface BillingConfig {
  prefsKey: string;
  queryHook: GeneratedQueryHook;
  queryOptions?: GeneratedQueryArgs;
  cols: string[];
  billedLater:React.ComponentType<any>;
}

const billingConfigs: BillingConfigs = {
  season: {
    prefsKey: authorizedCoursePreferenceKeys.billedEnrollmentsTable,
    queryHook: useBilledEnrollmentsQuery,
    cols: [
      'student.firstName',
      'student.lastName',
      'balance',
      'paymentService',
      'paymentStatus',
      'discountAmount',
      'discountCodes',
      'priceTier',
      'refundsTotal',
      'rosterPeriod',
      'billPeriod',
      'billedDate',
      'note',
      'student.grade',
      'student.age',
      'groups'
    ],
    billedLater: BilledLaterAdvanced
  },
  recurring: {
    prefsKey: authorizedCoursePreferenceKeys.billedAdvancedEnrollmentsTable,
    queryHook: useBilledAdvancedEnrollmentsQuery,
    cols: [
      'student.firstName',
      'student.lastName',
      'billingDescription',
      'paymentStatus',
      'paymentService',
      'rosterPeriod',
      'billPeriod',
      'billedDate',
      'discountAmount',
      'discountCodes',
      'priceTier',
      'balance',
      'refundsTotal',
      'note',
      'student.grade',
      'student.age',
      'groups'
    ],
    billedLater: BilledLaterAdvanced
  },
  usage: {
    prefsKey: authorizedCoursePreferenceKeys.billedUsageEnrollmentsTable,
    queryHook: useBilledUsageEnrollmentsQuery,
    queryOptions: { variables: { limit: 1000 } },
    cols: [
      'student.firstName',
      'student.lastName',
      'usageDate',
      'balance',
      'refundsTotal',
      'paymentStatus',
      'paymentService',
      'billedDate',
      'priceConfig.sessionStart',
      'priceConfig.sessionEnd',
      'usageCheckedInAt',
      'usageCheckedOutAt',
      'priceConfig.timeInCourse',
      'priceConfig.timeBilled',
      'usageRate',
      'listPrice',
      'discountAmount',
      'purchaseAmount',
      'roundingIncrement',
      'gracePeriod',
      'priceConfig.formattedOverlapHandling',
      'nonBillableTime',
      'discountCodes',
      'priceTier',
      'note',
      'student.grade',
      'student.age',
      'groups'
    ],
    billedLater:BilledLaterUsage
  }
}

const enrolledCols = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'student.age',
  'student.dob',
  'rosterPeriods',
  'added',
  'student.classroom.displayName',
  'pickup',
  'dismissal',
  'parent.name',
  'parent.phone',
  'parent.email',
  'overlaps',
  'groups'
];

const timeSlotCols = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'student.age',
  'student.dob',
  'course.courseDays', 
  'rosterPeriods',
  'course.teacher.name',
  'added',
  'student.classroom.displayName',
  'pickup',
  'dismissal',
  'parent.name',
  'parent.phone',
  'parent.email',
  'overlaps',
  'groups',
];
