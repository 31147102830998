import * as React from 'react';

import { Form, useFormInfo } from 'app2/components';
import { useCurrentUser, TOSField } from 'app2/views/shared-public';

import { acceptTos } from './generated'

export function AcceptTOS() {
  const {user} = useCurrentUser();
  const outerForm = useFormInfo();

  function render() {
    if (!outerForm?.editing) {
      return <></>;
    }

    if (user.tosAccepted) {
      return <></>;
    }

    return <Form onOk={onOk} onNavigation='nothing'>
      <TOSField />
    </Form>
  }

  async function onOk() {
    const [result]  = await acceptTos({variables:{}});
    return result;
  }

  return render();
}
