import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
export type FamilyAttendanceFieldsFragment = {
  count?: number | null | undefined;
  items?:
    | Array<{
        id: string;
        date?: string | null | undefined;
        sessionDateInTz?: string | null | undefined;
        checkedIn?: Types.CheckedIn | null | undefined;
        formattedCheckedInTime?: string | null | undefined;
        checkedOut?: boolean | null | undefined;
        formattedCheckedOutTime?: string | null | undefined;
        formattedSessionStartTime: string;
        formattedSessionEndTime: string;
        paymentStatus?: Types.FamilyPaymentStatus | null | undefined;
        student?: { id: string; nameWithNickname: string } | null | undefined;
        checkedInBy?:
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name: string }
          | { name?: string | null | undefined }
          | null
          | undefined;
        checkedOutBy?:
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name?: string | null | undefined }
          | { name: string }
          | { name?: string | null | undefined }
          | null
          | undefined;
        enrollment: { id: string; course: { id: string; name?: string | null | undefined } };
        usageInstance?:
          | {
              listPrice: number;
              discountAmount: number;
              purchaseAmount: number;
              refundsTotal: number;
              balance: number;
              priceConfig?: { formattedNonBillableTime: string; listRate?: number | null | undefined; timeInCourse: string; timeBilled: string; billingDate?: string | null | undefined } | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export const FamilyAttendanceFieldsFragmentDoc = gql`
  fragment familyAttendanceFields on AttendancesQueryResults {
    count
    items {
      id
      student {
        id
        nameWithNickname
      }
      date
      sessionDateInTz
      checkedIn
      formattedCheckedInTime
      checkedInBy {
        name
      }
      checkedOut
      formattedCheckedOutTime
      checkedOutBy {
        name
      }
      formattedSessionStartTime
      formattedSessionEndTime
      enrollment {
        id
        course {
          id
          name
        }
      }
      usageInstance {
        listPrice
        discountAmount
        purchaseAmount
        refundsTotal
        balance
        priceConfig {
          ... on UsagePriceConfig {
            formattedNonBillableTime
            listRate
            timeInCourse
            timeBilled
            billingDate
          }
        }
      }
      paymentStatus
    }
  }
`;
