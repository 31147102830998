import * as React from 'react';

import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

import { Button, sleep } from 'app/app2/components';
import { Module } from 'app2/views';

import { useCurrentSite } from '../shared';

import { MutatedSeason, SeasonForm } from './SeasonForm';

export function CreateSeason() {
  const history = useHistory();
  const match = useRouteMatch();
  const [showingForm, setShowForm] = React.useState(false);
  const { site } = useCurrentSite();
  const season = React.useMemo(() => ({blockOverlappingEnrollments: site?.blockOverlappingEnrollmentsDefault}), [site]);

  function showForm() {
    setShowForm(true);
  }

  function hideForm() {
    setShowForm(false);
  }

  async function onOk(season: MutatedSeason) {
    hideForm();

    // we need the form to finish hiding, else if we navigate
    // while its still visible the user will get prompted to
    // save changes before navigating a way
    
    await sleep(10);
    
    const url = generatePath(match.path, {
      site: match.params.site,
      season: season.id,
      tab: 'details'
    });
    history.push(url + '#activities&remove');
  }

  return (
    <Module title="Create season" subtitle={showingForm ? undefined : 'Start here to build a new season.'}>
      {showingForm
        ? <SeasonForm title="Create a season" type="edit" onCancel={hideForm} create season={season} parentOnOk={onOk} site={site} scrollOnSave={false} />
        : <Button icon="Plus" iconPosition="left" onClick={showForm}>Create season</Button>
      }
    </Module>
  );
}
