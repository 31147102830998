import * as React from 'react';
import { O } from 'ts-toolbelt'
import { useHistory } from 'react-router';

import { Course, Season } from 'app2/api';
import { buildCloudinaryImageProps, BoxProps, VBox, HBox, Img, MultiContext, BreakpointInfo } from 'app2/components';
import { COURSE_CARD_IMAGE_RATIO, DEFAULT_COURSE_CARD_IMG } from 'app2/views/shared-public';

import { CartCourse, Cart, CourseShareButton, courseKindBehavior } from '../..';

import { AddToCart, AddToCartProps } from './AddToCart';
import { CourseAndCompanyName, CourseCapacity, CourseCategory, CourseGrades, CoursePricesWithIcon, CourseDays, CourseDates, CourseWithPrices, CourseBreakdowns } from '.';

export type CourseCardCourse = Pick<Course, 
  'id' |
  'courseTags' |
  'courseCardImage' |
  'name' |
  'courseDays' |
  'classesCount' |
  'maxCapacity' |
  'grades' |
  'startDate' |
  'endDate' |
  'closed' |
  'waitlistOpen' |
  'searchable' |
  'description'
  > & Partial<O.P.Pick<Course, ['vendor', 'name']>> & Partial<O.P.Pick<Course, ['company', 'name' | 'id']>> 
    & Partial<O.P.Pick<Course, ['season', 'kind' | 'registrationDisplayConfig']>> 
    & CourseWithPrices
    & CartCourse
    & {children?:CourseCardCourse[]}
    & {teacher?:{name?:string}}
    & {site?:{branding?:{logo?:string}}};

export interface CourseInfoProps extends BoxProps {
  course: CourseCardCourse;
  displayConfig?: Season['registrationDisplayConfig']
  kind?:'course-page' | 'card' | 'card-preview' | 'course-option';
  size?:'auto' | 'tile' | 'wide';

  // for registration page
  cart?:Cart;
  siteCompanies?:{id:string}[];
  partner?:boolean;
  
  // for the course preview
  breakdowns?: CourseBreakdowns;

  addToCart?:AddToCartProps['addToCart'];
  primaryActions?:React.ReactElement[];
  variantFilter?:(c:CourseCardCourse) => boolean;
}

export function CourseInfo(props: CourseInfoProps) {
  const {course, kind, size, cart, siteCompanies, partner, breakdowns: prices, addToCart, primaryActions, variantFilter, displayConfig:propsDisplayConfig, ...remaining} = props;

  const behavior = courseKindBehavior[course.kind];
  const displayConfig = props.displayConfig || props.course?.season?.registrationDisplayConfig;
  const isSiteCompany = siteCompanies && course?.company && siteCompanies.find(c => c.id == course.company.id)
  const showCompany = (!isSiteCompany || behavior.showCompanyName) && kind != 'course-option';

  // potentially force into mobile layout for activity preview
  const context = React.useContext<BreakpointInfo>(MultiContext);
  const breakpoint = displayConfig?.layout == 'row' && props.kind == 'card-preview' ? 0 : context.breakpoint;
  
  const description = kind == 'course-option';

  const history = useHistory();

  function render() {
    return (displayConfig?.layout == 'tile' && size != 'wide') || size == 'tile'
      ? renderTile()
      : renderWide();
  }

  function renderTile() {
    return <VBox data-test={course.name} {...remaining}>
      {renderTiledCourseHeader()}
      <VBox width='100%' flex={1} onClick={navigateToCourse} cursor='pointer'>
        <HBox mb="$12">
          {renderCourseImage()}
          <CourseAndCompanyName course={course} showCompany={showCompany} kind={props.kind} />
        </HBox>
        {renderDatesDays()}
        {partner === false && <CourseDates course={course} icon={false} />}
        {behavior.showCapacity && <CourseCapacity course={course} />}
        <CoursePricesWithIcon course={course} breakdowns={props.breakdowns} />
        {description && <HBox mb='$8' text='body'>{course.description}</HBox>}
        <HBox flex={1} />
      </VBox>
      {renderPrimaryActions('either', '100%')}
    </VBox>
  }

  function renderTiledCourseHeader() {
    return <HBox gap='$8' width='100%'>
      <CourseCategory course={course} />
      <CourseGrades course={course} />
      <HBox flex={1} />
      {kind != 'course-option' && <CourseShareButton course={course} icon />}
    </HBox>
  }

  function renderCourseImage() {
    //       {props.kind != 'course-page' && <Img mr="$12" {...buildCloudinaryImageProps(course?.courseCardImage || DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO, 184, 3)} />}
    //{props.kind != 'course-page' && <Img mr="$12" {...buildCloudinaryImageProps(course?.courseCardImage || DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO, 145, 3)} />}

    return props.kind != 'course-page' && <Img mr="$12" {...buildCloudinaryImageProps(course?.courseCardImage || course?.site?.branding?.logo || DEFAULT_COURSE_CARD_IMG, COURSE_CARD_IMAGE_RATIO, 145, 3)} />
  }
  
  function renderWide() {
    return <VBox data-test={course.name} {...remaining}>
      <HBox gap='$8' width='100%'>
        <CourseCategory course={course} />
        <CourseGrades course={course} />
        <HBox flex={1} />
        {kind != 'course-option' && <CourseShareButton course={course} icon />}
      </HBox>
      {breakpoint != 0 ? renderWideDesktop() : renderWidePhone()}
    </VBox>
  }

  function renderWideDesktop() {
    if (kind == 'course-page') {
      return renderWidePhone();
    }

    // the negative margin allows the card info to overlap the add button 
    // a little using up some white space that wouldn't normally be used

    return <HBox width='100%' vAlign='top'>
      {renderCourseImage()}
      <VBox flex={1} mr='$12' onClick={navigateToCourse} cursor='pointer'>
        <VBox flex={1} mb='$12'>
          <CourseAndCompanyName course={course} showCompany={showCompany} kind={props.kind} />
        </VBox>
        {renderDatesDays('-100px')}
        {behavior.showCapacity && <CourseCapacity course={course} />}
        <CoursePricesWithIcon course={course} breakdowns={props.breakdowns} mb={description ? '$8' : 0} />
        {description && <HBox mb='$8' text='body'>{course.description}</HBox>}
        {renderPrimaryActions('variants')}
      </VBox>
      {renderPrimaryActions('no-variants')}
    </HBox>
  }

  function renderWidePhone() {
    return <VBox height='100%' width='100%' flex={1}>
      <VBox width='100%' flex={1} onClick={navigateToCourse} cursor='pointer'>
        <HBox mb="$12" width='100%' vAlign='top'>
          {renderCourseImage()}
          <VBox flex={1} mr='$12'>
            <CourseAndCompanyName course={course} showCompany={showCompany} kind={props.kind} />
          </VBox>
        </HBox>
        {renderDatesDays()}
        {behavior.showCapacity && <CourseCapacity course={course} />}
        <CoursePricesWithIcon course={course} breakdowns={props.breakdowns} />
        {description && <HBox mb='$8' text='body'>{course.description}</HBox>}
        <HBox flex={1} />
        {renderPrimaryActions('variants')}
      </VBox>
      {renderPrimaryActions('no-variants')}
    </VBox>
  }

  function renderDatesDays(mr?:string) {
    return displayConfig?.showDates && props.kind != 'course-option' 
      ? <CourseDates course={course} time mr={mr} /> 
      : behavior.sessionType != 'none' 
        ? <CourseDays course={course} mr={mr} />
        : undefined
  }

  function renderPrimaryActions(variantMode:'variants' | 'no-variants' | 'either', width?:string) {
    return <AddToCart key='add-to-cart' course={props.course} cart={props.cart} width={width} variantMode={variantMode} addToCart={addToCart} primaryActions={primaryActions} variantFilter={variantFilter} />
  }

  function navigateToCourse() {
    history.push('/activities/' + course.id);
  }

  return render();
}

CourseInfo.defaultProps = {
  kind: 'card'
}
