import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AdminImportOneRosterMutationVariables = Types.Exact<{
  site: Types.Scalars['ID'];
  file: Types.FileUploadAttributes;
  parentDefaults?: Types.InputMaybe<Types.Scalars['JSON']>;
  studentDefaults?: Types.InputMaybe<Types.Scalars['JSON']>;
}>;

export type AdminImportOneRosterMutation = { siteImportOneRoster?: { success?: boolean | null | undefined } | null | undefined };

export const AdminImportOneRosterDocument = gql`
  mutation AdminImportOneRoster($site: ID!, $file: FileUploadAttributes!, $parentDefaults: JSON, $studentDefaults: JSON) {
    siteImportOneRoster(site: $site, file: $file, parentDefaults: $parentDefaults, studentDefaults: $studentDefaults) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AdminImportOneRosterOptions = ExecMutationOptions<AdminImportOneRosterMutationVariables>;

export function adminImportOneRoster(options: AdminImportOneRosterOptions) {
  return executeMutation<AdminImportOneRosterMutation, AdminImportOneRosterMutationVariables>(AdminImportOneRosterDocument, options);
}
