import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type FamilyAttendanceFieldsFragment = {
  count?: number | null | undefined;
  items?:
    | Array<{
        id: string;
        date?: string | null | undefined;
        sessionDateInTz?: string | null | undefined;
        checkedInAt?: string | null | undefined;
        checkedOutAt?: string | null | undefined;
        student?: { id: string; nameWithNickname: string } | null | undefined;
        checkedInBy?: { name?: string | null | undefined } | { name?: string | null | undefined } | { name?: string | null | undefined } | { name: string } | { name?: string | null | undefined } | null | undefined;
        checkedOutBy?: { name?: string | null | undefined } | { name?: string | null | undefined } | { name?: string | null | undefined } | { name: string } | { name?: string | null | undefined } | null | undefined;
        enrollment: { id: string; course: { id: string; name?: string | null | undefined } };
        usageInstance?:
          | { amount?: number | null | undefined; priceConfig?: { formattedNonBillableTime: string; unit: Types.UsageUnit; unitsBilled?: number | null | undefined; rate?: number | null | undefined } | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export const FamilyAttendanceFieldsFragmentDoc = gql`
  fragment familyAttendanceFields on AttendancesQueryResults {
    count
    items {
      id
      student {
        id
        nameWithNickname
      }
      date
      sessionDateInTz
      checkedInAt
      checkedInBy {
        name
      }
      checkedOutAt
      checkedOutBy {
        name
      }
      enrollment {
        id
        course {
          id
          name
        }
      }
      usageInstance {
        amount
        priceConfig {
          ... on UsagePriceConfig {
            formattedNonBillableTime
            unit
            unitsBilled
            rate
          }
        }
      }
    }
  }
`;
