import * as React from 'react'
import { cloneDeep, remove, isEmpty } from 'lodash-es'

import { compact } from 'app2/api';
import { CheckboxField, DropdownField, Field, FormModel, Panel, RepeatingSection, Section, useForm } from 'app2/components';

import { OrganizerSeasonSelections } from '../../shared';

import { organizerUpsertSeason } from '../gql';

interface Props {
  season?: OrganizerSeasonSelections;
}

export function RegistrationPageSettings(props:Props) {
  const form = useForm(props.season?.registrationDisplayConfig, [props.season]);

  function render() {
    return <Panel title='Registration page' type='toggle' form={form} onOk={onOk}>
      <Section label='Group by' name='groups.0' component={DropdownField} options={groupOptions} maxWidth='500px' />
      <Section label='Sort'>
        <RepeatingSection name='sorts' add='Add sort' maxWidth='500px' numbered={false} assignIds={false} defaultRecord={null} fields={[
          <Field component={DropdownField} options={sortOptions} bind />,
          'remove'
        ]} />
      </Section>
      <Section label='Layout' name='layout' component={DropdownField} maxWidth='500px' options={layoutOptions} />
      <Section label='Show dates' name='showDates' component={CheckboxField} maxWidth='500px' />
    </Panel>;
  }

  async function onOk(form:FormModel) {
    const registrationDisplayConfig = cloneDeep(form.values);
    registrationDisplayConfig.groups = compact(registrationDisplayConfig.groups);
    registrationDisplayConfig.sorts = compact(registrationDisplayConfig.sorts);

    const variables = {attributes: {id: props.season.id, registrationDisplayConfig}};
    await organizerUpsertSeason({variables});
  }

  return render();
}

const groupOptions = [
  {label: 'Age/grade', value: 'grade'},
  {label: 'Day of week', value: 'day'},
  {label: 'Start of week', value: 'workWeek'},
  {label: 'None', value: null},
];

const sortOptions = [
  {label: 'Age/grade', value: 'grade'},
  {label: 'Activity name', value: 'name'},
  {label: 'Day of week', value: 'day'},
  {label: 'Start date', value: 'date'},
  {label: 'Start time', value: 'time'},
];

const layoutOptions = [
  {label: 'Row', value: 'row'},
  {label: 'Tile', value: 'tile'},
]
