import * as React from 'react'
import { useHistory, useParams } from 'react-router';
import queryString from 'query-string';

import { axios, safeJson } from 'app2/api';
import { Info, Loader, useLifecycle, sleep } from 'app2/components';

import { PublicPage, useCurrentUser } from 'app2/views/shared-public';

export function OauthCallback() {
  const { provider } = useParams<{provider:string}>();
  const history = useHistory();
  useLifecycle({onMount});

  const {state} = queryString.parse(window.location.search);
  const redirectInfo = safeJson(state);

  const [error, setError] = React.useState('');

  const { reexecute } = useCurrentUser();

  function render() {
    return <PublicPage useMaxWidth hAlign='center'>
      {!error && <Loader />}
      {error && <Info type='error' message={error} />}
    </PublicPage>
  }

  async function onMount() {
    if (!provider) {
      history.replace('/')
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('error')) {
      history.replace(redirectInfo?.origin || '/login')
      return;
    }

    const params:any = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    
    try {
      const response = await axios.get(`/auth/${provider}/callback`, { params });

      if (!response.data?.success) {
        setError(response.data?.message || "Log in failed");
      }

      await reexecute();

      // there seems to be a timing issue that even after the user reexecute that
      // not all the of urql cache is updated because sometimes navigation will
      // go to login, so put in a hacky delay
      await sleep(250);

      const state = redirectInfo || safeJson(response.data.state);
      history.replace(state.redirect || '/')
    }
    catch(e) {
      setError(e.message);
    }
  }

  return render();
}
