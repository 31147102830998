import * as React from 'react'
import { useHistory } from 'react-router';

import { register } from 'app2/api';
import { EmailField, FormModel, HBox, InputField, Link, Panel, Section, Text, VBox, useForm } from 'app2/components';
import { PublicPage, useRestrictedSiteAccountRegistration, getLoginUrl, TOSField } from 'app2/views/shared-public';

import { GoogleOAuthButton } from './GoogleOAuthButton';
import { useExternalAuthRequired } from './useExternalAuthRequired';
import { handleAuthResponse } from './handleAuthResponse';

export function Register() {
  const history = useHistory();
  const form = useForm({}, []);

  const {authProvider, checkDomain} = useExternalAuthRequired()
  useRestrictedSiteAccountRegistration();

  function render() {
    return <PublicPage title='Sign up' useMaxWidth>
      <VBox gap='$30'>
      {renderAuthProviders()}
        <VBox gap='$8'>
          <Text text='subtitle2'>Sign up with email</Text>
          <Panel title='We need just a few pieces of basic information' type='edit' onOk={!authProvider ? submit : null} ok={renderSubmit()} footerActions={renderFooter()} form={form} onNavigation='nothing'>
            <Section name='firstName' label='First name' required component={InputField} disabled={!!authProvider} />
            <Section name='lastName' label='Last name' required component={InputField} disabled={!!authProvider} />
            <Section name='email' label='Email address' required component={EmailField} onChange={checkDomain} />
            <Section name='password' label='Password' required component={InputField} type='password' disabled={!!authProvider} />
            <TOSField />
          </Panel>
        </VBox>
      </VBox>
    </PublicPage>
  }

  function renderAuthProviders() {
    return renderGoogleOauth()
  }

  function renderSubmit() {
    return !authProvider ? 'Register' : renderGoogleOauth()
  }

  function renderGoogleOauth() {
    return <GoogleOAuthButton type='sign-up' />
  }

  function renderFooter() {
    return <HBox text='body' vAlign='center' gap='$8'>Already have an account? <Link to={getLoginUrl()}>Log in here</Link></HBox>
  }

  async function submit(form:FormModel) {
    const response = await register(form.values);
    return handleAuthResponse(form, response, history);
  }

  return render();
}
