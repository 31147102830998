import * as React from 'react';

import { FamilyTeam } from '../../family'

import { StudentAttendance } from './StudentAttendance';
import { StudentCustomFields } from './StudentCustomFields';
import { StudentDetails } from './StudentDetails';
import { StudentEnrollments } from './StudentEnrollments';
import { StudentRefunds } from './StudentRefunds';
import { StudentLogEntries } from './StudentLogEntries';

export function studentTabs(id:string, editing:boolean) {
  return [{
    label: 'Profile',
    content: <StudentDetails id={id} editing={editing} />
  },
  {
    label: 'Contacts',
    content: <FamilyTeam student={id} editing />
  },
  {
    label: 'Refunds',
    content: <StudentRefunds id={id} />
  },
  {
    label: 'Activities',
    content: <StudentEnrollments id={id} />
  },
  {
    label: 'Registration questions',
    content: <StudentCustomFields id={id} overrideRequired={true} />
  },
  {
    label: 'Attendance',
    content: <StudentAttendance id={id} />
  },
  {
    label: 'Notes',
    content: <StudentLogEntries id={id} />
  }]
}
