import * as React from 'react'

import { FileUploadAttributes, Student } from 'app2/api';
import { Form, FormModel, InputField, Modal, Part, Section, Text } from 'app2/components';
import { FileUploads } from 'app2/views/shared';

import { adminImportOneRoster } from './generated';

interface ImportFormModel {
  files:FileUploadAttributes[];
  studentDefaults:Partial<Student>;
}
interface Props {
  site:string;
}

export function ImportOneRoster(props:Props) {
  function render() {
    return <Modal title='Import OneRoster'>
      <Form<ImportFormModel> onNavigation='nothing' editing onOk={onOk}>
        <Text text='body'><b>Note</b>: This <b>will update existing family members and students</b>, not just add new ones, including: names, phone numbers, grades, assigned school & classroom, etc.</Text>
        <Text text='body'><b>Note</b>: Importing happens <b>in the background</b>, and can take up to 5 minutes to complete.</Text>
        <Section label='Account zip' name='files' component={FileUploads} accept='application/zip' children='Choose file' />

        <Section label='Student defaults'>
          <Part label='Student medical conditions' name='studentDefaults.medical.conditions' component={InputField} />
        </Section>
      </Form>
    </Modal>
  }

  async function onOk(form:FormModel<ImportFormModel>) {
    const [success, result] = await adminImportOneRoster({variables:{site: props.site, file: form.values.files[0], studentDefaults: form.values.studentDefaults}});

    return success;
  }

  return render();
}
