import * as React from 'react';

import { CourseOptionKind, EnrollmentOptionInput } from 'app2/api';
import { FieldParent, Form, FormProps, Section, Text, useFormInfo, useForm, VBox } from 'app2/components';

import { CourseCard } from '../course';

import { EnrollmentOptions, useCourseWithOptions } from './useCourseWithOptions';
import { EnrollmentCourseOptionsAddToCart } from './EnrollmentCourseOptionsAddToCart';

export interface EnrollmentOptionsFormValues {
  choices:EnrollmentOptionInput[];
}

interface Props extends FormProps {
  course?:string;
  checkout?:boolean;
  choices?:EnrollmentOptions;
  onNext?:() => void;
  page?:number;
}

export function EnrollmentCourseOptionsForm(props:Props) {
  const {course:propsCourse, checkout, choices:propsChoices, onNext:propsOnNext, page, ...remaining} = props;
  const outerForm = useFormInfo();
  const {choices, course, options, courses} = useCourseWithOptions(props.course, props.checkout, props.choices);

  const form = getForm();
  const formUi = React.useRef<Form>();

  function render() {
    if (!course || !options?.length) {
      return <></>;
    }
  
    const hasNext = isFinite(props.page) && props.page < options?.length - 1;
  
    return <Form ref={formUi} form={form} title={course.name} alwaysSave={true} autoFocus={false} editing={outerForm?.editing || props.editing} onOk={hasNext ? onNext : undefined} {...remaining}>{renderPages()}</Form>
  }

  function renderPages() {
    return isFinite(props.page)
      ? renderPage(props.page)
      : options.map((o, i) => renderPage(i));
  }

  function renderPage(page:number) {
    const option = options[page];
    
    const multiple = option?.kind == CourseOptionKind.MultipleChoice;
    const required = !multiple;
    const choiceIndex = form.values.choices.findIndex(c => c.option == option.id);

    return <Section key={page} text='subtitle1' label={`${option.title} ${required ? '(select one)' : ''}`} required={required} layout='vbox' gap='$20'>
      <Text text='body'>{option.description}</Text>
      <VBox gap='$20' width='100%'>
        {option.choicesByGrade.map(group => {
          return <VBox key={group.label} gap='$4'>
            <Text text='subtitle2'>{group.label}</Text>
            {group.courses.map(course => <FieldParent key={course.label.id} name={`choices.${choiceIndex}.choices`}>
              <Section required={required} errorPlacement='above' none={false} 
                  component={<CourseCard course={course.label} bg='white' kind='course-option' size='wide' addToCart={<EnrollmentCourseOptionsAddToCart disabled={course.disabled} multiple={multiple} />} />} />
              </FieldParent>
            )}
          </VBox>
        })}
      </VBox>
    </Section>
  }

  function getForm() {
    const form = useForm<EnrollmentOptionsFormValues>(createChoices, [options, props.choices]);

    form.indexPaths = true;
    form.assignIds = false;

    return form;
  }

  function createChoices() {
    // ensures that there's an allocated choice for each option
    return {choices: options?.map(o => ({option: o.id, choices: choices.find(c => c.option == o.id)?.choices?.slice() || [] }))};
  }

  function onNext() {
    // this is a hack that prevents validation errors
    // from showing as we move from step to step because
    // the form does submission validation after each step
    form.submitted = false;

    formUi.current.element.scrollTo(0, 0);
    propsOnNext?.();
    
    return false;
  }

  return render();
}
