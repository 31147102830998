import * as React from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { uniq } from 'lodash-es';

import { AttendanceAction } from 'app2/api';
import {
  Body,
  CheckboxField,
  DropdownField,
  Field,
  FieldInfo,
  FieldProps,
  FieldRendererProps,
  Form,
  formatDate,
  FormModel,
  HBox,
  Icon,
  Link,
  RepeatingSection,
  Saveable,
  Text,
  TIME_FORMAT,
  useForm,
  useIdleDetector,
  VBox
} from 'app2/components';

import { PublicSiteParams } from '../../publicRoutes';

import { AttendanceCancel } from './AttendanceCancel';
import { PublicAttendancePage } from './PublicAttendancePage';
import { ParentAttendanceStudentsSelections, parentSetAttendance, ParentSetAttendanceMutation, useAttendanceSiteQuery, useParentAttendanceStudentsQuery } from './generated';

export function PublicAttendanceStudents() {
  const history = useHistory();
  const { site: siteId } = useParams<PublicSiteParams>();
  const [siteQueryResult] = useAttendanceSiteQuery({ variables: { id: siteId } });
  const site = siteQueryResult?.data?.site;
  const location = useLocation();
  const code = location.state?.code;

  const [studentsQueryResult] = useParentAttendanceStudentsQuery({ variables: { code: code?.toString() }, pause: !code });
  const loading = studentsQueryResult.loading || studentsQueryResult.fetching;
  const owner = studentsQueryResult?.data?.parentAttendanceStudents?.owner;
  const rosterItems = studentsQueryResult?.data?.parentAttendanceStudents.items;

  const form = useForm<FormValues>(
    () => ({
      attendances: rosterItems?.map(item => ({
        ...item,
        disableCheckbox: !!item.checkedOutAt, // disable checkbox if checked out
        include: false,
        action: item.checkedInAt ? AttendanceAction.CheckOut : AttendanceAction.CheckIn
      }))
    }),
    [rosterItems],
    { alwaysSave: true, indexPaths: true }
  );
  const codeInputPath = `/sites/${siteId}/family-attendance`;

  const [result, setResult] = React.useState<{ success: boolean; data?: ParentSetAttendanceMutationResult }>({ success: false });

  // Reset after 90 seconds of inactivity:
  useIdleDetector(() => {
    history.replace(`/sites/${siteId}/family-attendance`, {});
  }, 90 * 1000);

  function render() {
    if (site && !site?.usingParentAttendance) {
      return <Redirect to="/" />;
    }

    if (!code || (!loading && !rosterItems)) {
      return <Redirect to={codeInputPath} />;
    }

    return <PublicAttendancePage>{renderContent()}</PublicAttendancePage>;
  }

  function renderContent() {
    if (result.success) {
      return renderSuccess();
    }

    return renderForm();
  }

  function renderSuccess() {
    return (
      <VBox hAlign="center" gap="$8" width="100%">
        <HBox vAlign="center" mb="$16">
          <Icon name="CheckCircle" size={34} mr="$8" />
          <Text text="heading1">Success!</Text>
        </HBox>
        <Text text="body" mb="$20">
          {formatAttendanceMessage(result.data)}
        </Text>
        <Text text="body" mb="$20" bold>
          Please hand the device back to a staff member.
        </Text>
        <Link button="primary" to={codeInputPath} replace state={{}}>
          Continue
        </Link>
      </VBox>
    );
  }

  function formatAttendanceMessage(data: ParentSetAttendanceMutationResult) {
    const ownerName = owner.name;
    const attendances = data.results.map(result => {
      const rosterItem = rosterItems.find(item => item.id === result.id);
      return {
        ...rosterItem,
        action: result.action
      };
    });
    const checkIns = uniq(attendances.filter(attendance => attendance.action === AttendanceAction.CheckIn).map(attendance => attendance.student.name));
    const checkOuts = uniq(attendances.filter(attendance => attendance.action === AttendanceAction.CheckOut).map(attendance => attendance.student.name));

    let message = `${ownerName} `;

    if (checkIns.length > 0) {
      message += `checked ${checkIns.join(' and ')} in`;
    }

    if (checkOuts.length > 0) {
      if (checkIns.length > 0) {
        message += ' and ';
      }
      message += `checked ${checkOuts.join(' and ')} out`;
    }

    return `${message.trim()}.`;
  }

  function renderForm() {
    return (
      <Saveable ok="Submit" cancel={<AttendanceCancel />} width="100%">
        <Form
          title={<Text text={['heading2', 'heading1']}>Welcome{owner && owner.name.length ? `, ${owner.name.split(' ')[0]}` : ''}!</Text>}
          subtitle="Select the students to check in or out."
          onNavigation="nothing"
          onOk={handleSubmit}
          form={form}
          width="100%"
          pr={0}
          mr={0}
        >
          <RepeatingSection
            name="attendances"
            numbered={false}
            headers={false}
            equalWidths={false}
            width="100%"
            fields={[<Field format={formatStudent} />, <Field name="action" label="Action" required render={renderAction} width="175px" />]}
          />
        </Form>
      </Saveable>
    );
  }

  function formatStudent(val: Attendance, props: FieldProps<any, any, any>, info: FieldInfo<Attendance['course']>) {
    return (
      <HBox vAlign="baseline" gap="$8">
        {/* checked out students should not be selectable */}
        <Field name="include" disabled={val.disableCheckbox} component={CheckboxField} />
        <VBox>
          <Text text="subtitle1" whiteSpace="nowrap">
            {val?.student?.name}
          </Text>
          <Text text="body">{val?.course?.name}</Text>
          {renderSessionTimes(val)}
          {renderCheckedIn(val)}
          {renderCheckedOut(val)}
        </VBox>
      </HBox>
    );
  }

  function renderSessionTimes(val: RosterItem) {
    const startsAt = val?.startsAt;
    const endsAt = val?.endsAt;

    return (
      <>
        {formatDate(startsAt, TIME_FORMAT, site?.timezone)} - {formatDate(endsAt, TIME_FORMAT, site?.timezone)}
      </>
    );
  }

  function renderAttendanceStatus(val: RosterItem, type: 'checkedIn' | 'checkedOut') {
    const timeKey = type === 'checkedIn' ? 'checkedInAt' : 'checkedOutAt';
    const byKey = type === 'checkedIn' ? 'checkedInBy' : 'checkedOutBy';

    if (!val[timeKey]) {
      return null;
    }

    return (
      <Body color="success">
        {type === 'checkedIn' ? 'Checked in' : 'Checked out'} by {val[byKey]} at {formatDate(val[timeKey], TIME_FORMAT, site?.timezone)}
      </Body>
    );
  }

  function renderCheckedIn(val: RosterItem) {
    if (val.checkedOutAt) {
      return null;
    }
    return renderAttendanceStatus(val, 'checkedIn');
  }

  function renderCheckedOut(val: RosterItem) {
    return renderAttendanceStatus(val, 'checkedOut');
  }

  function renderAction(fieldProps: FieldRendererProps<Attendance>) {
    const checkedIn = Boolean(fieldProps.info.record.checkedInAt);
    const checkedOut = Boolean(fieldProps.info.record.checkedOutAt);
    const checkInOption = { value: AttendanceAction.CheckIn, label: 'Check in' };
    const checkOutOption = { value: AttendanceAction.CheckOut, label: 'Check out' };

    let options = [checkInOption, checkOutOption];
    if (checkedIn && !checkedOut) {
      options = [checkOutOption];
    }

    return <Field placeholder="Check in or out" options={options} name="action" component={DropdownField} disabled={checkedOut} />;
  }

  async function handleSubmit(form: FormModel<FormValues>) {
    const attendances = form.values.attendances.filter(a => a.include).map(({ student, course, action }) => ({ student: student.id, course: course.id, action }));

    if (!attendances.length) {
      return false;
    }

    const [success, res] = await parentSetAttendance({ variables: { code: code.toString(), attendances }, successMsg: 'Attendance submitted' });
    if (success) {
      setResult({ success: true, data: res.data.parentSetAttendance });
    }

    return success;
  }

  return render();
}

interface FormValues {
  attendances?: Attendance[];
}

interface Attendance extends RosterItem {
  action: AttendanceAction;
  disableCheckbox: boolean;
  include: boolean;
}

type RosterItem = ParentAttendanceStudentsSelections['items'][0];

type ParentSetAttendanceMutationResult = ParentSetAttendanceMutation['parentSetAttendance'];
