import * as React from 'react'

import { EnrollmentStatusFilter } from 'app2/api';
import { colId, DataTableColumn, DataTableColumnSort } from 'app2/components';

import { CourseSelections } from '../generated';

import { useCfQuestionCols } from '../../custom-fields';
import { createRosterView } from '../../roster/createRosterView';
import { StudentModal, restrictedModalRenderer } from '../../student';

interface ColSort {
  name:string;
  sort: DataTableColumnSort;
}

export function useCourseEnrollmentCols<T>(cols:(string | DataTableColumn<T>)[], course:CourseSelections, status:EnrollmentStatusFilter, cfQuestions?:boolean, colSort?: ColSort) {
  const cfSeason = cfQuestions ? useCfQuestionCols<T>(course?.cfQuestions) : [];
  const cfActivity = cfQuestions ? useCfQuestionCols<T>({owner:course?.season}) : [];

  return React.useMemo(() => {
    const studentModal = course?.behavior?.studentUpdate ? StudentModal : restrictedModalRenderer(course?.token)

    const rosterView = createRosterView({ cols, cfCols: cfSeason.concat(cfActivity), course, status, studentModal }).cols;

    if (colSort) {
      const col = rosterView.find(c => colId(c.id) == colSort.name);
      if (col) {
        col.sort = colSort.sort;
      }
    }

    return rosterView;
  }, [cols, course, status, cfSeason, cfActivity])
}
