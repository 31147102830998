import { gqlTypes } from 'app2/api';
import { BooleanField, DataTableColumn, NumberField, DateField, MultipleDateField, CurrencyField, PercentField } from 'app2/components';

import { EntityType } from './EntityType';
import { FieldWithOverrides } from './FieldWithOverrides';

const typeToFields:any = {
  'ID': {},
  'String': {},
  'ISO8601DateTime': DateField,
  'ISO8601Date': DateField,
  'Float': NumberField,
  'Boolean': BooleanField,
  'currency': CurrencyField,
  'percent': PercentField
}

const arrayTypeToFields:any = {
  'ISO8601DateTime': MultipleDateField,
  'ISO8601Date': MultipleDateField,
}

function convertSchemaToFields() {
  const baseFields:Record<EntityType, Record<string, FieldWithOverrides>> = {};

  for (const typeName in gqlTypes) {
    const props = gqlTypes[typeName];
    const fields:Record<string, DataTableColumn> = baseFields[typeName] = {};

    for (const name in props) {
      const prop = props[name];
      const fieldComponent = !prop.array ? typeToFields[prop.format || prop.type] : arrayTypeToFields[prop.type];      
      
      fields[name] = {
        ...fieldComponent, 
        name
      }
    }
  }

  return baseFields;
}

export const allSchemaFields = convertSchemaToFields();
