import * as React from 'react';

import { Sort, SortDirection, Filter } from 'app2/api';
import { colId, DataTableColumn, DataTableColumnSort, showCols } from 'app2/components';

import { EntityType } from './EntityType';
import { getFields } from './getFields';
import { FieldList, normalizeFields } from './FieldList';

// the GQL Filter.value type is wrong, it should be an array but changing it in gql
// generates a lot of errors.  it should be updated to be an any type in gql because
// we allow scalars and an array of scalars.  so its retyped here.

type CorrectFilter = Pick<Filter, 'name' | 'op'> & {value: any};

// options allows adding default sort/filters.  they can be overridden by the user
// in which case the persisted table preference will be used

export function useCols<T = any>(type:EntityType, cols?:FieldList, props?:any, options?:{sorts?: Sort[], filters?: CorrectFilter[]}):DataTableColumn<T>[] {
  return React.useMemo(() => {
    const {paths, fields} = normalizeFields(cols);
    const expandCols = getFields(type, props || {}, paths, fields);

    if (options?.sorts) {
      options?.sorts.forEach((sort) => {
        const sortCol = expandCols.find(col => colId(col) == sort.name);

        if (!sortCol) {
          return;
        }

        sortCol.sort = sort.direction == SortDirection.Desc ? DataTableColumnSort.descending : DataTableColumnSort.ascending;
      });
    }

    if (options?.filters) {
      options?.filters.forEach((filter) => {
        const filterCol = expandCols.find(col => colId(col) == filter.name);

        if (!filterCol) {
          return;
        }

        if (Array.isArray(filter.value)) {
          filterCol.filter = filter.value.length ? filter.value : null;
        }
        else {
          filterCol.filter ||= []
          filterCol.filter.push(filter.value);
        }
      });
    }

    return expandCols as unknown as DataTableColumn<T>[];
  }, [props, options]);
}
