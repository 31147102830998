import * as React from 'react';

import { VBox } from 'app2/components';
import { FamilyAttendanceTable } from '../../family'

import { useStudentAttendanceQuery, StudentAttendanceDocument } from './gql'

interface Props {
  id:string;
}

export function StudentAttendance(props:Props) {
  const variables = {student: props.id};
  
  function render() {
    // not sure why the zindex is needed to get the row borders to show, but it is
    return <VBox bg='#fff' height='100%'><VBox zIndex={1} height='100%'>
      <FamilyAttendanceTable query={useStudentAttendanceQuery} document={StudentAttendanceDocument} variables={variables} cols={FamilyAttendanceTable.colNamesWoStudent}
        header={{height: '100%', overflow: 'scroll', mb:0}} table={{maxWidth: '100%', maxHeight: '100%', unstickyX: false}}/>
    </VBox></VBox>
  }

  return render();
}