import * as React from 'react'

import { EntityKind, Member, MemberRole } from 'app2/api';
import { HBox, Info, Icon } from 'app2/components';
import { getTeamFielsToOmit, UnifiedTeam, UnifiedTeamGroup, UnifiedTeamProps } from 'app2/views/shared';

import { useFamilyParameter } from '../useFamilyParameter';
import { useStudentTeamQuery, useStudentTeamByTokenQuery } from '../generated';

export interface FamilyTeamProps extends Partial<UnifiedTeamProps> {
  family?:string;
  student?:string;
  course?:string;

  // removes attendance and contact cols
  small?:boolean;
}

export function FamilyTeam(props:FamilyTeamProps) {
  const {family:propsFamily, student, course, small, ...remaining} = props;

  const colsToUse = small ? smallCols : cols;
  const {entityKind, entityId, team} = getTeam();
 
  function render() {
    return <UnifiedTeam entityKind={entityKind} entityId={entityId} team={team} separator='small' labelType='formlabel' grid={3}
      cols={colsToUse} group={group} groups={getGroups} numbered={false} {...remaining} />
  }

  function getTeam() {
    const familyIdFromUrl = !props.student ? useFamilyParameter() : undefined;
    const familyId = propsFamily || familyIdFromUrl;
    const entityKind = EntityKind.Family;
    let entityId = familyId;

    const removeFields = React.useMemo(() => getTeamFielsToOmit(colsToUse), [colsToUse]);
    const [studentResult1] = useStudentTeamByTokenQuery({variables: {student: props.student, course}, removeFields, pause: !props.student || !course})
    const [studentResult2] = useStudentTeamQuery({variables: {student: props.student}, removeFields, pause: !props.student || !!course})

    const team = studentResult2.data?.student?.family || studentResult1.data?.studentByToken?.family;
    entityId ||= team?.id;

    return {entityKind, entityId, team}
  }

  function group(m:Member) {
    return m.kind != 'contact' ? 0 : m.role === 'Authorized Pickup' ? 2 : 1;
  }

  function getGroups(team:Member[]) {
    const ecs = team?.filter(m => m.kind == 'contact' && m.role == 'Emergency contact');
    const hasMultipleEC = ecs?.length > 1;

    const groups:UnifiedTeamGroup[] = [{
      cols: colsToUse,
      title: <HBox tooltip='Each parent/guardian will have full access to manage family information, registrations, and payments.' vAlign='center'>Parents/guardians<Icon ml='$4' infoTip /></HBox>,
      add:'Add parent/guardian',
      defaultRecord:{kind: 'invite', role: MemberRole.Admin},
    }, {
      cols: colsToUse,
      title:'Emergency contacts',
      add:'Add emergency contact',
      defaultRecord:{kind: 'contact', role: 'Emergency contact'},
      maxRows: 1,
      contacts: true,
      alwaysOne: true,
      header: hasMultipleEC ? <Info type='warning'>Multiple emergency contacts are listed. Please choose one to keep, and delete the others.</Info> : undefined,
    }, {
      cols: colsToUse,
      title: 'Authorized pickups',
      add:'Add authorized pickup',
      defaultRecord:{kind: 'contact', role: 'Authorized Pickup'},
      contacts: true
    }]

    return groups;
  }

  return render();
}

FamilyTeam.defaultProps = {
  onNavigation: 'prompt-dirty'
}

const smallCols = ['relation', 'phone', 'email']
const cols = smallCols.concat('resendInvite', 'isContact', 'attendanceCodeCode');
