import * as React from 'react';

import { FileUpload, FileUploadAttributes } from 'app2/api';
import { FieldInfo, useFormInfo, VBox } from 'app2/components';

import { FileLinks } from './FileLinks';
import { FileTags } from './FileTags';
import { FileUploader, FileUploaderProps } from './FileUploader';

interface Props<T> extends Omit<FileUploaderProps<T>, 'value'> {
  value?: FileUploadAttributes[];
  field?: FieldInfo<any>;
  uploader?:boolean;//force into edit mode
}

export function FileUploads<T = any>(props: Props<T>) {
  const {value, field, disabled, uploader, ...remaining} = props;
  const formInfo = useFormInfo();

  function render() {
    return <VBox>
      {!formInfo.editing && !uploader
        ? <FileLinks files={value as FileUpload[]} />
        : <>
          <FileTags files={value} mb="$12" onRemove={onRemoveFile} disabled={disabled} />
          <FileUploader kind="secondary" onChoose={onChooseFile} disabled={disabled} {...remaining} />
        </>}
    </VBox>
  }

  function onChooseFile(name: string, fileType: string, fileData: string) {
    const existing = formInfo.form.getValue(field.parents, field.name)
    const uploads = (Array.isArray(existing) ? existing : []).concat({ name, fileType, fileData });
    formInfo.form.setValue(field.parents, field.name, uploads);
  }

  function onRemoveFile(pos: number) {
    const uploads = formInfo.form.getValue(field.parents, field.name).slice();
    uploads.splice(pos, 1);

    formInfo.form.setValue(field.parents, field.name, uploads);
  }

  return render();
}

FileUploads.fieldProps = {
  valueProperty: 'value',
  fieldProperty: 'field'
};
