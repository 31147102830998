import * as React from 'react'
import { omit } from 'lodash-es';

import { CfOwnerKind, CfQuestion, CfKind, CfStage, CourseKind, CourseKindGroup } from 'app2/api'
import { Panel, RepeatingSection, Field, FormModel, Info, Text } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';
import { addDeletedItems, prepareFileUploads } from 'app2/views';

import { CfOwnerProps, useCfQuestionsQuery } from '../useCfQuestionsQuery';

import { CfQuestionConfigure } from './CfQuestionConfigure';
import { organizerUpsertCfQuestions } from './generated';

interface Props extends CfOwnerProps {
  courseKinds:(CourseKind | CourseKindGroup)[];
}

export function CfQuestionsEditor(props:Props) {
  const owner = useCfQuestionsQuery(props);

  function render() {
    return <Panel title='Registration questions' subtitle='Registration questions are custom questions that can be added to the registration process.'
    type='toggle' initialValues={owner} onOk={onOk}>
      <Info type='warning' small>
        {renderWarningContent()}
      </Info>
      <RepeatingSection name='questions' add='Add question' defaultRecord={defaultQuestion} fields={[
        <Field component={CfQuestionConfigure} ownerType={owner.type} ownerId={owner.id} />,
        'remove'
      ]}/>
    </Panel>
  }

  async function onOk(form:FormModel) {
    const original = owner.questions.map((q:any) => ({...q, files: prepareFileUploads(q.files)}))
    const updated = form.values.questions.map((q:any) => ({...q, files: prepareFileUploads(q.files)}))
    const questions = addDeletedItems(original, updated, "archived").map(q => ({...omit(q, ['owner', 'ownerId', 'ownerType'])}));
    const [success] = await organizerUpsertCfQuestions({variables:{ownerId: owner.id, ownerType: owner.type, questions}});

    if (!success) {
      return false;
    }
  }

  function renderWarningContent() {
    const allStudentDetails = props.courseKinds.some(g => courseKindBehavior[g].allStudentDetails);

    const baseMessage = (
      <Text text="body">
        Families are already asked the following basic questions —&nbsp;
        {allStudentDetails ? (
          <>
            <b>student details</b> (Name, Age, School, Teacher, Grade), <b>allergies/medical conditions</b>, <b>notes</b>, and <b>contacts</b> (primary, emergency, authorized pickup(s))
          </>
        ) : (
          <>
            <b>student details</b> (Name, Age, Grade), <b>allergies/medical conditions</b>, <b>notes</b>, and <b>contacts</b> (primary, emergency, authorized pickup(s))
          </>
        )}
      </Text>
    );
    if (owner.type === CfOwnerKind.Course) {
      return baseMessage;
    }

    return (
      <ul>
        <li>{baseMessage}</li>
        <li>
          <Text text="body">Families will be reminded a month before the start of the season to answer required questions.</Text>
        </li>
        <li>
          <Text text="body">Responses will be viewable by all team members, including activity providers.</Text>
        </li>
      </ul>
    );
  }

  return render();
}

const defaultQuestion:Partial<CfQuestion> = {
  kind: CfKind.MultipleLines,
  stage: CfStage.Later
}