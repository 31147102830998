import * as React from 'react';

import { CheckedIn } from 'app2/api';
import { DateField, DataTableFilterType, DropdownField, Icon, IconNames, OptionButton, TimeField } from 'app2/components';

import { PaymentStatusTag } from '../enrollment';

function icon(name:IconNames, color:string) {
  return <Icon name={name} size='small' color={color}
    border='solid 1px' width='24px' minWidth='24px' maxWidth='24px' minHeight='24px' hAlign='center' vAlign='center' layout='hbox'
    borderColor={color} borderRadius='12px' overflow='hidden' cursor='pointer'
    />
}

const checkInOptions = [{
  value: CheckedIn.Present,
  text: 'Checked in',
  label: {icon: icon('UserCheck', 'success'), color: 'success'}
}, {
  value: CheckedIn.Absent,
  text: 'Absent',
  label: {icon: icon('X', 'error'), color: 'error'},
}, {
  value: null as any,
  text: '',
  // note that once we merge this with the stuff in createRosterView
  // we'll have to resolve that for the parent view we don't want a circle
  // that indicates it editable
  label: '',
}]

const checkOutOptions = [{
  value: true,
  text: 'Checked out',
  label: {icon: icon('LogOut', 'success'), color:'success'}
}, {
  value: false,
  text: '',
  label: '',
}]

export const attendanceFields = [{
  ...DateField,
  name: 'sessionDateInTz',
  label: 'Date',
  sortable:true,
  filterable:true,
  filterType: 'date-range' as DataTableFilterType
}, {
  ...DropdownField,
  name: 'checkedIn', 
  label:'Checked in', 
  options: checkInOptions,
  disallowNone: true,
  display: OptionButton,
  edit: <OptionButton padding='12px 12px 12px 12px' />,
  filterable: true,
  sortable: true,
}, {
  ...TimeField,
  width: 150,
  name: 'checkedInAt', 
  label:'Check in time', 
  edit: {...TimeField.edit, placeholder: false},
  sortable: true,
}, {
  width: 150,
  name: 'formattedCheckedInTime', 
  label:'Check in time', 
}, {
  name: 'checkedInBy', 
  fields: {
    name: {
      name: 'name',
      label:'Checked in by',
      width: 200
    }
  }
}, {
  name: 'checkedOutBy',
  fields: {
    name: {
      name: 'name',
      label:'Checked out by', 
      width: 200
    }
  }
}, {
  ...DropdownField,
  name: 'checkedOut', 
  label: 'Checked out', 
  options: checkOutOptions,
  disallowNone: true,
  display: OptionButton,
  edit: <OptionButton padding='12px 12px 12px 12px' />,
  filterable: true,
  sortable: true,
}, {
  ...TimeField,
  width: 175,
  name: 'checkedOutAt', 
  label: 'Check out time', 
  edit: {...TimeField.edit, placeholder: false},
  sortable: true,
}, {
  width: 150,
  name: 'formattedCheckedOutTime', 
  label:'Check out time', 
}, {
  label: 'Status',
  name: 'paymentStatus',
  component: PaymentStatusTag
}, {
  width: 150,
  name: 'formattedSessionStartTime', 
  label:'Activity start', 
}, {
  width: 150,
  name: 'formattedSessionEndTime', 
  label:'Activity end', 
}
]
