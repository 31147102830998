import { DocumentNode, OperationDefinitionNode, FieldNode } from 'graphql';

// searches in a gql query document for the selections that
// do the query (vs. the ones that select on the results)
export function extractQueryPath(document: DocumentNode): string {
  const queryDef = document.definitions[0] as OperationDefinitionNode;
  return stringifyWithVars(queryDef.selectionSet.selections[0] as FieldNode);
}

function stringifyWithVars(selection: FieldNode): string {
  const fieldName = selection.name.value;
  
  if (!selection.selectionSet?.selections?.[0] || !(selection.selectionSet?.selections[0] as FieldNode).arguments?.length) {
    return `${fieldName}`;
  }

  const nested = stringifyWithVars(selection.selectionSet.selections[0] as FieldNode);
  return `${fieldName}.${nested}`;
}
