import * as React from 'react'

import { Modal, PopupHandleWithPromise, Body } from 'app2/components';

let curErrors:string[] = [];
let modalHandle:PopupHandleWithPromise;

// shows an error modal:
//  - there's only ever one error modal at a time.  this prevents the user
//     having a stack of errors they have to close one by one.  if the error
//     modal is already showing, it adds the incoming errors to it.
//
//  - duplicate errors are eliminated.

export async function showErrors(errors:string[]) {
  const dedupedErrors = [...new Set(curErrors.concat(errors))];

  if (dedupedErrors.length == curErrors.length) {
    return;
  }

  curErrors = dedupedErrors;

  if (modalHandle) {
    modalHandle.remove();
    modalHandle = null;
  }

  const thisHandle = modalHandle = Modal.error('Error', curErrors.map((s, i) => <Body key={i} whiteSpace='pre-wrap'>{s}</Body>));

  await thisHandle;

  if (thisHandle == modalHandle) {
    modalHandle = null;
    curErrors = [];
  }
}

export function closeErrorsModal() {
  if (modalHandle) {
    modalHandle.remove();
    modalHandle = null;
    curErrors = [];
  }
}