import * as React from 'react'

import { OrganizerRole } from 'app2/api';
import { RoleRestricted } from 'app2/views/shared-public';

import { OrganizerSeasonSelections } from '../../shared';

import { RegistrationPageSettings } from './RegistrationPageSettings';
import { SeasonPayoutConfig } from './SeasonPayoutConfig';

interface Props {
  season?: OrganizerSeasonSelections;
}

export function AdvancedSeasonSettings(props:Props) {
  return <>
    <RegistrationPageSettings season={props.season} />
    <RoleRestricted role={OrganizerRole.Coordinator}><SeasonPayoutConfig /></RoleRestricted>
  </>
}
