import { DocumentNode, OperationDefinitionNode, FieldNode } from 'graphql';

// because we don't send full queries to the server because it determines
// the selections (and we should probably change that), but some reports
// are based on dotted paths (like site.students) and the variables might
// be needed to different parts of the selection (like site(site: siteName).students(sorts: ['name'])
// we extract a map for the serve to use to know where to put the variables

export function extractQueryVariablesMap(document: DocumentNode):any {
  const queryDef = document.definitions[0] as OperationDefinitionNode;
  return extractQueryVariables(queryDef.selectionSet.selections[0] as FieldNode, {});
}

function extractQueryVariables(selection: FieldNode, map:any): string {
  const fieldName = selection.name.value;
  const args = (selection.arguments || [])
    .filter(arg => arg.value.kind === 'Variable')
    .map(arg => (arg.value.kind === 'Variable' ? arg.value.name.value : ''));
  
  map[fieldName] = args;
  
  if (!selection.selectionSet?.selections?.[0] || !(selection.selectionSet?.selections[0] as FieldNode).arguments?.length) {
    return map;
  }

  return extractQueryVariables(selection.selectionSet.selections[0] as FieldNode, map);
}
