import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyBilledLaterQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
}>;

export type FamilyBilledLaterQuery = {
  family?:
    | {
        billedLater?:
          | Array<
              | {
                  id: string;
                  status: Types.EnrollmentStatusEnum;
                  waitlistKind: Types.WaitlistKind;
                  rosterPeriod?: string | null | undefined;
                  rosterAmount?: string | null | undefined;
                  billPeriod?: string | null | undefined;
                  startDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  withdrawable?: boolean | null | undefined;
                  student?: { id: string; name: string } | null | undefined;
                  priceConfig?: { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | { kind: Types.PriceConfigKind } | null | undefined;
                  course: { id: string; name?: string | null | undefined; room?: string | null | undefined; lastWithdrawDate?: string | null | undefined };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FamilyBilledLaterDocument = gql`
  query FamilyBilledLater($family: ID!) {
    family(family: $family) {
      billedLater: enrollments(queries: ["BILLED_LATER", "PRESENT"]) {
        id
        status
        waitlistKind
        student {
          id
          name
        }
        rosterPeriod
        rosterAmount
        billPeriod
        startDate
        endDate
        withdrawable
        priceConfig {
          kind
        }
        course {
          id
          name
          room
          lastWithdrawDate
        }
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyBilledLaterArgs = MakeOptional<UseQueryArgs<FamilyBilledLaterQueryVariables, FamilyBilledLaterQuery>, 'query'>;

export function useFamilyBilledLaterQuery(options: FamilyBilledLaterArgs = {}) {
  return useQuery<FamilyBilledLaterQueryVariables, FamilyBilledLaterQuery>({ query: FamilyBilledLaterDocument, ...options });
}

export type FamilyBilledLaterOptions = ExecQueryOptions<FamilyBilledLaterQueryVariables>;

export function familyBilledLater(options: FamilyBilledLaterOptions) {
  return executeQuery<FamilyBilledLaterQuery, FamilyBilledLaterQueryVariables>(FamilyBilledLaterDocument, options);
}

export type FamilyBilledLaterSelections = FamilyBilledLaterQuery['family'];
