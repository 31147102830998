import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSeasonQueryVariables = Types.Exact<{
  seasonId: Types.Scalars['ID'];
}>;

export type OrganizerSeasonQuery = {
  season?:
    | {
        id: string;
        name: string;
        note?: string | null | undefined;
        courseKindGroups: Array<Types.CourseKindGroup>;
        hasCourses: boolean;
        blockOverlappingEnrollments?: boolean | null | undefined;
        coursesBegin: string;
        coursesFinish: string;
        enrollmentOpens: string;
        enrollmentCloses: string;
        holidays: Array<string | null | undefined>;
        hasFutureCourses: boolean;
        completed: boolean;
        private?: boolean | null | undefined;
        registrationDisplayConfig?:
          | {
              tab?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              sorts?: Array<string> | null | undefined;
              layout?: Types.SeasonRegistrationLayout | null | undefined;
              showDates?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const OrganizerSeasonDocument = gql`
  query OrganizerSeason($seasonId: ID!) {
    season(season: $seasonId) {
      id
      name
      note
      courseKindGroups
      hasCourses
      blockOverlappingEnrollments
      coursesBegin
      coursesFinish
      enrollmentOpens
      enrollmentCloses
      holidays
      hasFutureCourses
      completed
      private
      registrationDisplayConfig {
        tab
        groups
        sorts
        layout
        showDates
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSeasonArgs = MakeOptional<UseQueryArgs<OrganizerSeasonQueryVariables, OrganizerSeasonQuery>, 'query'>;

export function useOrganizerSeasonQuery(options: OrganizerSeasonArgs = {}) {
  return useQuery<OrganizerSeasonQueryVariables, OrganizerSeasonQuery>({ query: OrganizerSeasonDocument, ...options });
}

export type OrganizerSeasonOptions = ExecQueryOptions<OrganizerSeasonQueryVariables>;

export function organizerSeason(options: OrganizerSeasonOptions) {
  return executeQuery<OrganizerSeasonQuery, OrganizerSeasonQueryVariables>(OrganizerSeasonDocument, options);
}

export type OrganizerSeasonSelections = OrganizerSeasonQuery['season'];
