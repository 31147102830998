import moment from 'moment';
import { first, flatten, get, last, castArray, groupBy } from 'lodash-es';

import { Course, Grade } from '../graphql';
import { DeepPartial } from '../'

import { WEEKDAYS } from './constants';
import { CourseDayUtils } from './CourseDayUtils';
import { CourseDayName } from './CourseDayName';
import { GRADE_LABEL_TO_POSITION } from './constants';
import { formatAges } from './formatAge';
import { ageRanges, CourseUtils } from './CourseUtils';

export class CourseSortFilterUtils {
  // filter
  static filterCourses<T extends SortableFilterableCourse>(courses:T[], filterOptions: CourseFilterOptions): T[] {
    const optimized = {
      tags: new Set(castArray(filterOptions.tags || [])),
      grades: new Set(castArray(filterOptions.grades || [])),
      days: new Set(castArray(filterOptions.days || [])),
      ages: castArray(filterOptions.ages || [])
    }

    if (!courses) {
      return [];
    }

    return courses
      .filter(that.containsAnyTag(optimized.tags))
      .filter(that.containsAnyGrade(optimized.grades))
      .filter(that.containsAnyAge(optimized.ages))
      .filter(that.containsAnyDay(optimized.days))
      .filter(that.isBetween(filterOptions.dates?.[0], filterOptions.dates?.[1]))
      ;
    }
  
  static containsAnyTag(tags: Set<string>) {
    return function(course: SortableFilterableCourse) {
      const hasTagFilter = tags && tags.size != 0;
      const hasTags = course.courseTags?.length != 0;
      return (
        !hasTagFilter ||
        (!hasTags && tags.has('Other')) ||
        (hasTags && course.courseTags.find(tag => tags.has(tag.name)) != null)
      );
    };
  }
  
  static containsAnyGrade(grades: Set<string>) {
    return function(course: SortableFilterableCourse) {
      return (
        !grades ||
        grades.size == 0 ||
        (course.grades && course.grades.find(grade => grades.has(grade)) != null)
      );
    };
  }
  
  static containsAnyAge(ages:string[]) {
    return function(course: SortableFilterableCourse) {
      return !ages?.length || ages.some((ageIndex:string) => {
        if (typeof course.ageMin !== 'number') {
          return false;
        }

        const ageRange = ageRanges[Number(ageIndex)].value;
        const from = Math.max(ageRange.from, course.ageMin);
        const to = Math.min(ageRange.to, course.ageMax || ageRange.to);
        
        return from <= to;
      })
    };
  }

  static containsAnyDay(days: Set<string>) {
    return function(course: SortableFilterableCourse) {
      return (
        !days ||
        days.size == 0 ||
        (CourseUtils.getNestedCourseDays(course).find(
            courseDay => courseDay.days.find(day => days.has(day)) != null
          ) != null)
      );
    };
  }
  
  static isBetween(start:moment.Moment, end:moment.Moment) {
    return function(course: SortableFilterableCourse) {
      return (
        !start || !end || !course.startDate || !course.endDate ||
        (moment(course.startDate).isSameOrBefore(end) && moment(course.endDate).isSameOrAfter(start))
      );
    };
  }
  
  // group
  static groupCourses<T extends SortableFilterableCourse>(groups:string[], sorts:string[], items:T[], options?:SortFilterOptions): NestedGroupedCourses<T>[] {
    if (!groups?.length) {
      return [{label: '', courses: that.sortCourses(sorts, items)}];
    }

    const group = groups[0];
    const remainingGroups = groups.slice(1);    
    const itemsWithGroupInfo = flatten(items.map(item => {
      const groupInfo = that.getCourseGroup(item, group, options);
      return Array.isArray(groupInfo)
        ? groupInfo.map(info => ({item, ...info}))
        : {item, ...groupInfo}
    })) as Array<{item: T, label: string, value: any}>;
    const grouped = groupBy(itemsWithGroupInfo, groupItem => groupItem.label);
    
    const result:NestedGroupedCourses<T>[] = Object.entries(grouped)
      .map(([label, groupItems]) => {
        if (group == 'day') {
          options = {...options, day: label as CourseDayName};
        }

        return {
          label,
          group,
          value: groupItems[0].value,
          courses: !remainingGroups.length ? that.sortCourses(sorts, groupItems.map(g => g.item), options) : null,
          groups: remainingGroups.length ? that.groupCourses(remainingGroups, sorts, groupItems.map(g => g.item), options).sort(this.compareGroups) : null
        }});

    that.addEmptyGroups(group, result, options);

    return result.sort(this.compareGroups);
  }

  static addEmptyGroups<T>(group:string, groups:NestedGroupedCourses<T>[], options:SortFilterOptions) {
    if (group != 'day' || options?.emptyDayGroups === false) {
      return;
    }

    WEEKDAYS.forEach(day => {
      const existing = groups.find(g => g.label == day)
      if (existing) {
        return;
      }

      groups.push({group, label: day, value: day});
    });
  }

  // group labels
  static courseGroupFns = {
    age: CourseSortFilterUtils.getCourseGradesOrAgesGroup,
    grade: CourseSortFilterUtils.getCourseGradesOrAgesGroup,
    day: CourseSortFilterUtils.getCourseDaysGroup,
    workWeek: CourseSortFilterUtils.getCourseWorkWeek
  }

  static getCourseGroup(item:SortableFilterableCourse, prop:string, options?:SortFilterOptions) {
    const fn = that.courseGroupFns[prop as keyof typeof that.courseGroupFns];

    if (fn) {
      return fn(item, options);
    }

    const label = get(item, prop);
    return {label, value: label};
  }

  static getCourseGradesOrAgesGroup(course:SortableFilterableCourse, options?:SortFilterOptions) {
    return CourseUtils.usingGrades(course) 
      ? {label: CourseUtils.formatCourseGrades(course, options?.siteGrades), value: course}
      : {label: formatAges(course.ageMin, course.ageMax), value: course}
  }

  static getCourseDaysGroup(course:SortableFilterableCourse, options?:SortFilterOptions) {
    const days = CourseUtils.getCourseWeekdays(course);

    return days.map(d => ({label: d, value: d}))
  }

  // group sort
  static courseGroupCompareFns = {
    age: CourseSortFilterUtils.compareAgeOrGradeGroups,
    grade: CourseSortFilterUtils.compareAgeOrGradeGroups,
    day: CourseSortFilterUtils.compareDayGroups,
    workWeek: CourseSortFilterUtils.compareWorkWeekGroups
  }

  static compareGroups<T extends SortableFilterableCourse>(a:NestedGroupedCourses<T>, b:NestedGroupedCourses<T>):number {
    const fn = that.courseGroupCompareFns[a.group as keyof typeof that.courseGroupCompareFns];

      if (!fn) {
      return a.label.localeCompare(b.label);
    }
    
    return fn(a, b);
  }

  static compareAgeOrGradeGroups<T extends SortableFilterableCourse>(a:NestedGroupedCourses<T>, b:NestedGroupedCourses<T>):number {
    return that.compareGradesOrAges(a.value, b.value);
  }

  static compareDayGroups<T extends SortableFilterableCourse>(a:NestedGroupedCourses<T>, b:NestedGroupedCourses<T>):number {
    return CourseDayUtils.compareDays(a.value, b.value);
  }

  static compareWorkWeekGroups<T extends SortableFilterableCourse>(a:NestedGroupedCourses<T>, b:NestedGroupedCourses<T>):number {
    return moment(a.value).valueOf() - moment(b.value).valueOf();
  }

  // sort
  static coursePropLabelFns = {
    age: CourseSortFilterUtils.getCourseGradesOrAges,
    grade: CourseSortFilterUtils.getCourseGradesOrAges,
    workWeek: CourseSortFilterUtils.getCourseWorkWeekLabel
  }

  static getCoursePropLabel<T>(item:T, prop:string, options?:SortFilterOptions):string {
    const fn = that.coursePropLabelFns[prop as keyof typeof that.coursePropLabelFns];
    return fn ? fn(item, options) : get(item, prop);
  }

  static getCourseGradesOrAges(course:Pick<Course, 'grades' | 'ageMin' | 'ageMax'>, options?:SortFilterOptions) {
    return CourseUtils.usingGrades(course) ? CourseUtils.formatCourseGrades(course, options?.siteGrades) : formatAges(course.ageMin, course.ageMax)
  }

  static getCourseWorkWeekLabel(course:Pick<Course, 'startDate'>, options?:SortFilterOptions) {
    return that.getCourseWorkWeek(course).label;
  }

  static getCourseWorkWeek(course:Pick<Course, 'startDate'>, options?:SortFilterOptions) {
    const weekStart = moment(course.startDate).startOf('week');
    const weekEnd = moment(course.startDate).endOf('week').subtract(1, 'd');

    return {label: `${weekStart.format('MMM D')} - ${weekEnd.format('MMM D, YYYY')}`, value: weekStart};
  }

  static sortCourses<T extends SortableFilterableCourse>(sorts:string[], items:T[], options?:SortFilterOptions): T[] {
    return items.sort((a:T, b:T) => {
      for (const sortProp of sorts) {
        const compare = that.compareCourseProp(a, b, sortProp, options);
        
        if (compare != 0) {
          return compare;
        }
      }

      return 0;
    });
  }

  static coursePropSortFns = {
    age: CourseSortFilterUtils.compareGradesOrAges,
    grade: CourseSortFilterUtils.compareGradesOrAges,
    day: CourseSortFilterUtils.compareCourseDays,
    date: CourseSortFilterUtils.compareCourseDates,
    time: CourseSortFilterUtils.compareCourseTimes
  }

  static compareCourseProp<T extends SortableFilterableCourse>(a:T, b:T, prop:string, options?:SortFilterOptions) {
    const fn = that.coursePropSortFns[prop as keyof typeof that.coursePropSortFns] || that.compareCoursePropByLabel;
    return fn(a, b, prop, options)
  }

  static compareCoursePropByLabel<T extends SortableFilterableCourse>(a:T, b:T, prop:string) {
    const aVal = that.getCoursePropLabel(a, prop);
    const bVal = that.getCoursePropLabel(b, prop);

    return aVal.localeCompare(bVal);
  }

  static compareCourseDates<T extends SortableFilterableCourse = SortableFilterableCourse>(c1:T, c2:T) {
    return moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf();
  }

  static compareCourseDays(courseA: SortableFilterableCourse, courseB: SortableFilterableCourse): number {
    return CourseDayUtils.compareCourseDays(courseA.courseDays, courseB.courseDays);
  }

  static compareCourseTimes(courseA: SortableFilterableCourse, courseB: SortableFilterableCourse, sort?:string, options?:SortFilterOptions): number {
    if (options?.day) {
      return that.compareCourseTimesForDay(courseA, courseB, options?.day);
    }
    else {
      return that.compareCoursesTimesOfWeek(courseA, courseB);
    }
  }

  static compareCourseTimesForDay(courseA: SortableFilterableCourse, courseB: SortableFilterableCourse, day: CourseDayName): number {
    const courseDayA = that.getCourseDayTime(courseA, day);
    const courseDayB = that.getCourseDayTime(courseB, day);

    return CourseDayUtils.compareTimeOfDay(courseDayA.start, courseDayB.start);
  }
    
  static getCourseDayTime(course: SortableFilterableCourse, day: CourseDayName) {
    return CourseUtils.getNestedCourseDays(course).find(courseDay => courseDay.days.indexOf(day) != -1);
  }

  static compareCoursesTimesOfWeek<T extends SortableFilterableCourse = SortableFilterableCourse>(c1:T, c2:T) {
    const daysA = CourseUtils.getNestedCourseDays(c1);
    const daysB = CourseUtils.getNestedCourseDays(c2);

    return CourseDayUtils.compareCourseDaysTimeOfWeek(daysA, daysB);
  }
  
  static compareGradesOrAges<T extends SortableFilterableCourse = SortableFilterableCourse>(c1:T, c2:T) {
    if (CourseUtils.usingGrades(c1) && CourseUtils.usingGrades(c2)) {
      return that.compareGrades(c1, c2);
    }
    else
    if (CourseUtils.usingAge(c1) && CourseUtils.usingAge(c2)) {
      return that.compareAges(c1, c2);
    }
    else
    if (CourseUtils.usingGrades(c1)) {
      return -1
    }
    else
    if (CourseUtils.usingAge(c1)) {
      return 1;
    }
    else {
      return 0;
    }
  }

  static compareGrades<T extends SortableFilterableCourse = SortableFilterableCourse>(c1:T, c2:T) {
    const aValues = [GRADE_LABEL_TO_POSITION.get(first(c1.grades)), GRADE_LABEL_TO_POSITION.get(last(c1.grades))];
    const bValues = [GRADE_LABEL_TO_POSITION.get(first(c2.grades)), GRADE_LABEL_TO_POSITION.get(last(c2.grades))];

    return (aValues[0] - bValues[0]) || (aValues[1] - bValues[1]);
  }

  static compareAges<T extends SortableFilterableCourse = SortableFilterableCourse>(c1:T, c2:T) {
    const aValues = [c1.ageMin, c1.ageMax];
    const bValues = [c2.ageMin, c2.ageMax];

    return (aValues[0] - bValues[0]) || (aValues[1] - bValues[1]);
  }
}

// normally you can use this.fnName() inside of CourseUtils code, but we've seen
// cases where the this pointer is not bound correctly, so this allows shorthand
// over CourseUtils.fnName() which can get long.
const that = CourseSortFilterUtils;

export interface CourseFilterOptions {
  grades?:string | string[];
  ages?:string | string[];
  tags?:string | string[];
  days?:string | string[];
  dates?:moment.Moment[];
  byGrade?:boolean;
  byAge?:boolean;
}

type SortableFilterableCourse = DeepPartial<Pick<Course, 'id' | 'name' | 'courseTags' | 'courseDays' | 'grades' | 'startDate' | 'endDate' | 'ageMin' | 'ageMax'>>;

export interface NestedGroupedCourses<T extends SortableFilterableCourse> {
  group?:string;
  label?:string;
  // this is the value for the group and its structure is dependent on the group name
  // its used for sorting groups since we dont want them always to be alphabetical
  value?:any;
  groups?:NestedGroupedCourses<T>[];
  courses?:T[];
}

interface SortFilterOptions {
  siteGrades?:Grade[];
  day?:CourseDayName;
  // this will add empty groups for missing days unless explicitly false
  emptyDayGroups?:boolean;
}