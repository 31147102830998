import * as React from 'react';
import { castArray, omit } from 'lodash-es';

import { compact, DiscountDefinitionFormat, DiscountKind, DiscountDefinitionInput, EntityKind } from 'app2/api';
import { ActionButton, Button, DataTable, Text, useTableEditing, VBox } from 'app2/components';
import { HrDataTable, HrDataTableProps, removePathSegment, useCols } from 'app2/views/shared';

import { useCurrentSite } from '../../shared';

import { useDiscountDefinitionsQuery, DiscountDefinitionsSelections, discountDefinitionFilterOptions, organizerUpsertDiscounts } from './gql';

type Discount = DiscountDefinitionsSelections['discountDefinitionsQuery']['items'][0]

export interface DiscountsProps extends Pick<HrDataTableProps<Discount>, 'pref' | 'prefsVersion'> {
  ownerId: string;
  ownerType: 'season' | 'site';
  queries: 'promotions' | 'students';
  none: string;
  cols: string[];
  header:Pick<HrDataTableProps<Discount>['header'], 'title' | 'subtitle'> & Partial<HrDataTableProps<Discount>['header']>;
}

export function Discounts(props: DiscountsProps) {
  const { ownerId, ownerType, queries } = props;

  // for the table view owner..if we expose this to organizers then this needs to be passed in
  const { site } = useCurrentSite();

  const cols = useCols<Discount>('DiscountDefinition', props.cols, {ownerKind :ownerType, ownerId, entityKind: 'site', entityId: site?.id});
  const table = React.useRef<DataTable<Discount>>();
  const [editing, setEditing] = useTableEditing(table.current);

  function render() {
    const entityKind = ownerType == 'season' ? 'Season' : 'Site';
    const entityId = ownerId;

    return <HrDataTable<Discount>
      queryHook={useDiscountDefinitionsQuery} queryOptions={{variables:{entityKind, entityId, queries}}} filterOptionsQuery={discountDefinitionFilterOptions} sortFilterType='v2' pref={props.pref} prefsVersion={props.prefsVersion}      
      header={{icon:'Tag', secondaryActions: renderSecondaryActions(), editing, onEdit: () => setEditing(true), onStopEditing: () => setEditing(false), onSaveEdits: onOk, ...props.header}}
      views={{entityKind:EntityKind.Site, entityId: site?.id, table: `site-${queries}-discounts`}}
      table={{cols, ref:table, defaultRecord, editable: true, keepFormEditingFalse: true, none: renderNone()}} 
    />
  }

  function renderSecondaryActions() {
    if (!editing) {
      return [];
    }

    return [
      <ActionButton selection={false} icon="PlusCircle" onClick={() => table.current.append()}>Add discount</ActionButton>,
      <ActionButton selection={false} icon='XCircle' onClick={() => table.current.removeSelected()}>Remove discount</ActionButton>,
    ]
  }

  function renderNone() {
    return <VBox gap='$20'>
      <Text>No {props.none} discounts yet!</Text>
      {!editing && <Button onClick={() => setEditing(true)}>Create discounts</Button>}
    </VBox>
  }

  async function onOk(table:DataTable<Discount>) {
    const valid = await table.presubmit();

    if (!valid) {
      return false;
    }

    const discounts = extractInputs(table);
    const [success] = await organizerUpsertDiscounts({ variables: { ownerId, ownerType, discounts }, error: {handler: table, transform: removePathSegment('discounts')} });

    return success;
  }

  function extractInputs(table:DataTable<Discount>): DiscountDefinitionInput[] {
    const discounts = table.getItems(true, false, 'archive');
    return discounts.map(d => {
      return {
        ...omit(d, ['usesCount', 'student', 'courses']), 
        scope: {
          ...d.scope,
          courses: d.courses?.map(c => typeof c == 'object' ? c.id : c),
          students: compact(castArray(d.student))?.map(s => s.id)
        }
    }});
  }

  return render();
}

Discounts.defaultProps = {
  queries: 'promotions'
}

const defaultRecord = { code: '', active: true, kind: DiscountKind.Activity, format: DiscountDefinitionFormat.Percentage, amount: 0, usesCount: 0 };

