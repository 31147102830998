import * as React from 'react';
import { useParams } from 'react-router';

import { Field, Panel, RepeatingSection } from 'app2/components';

import { CourseLink } from '../shared';

import { useSeasonCoursesQuery } from './generated';

export function SeasonCourses() {
  const { season } = useParams<{season:string}>();
  const [result] = useSeasonCoursesQuery({variables: {id:season}});
  const item = result.data?.season;

  function render() {
    return <Panel icon='Home' title='Activities' initialValues={item}>
      <RepeatingSection name='allCourses' fields={[
        <Field name='name' label='Name' component={CourseLink} />,
        <Field name='status' label='Status' />
      ]} />
    </Panel>
  }

  return render();
}
