import { lowerCase, startCase } from 'lodash-es';

import { PriceConfig } from 'app2/api';
import { CurrencyField, DateField } from 'app2/components';

interface Props<T> {
  timezone?:string;
}

export function priceConfigFields<T extends PriceConfig = any>(props:Props<T>) {
  const { timezone } = props;

  return [{
    ...DateField,
    label: 'Billing date',
    name: 'billingDate',
    timezone,
    width: 148,
    readOnly: true,
  },
  {
    ...CurrencyField,
    label: 'Rate',
    name: 'rate' as keyof T,
    readOnly: true,
  }, 
  {
    ...CurrencyField,
    label: 'Rate',
    name: 'listRate' as keyof T,
    readOnly: true,
  }, 
  {
    label: 'Time in activity',
    name: 'timeInCourse' as keyof T,
    width: 160,
  },
  {
    label: 'Time billed',
    name: 'timeBilled' as keyof T,
    width: 160,
    readOnly: true,
  },
  {
    label: 'Unit',
    name: 'usageUnit' as keyof T,
    format: (value:string) => startCase(lowerCase(value)),
    readOnly: true
  },
  {
    label: 'Units billed',
    name: 'unitsBilled' as keyof T,
    readOnly: true,
  },
  {
    label: 'Billing round-up',
    name: 'roundingIncrement' as keyof T,
    width: 180,
    readOnly: true,
    format: (value:number) => value ? `${value} min` : '',
  }, 
  {
    label: 'Grace period',
    name: 'gracePeriod' as keyof T,
    width: 180,
    readOnly: true,
    format: (value:number) => value ? `${value} min` : '',
  },
  {
    label: 'Non-billable time',
    name: 'formattedNonBillableTime' as keyof T,
    width: 200,
    readOnly: true
  },
  {
    label: 'Overlap policy',
    name: 'formattedOverlapHandling' as keyof T,
    width: 200,
    readOnly: true
  }]
}
