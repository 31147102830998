import * as React from 'react';

import { Contact } from 'app2/api';
import { EmailField, InputField, PhoneField } from 'app2/components';

interface Props {
}

export function participantFields<T extends Contact = any>(props?:Props) {
  return [{
    label: "Name",
    name: 'name' as keyof T,
    required: true,
    component: InputField,
    sortable: true,
    filterable: true
  }, {
    label: "Email",
    name: 'email' as keyof T,
    required: true,
    component: EmailField,
    sortable: true,
    filterable: true
  }, {
    label: "Phone",
    name: 'phone' as keyof T,
    required: true,
    component: PhoneField,
    sortable: true,
    filterable: true
  }, {
    label: "Type",
    name: 'position' as keyof T,
    required: true,
    sortable: true,
    filterable: true
  }, {
    label: "Relationship",
    name: 'relation' as keyof T,
    required: true,
    component: InputField,
    sortable: true,
    filterable: true
  }, {
    label: "Attendance code",
    name: 'attendanceCodeCode' as keyof T,
    required: true,
    sortable: true,
    filterable: true
  }, {
    name: 'owner',
    id: 'family',
    fields: {
      name: {
        label: "Family name",
      }
    },
    // sortable: true,
    // filterable: true
  }]
}
