import { attendanceFields } from '../roster/attendanceFields';
import { courseFields } from '../course/courseFields';
import { discountDefinitionFields } from '../discounts/discountDefinitionFields';
import { enrollmentFields } from '../enrollment/enrollmentFields';
import { participantFields } from '../student/participantFields';
import { orderFields } from '../order/orderFields';
import { priceConfigFields } from '../enrollment/priceConfigFields';
import { studentFields } from '../student/studentFields';

import { EntityType } from './EntityType';
import { FieldWithOverrides } from './FieldWithOverrides';

type FieldsFunction = (props:any) => FieldWithOverrides[];

// for some reason TS is complaining incorrectly that name properties
// are required in override field declarations even though they aren't
// so casting is needed below

export const fieldRegistry:Partial<Record<EntityType, FieldWithOverrides[] | FieldsFunction>> = {
  'Attendance': attendanceFields,
  'Contact': participantFields as FieldsFunction,
  'Course': courseFields,
  'DiscountDefinition': discountDefinitionFields as FieldsFunction,
  'Enrollment': enrollmentFields,
  'Order': orderFields,
  'PriceConfig': priceConfigFields,
  'Student': studentFields as FieldsFunction,
}
