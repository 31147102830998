import { captureLogRocketSessions } from 'app/helpers';
import { eventManager, CLIENT_VERSION } from 'app2/api';

import { scriptManager } from 'app/scriptLoading/ScriptManager';

import { ILogRocket } from './ILogRocket';
import { LogRocketShim } from './LogRocketShim';

declare global {
  interface Window {
    LogRocket: ILogRocket;
    __lrs: string;
  }
}

const logRocketUrl = 'https://cdn.logrocket.io/LogRocket.min.js';
let logRocketShim: LogRocketShim | undefined;

window.__lrs = 'pending';

export function loadLogRocket() {
  window.LogRocket = logRocketShim = new LogRocketShim();

  if (captureLogRocketSessions()) {
    scriptManager.register(logRocketUrl, true, onLoadLogRocket);
  }
  else {
    window.__lrs = 'not capturing';
  }
}

function onLoadLogRocket() {
  LogRocket.init('ae8jag/homeroom', {
    rootHostname: window.location.hostname.endsWith('homeroom.com') ? 'homeroom.com' : window.location.hostname,
    release: CLIENT_VERSION, network: {requestSanitizer}},
  );

  if (logRocketShim) {
    logRocketShim.applyShimCalls();
    logRocketShim = undefined;
  }

  // this exposes the LogRocket session url in a way the e2e tests can get it easily
  window.Sentry.setContext('logRocket', { sessionURL: 'pending' });

  LogRocket.getSessionURL(function(sessionURL: string) {
    window.__lrs = sessionURL;
    eventManager.onLogRocketUrlUpdated(sessionURL);
    window.Sentry.setContext('logRocket', { sessionURL });
  });
}

const requestWithPw:any = {
  '/api/auth': true,
  '/api/auth/sign_in': true,
  '/api/auth/password': true
}

function requestSanitizer(request:any) {
  try {
    const url = new URL(request.url)
  
    if (!requestWithPw[url.pathname]) {
      return request;
    }
  
  
    const body = JSON.parse(request.body);
    delete body.password;
    delete body.password_confirmation;
    
    return {
      ...request,
      body: JSON.stringify(body)
    };
  }
  catch(e) {
    // for some reason lr is passing some things that def not requests
    return request;
  }
}
