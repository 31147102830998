import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
import { EnrollmentFieldsFragmentDoc } from '../../../gql/generated/enrollmentFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BilledUsageEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type BilledUsageEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              status: Types.EnrollmentStatusEnum;
              rosterPeriod?: string | null | undefined;
              billPeriod?: string | null | undefined;
              billedDate?: string | null | undefined;
              paymentStatus: Types.PaymentStatus;
              paymentService?: Types.PaymentService | null | undefined;
              paymentFailureReason?: string | null | undefined;
              perSessionRefunds: boolean;
              refundsTotal: number;
              listPrice: number;
              purchaseAmount: number;
              id: string;
              createdAt: string;
              discountAmount: number;
              discountCodes: Array<string>;
              balance: number;
              pickup?: string | null | undefined;
              dismissal?: string | null | undefined;
              groups?: Array<string> | null | undefined;
              priceConfig?:
                | { description: string }
                | {
                    billingDate?: string | null | undefined;
                    formattedOverlapHandling?: string | null | undefined;
                    formattedNonBillableTime: string;
                    listRate?: number | null | undefined;
                    sessionStart: string;
                    sessionEnd: string;
                    timeBilled: string;
                    timeInCourse: string;
                    roundingIncrement?: number | null | undefined;
                    gracePeriod?: number | null | undefined;
                    usageUnit: Types.UsageUnit;
                    attendance?: { date?: string | null | undefined; checkedInAt?: string | null | undefined; checkedOutAt?: string | null | undefined } | null | undefined;
                  }
                | null
                | undefined;
              parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
              family?: { id: string; parents?: Array<{ id: string; name?: string | null | undefined; email: string }> | null | undefined } | null | undefined;
              enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    firstNameWithNickname: string;
                    name: string;
                    grade: string;
                    age?: number | null | undefined;
                    dob?: string | null | undefined;
                    nickname?: string | null | undefined;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const BilledUsageEnrollmentsDocument = gql`
  query BilledUsageEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort, $limit: Int) @urql(additionalTypenames: ["RosterItem", "Enrollment"]) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: COURSE, enrollmentStatus: BILLED, filters: $filters, sort: $sort, limit: $limit) {
      total
      items {
        ...enrollmentFields
        status
        rosterPeriod
        billPeriod
        billedDate
        paymentStatus
        paymentService
        paymentFailureReason
        perSessionRefunds
        refundsTotal
        priceConfig {
          ... on CustomPriceConfig {
            description
          }
          ... on UsagePriceConfig {
            attendance {
              date
              checkedInAt
              checkedOutAt
            }
            billingDate
            formattedOverlapHandling
            formattedNonBillableTime
            listRate
            sessionStart
            sessionEnd
            timeBilled
            timeInCourse
            roundingIncrement
            gracePeriod
            usageUnit: unit
          }
        }
        listPrice
        purchaseAmount
      }
    }
  }
  ${EnrollmentFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type BilledUsageEnrollmentsArgs = MakeOptional<UseQueryArgs<BilledUsageEnrollmentsQueryVariables, BilledUsageEnrollmentsQuery>, 'query'>;

export function useBilledUsageEnrollmentsQuery(options: BilledUsageEnrollmentsArgs = {}) {
  return useQuery<BilledUsageEnrollmentsQueryVariables, BilledUsageEnrollmentsQuery>({ query: BilledUsageEnrollmentsDocument, ...options });
}

export type BilledUsageEnrollmentsOptions = ExecQueryOptions<BilledUsageEnrollmentsQueryVariables>;

export function billedUsageEnrollments(options: BilledUsageEnrollmentsOptions) {
  return executeQuery<BilledUsageEnrollmentsQuery, BilledUsageEnrollmentsQueryVariables>(BilledUsageEnrollmentsDocument, options);
}

export type BilledUsageEnrollmentsSelections = BilledUsageEnrollmentsQuery['rosterByGrouping']['items'][0];

(BilledUsageEnrollmentsDocument as any).additionalTypenames = ['RosterItem', 'Enrollment'];
