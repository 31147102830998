import * as React from 'react';

import { FamilyAttendanceTable, useFamilyParameter } from 'app2/views/shared'
import { ParentPage } from 'app2/views/parent/shared';

import { FamilyAttendanceDocument, useFamilyAttendanceQuery } from './generated';

export function FamilyAttendance() {
  const family = useFamilyParameter();
  const variables = {family};
  
  function render() {
    return <ParentPage title='Attendance'>
      <FamilyAttendanceTable query={useFamilyAttendanceQuery} document={FamilyAttendanceDocument} variables={variables} />
    </ParentPage>
  }

  return render();
}
