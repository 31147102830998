import * as React from 'react'
import queryString from 'query-string';

import { embedMessageManager, hasAppMode, Img } from 'app2/components';

import { oauthRedirectUrl } from './oauthRedirectUrl';
import signInWithGoogle from './sign-in-with-google.png';
import signUpWithGoogle from './sign-up-with-google.png';

interface Props {
  type: 'login' | 'sign-up';
}

export function GoogleOAuthButton(props:Props) {
  const embedded = hasAppMode('embed');

  const {redirect} = queryString.parse(window.location.search);
  const url = oauthRedirectUrl('google_oauth2', JSON.stringify({origin: props.type, redirect}));

  function render() {
    return <a href={url} style={{width:'max-content'}} onClick={onClick}>
      <Img src={props.type == 'login' ? signInWithGoogle : signUpWithGoogle} width='175px' />
    </a>
  }

  function onClick(event:React.MouseEvent) {
    if (!embedded) {
      return;
    }

    event.preventDefault();
    embedMessageManager.sendMessage('navigate-to', {url});
  }

  return render();
}
