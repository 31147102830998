import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
export type RegistrationDisplayFieldsFragment = {
  registrationDisplayConfig?:
    | {
        tab?: string | null | undefined;
        groups?: Array<string> | null | undefined;
        sorts?: Array<string> | null | undefined;
        layout?: Types.SeasonRegistrationLayout | null | undefined;
        showDates?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export const RegistrationDisplayFieldsFragmentDoc = gql`
  fragment registrationDisplayFields on Season {
    registrationDisplayConfig {
      tab
      groups
      sorts
      layout
      showDates
    }
  }
`;
