import moment from 'moment';
import { groupBy } from 'lodash-es';

import { Course, Grade } from '../graphql';
import { DeepPartial } from '../'

import { CourseDayUtils } from './CourseDayUtils';
import { CourseSortFilterUtils } from './CourseSortFilterUtils';
import { CourseUtils } from './CourseUtils';
import { formatAges } from './formatAge';

export class CourseLegacySortFilterUtils {  
  static groupCoursesByGrade<T extends SortableFilterableCourse>(courses:T[], siteGrades?:Grade[]): GroupedCourses<T>[] {
    const groups = groupBy(courses, course => CourseUtils.formatCourseGrades(course, siteGrades));
    const grouped:GroupedCourses<T>[] = [];
  
    for (let group in groups) {
      grouped.push({label:group, courses:groups[group].sort(that.compareCourses)});
    }
  
    return grouped.sort((a:GroupedCourses<T>, b:GroupedCourses<T>) => CourseSortFilterUtils.compareGrades(a.courses[0], b.courses[0]));
  }

  // TODO cleanup the old usage that is using time not days and is very confusing
  // so confusing that i didnt want to change the names and copied and created new functions

  static sortCoursesByCourseDayTime2<T extends SortableCourse = SortableCourse>(courses:T[]) {
    return courses.slice().sort(that.compareCoursesDayTime2);
  }

  static compareCoursesDayTime2<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    return CourseDayUtils.compareCourseDays2(c1.courseDays, c2.courseDays) || c1.name?.localeCompare(c2.name) || (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf());
  }

  static compareCourses<T extends SortableCourse = SortableCourse>(c1:T, c2:T) {
    return (moment(c1.startDate).valueOf() - moment(c2.startDate).valueOf()) || CourseDayUtils.compareCourseDays(c1.courseDays, c2.courseDays) || c1.name?.localeCompare(c2.name);
  }
}

// normally you can use this.fnName() inside of CourseUtils code, but we've seen
// cases where the this pointer is not bound correctly, so this allows shorthand
// over CourseUtils.fnName() which can get long.
const that = CourseLegacySortFilterUtils;

type SortableCourse = DeepPartial<Pick<Course, 'name' | 'courseDays' | 'grades' | 'startDate'| 'ageMin' | 'ageMax'>>;
type SortableFilterableCourse = DeepPartial<Pick<Course, 'id' | 'name' | 'courseTags' | 'courseDays' | 'grades' | 'startDate' | 'endDate' | 'ageMin' | 'ageMax'>>;

export interface GroupedCourses<T extends SortableFilterableCourse> {
  label: string;
  courses: T[];
}
