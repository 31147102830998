import * as React from 'react';
import moment from 'moment'
import { DocumentNode } from 'graphql';

import { Attendance, FilterOperator, SortDirection } from 'app2/api';
import { Button, compareDateArrays, DateInput, DataTable, DatePicker } from 'app2/components';
import { ContentType, createReportViewFromTableV2, downloadReport, HrDataTable, HrDataTableProps, PAGE_WIDTH, useCols } from 'app2/views/shared'

interface Props extends Pick<HrDataTableProps<Attendance>, | 'query' | 'views'> {
  document:DocumentNode;
  variables:any;
  header?:Partial<HrDataTableProps<Attendance>['header']>;
  table?:Partial<HrDataTableProps<Attendance>['table']>;
  cols?:string[];
}

export function FamilyAttendanceTable(props:Props) {
  const {query, document, variables, views, header, table:tableProps, cols:propsCols} = props;
  
  const [dateRange, setDateRange] = React.useState<DateInput[]>(getLastAndCurrentMonthRange());
  const colOptions = {sorts: [{name: 'sessionDateInTz', direction: SortDirection.Desc}], filters: [{name: 'sessionDateInTz', op:FilterOperator.Between, value: dateRange}]};

  const cols = useCols<Attendance>('Attendance', propsCols, colProps, colOptions);
  const table = React.useRef<DataTable>();

  function render() {
    return <HrDataTable
      header={{ icon: 'Clock', title: 'Attendance', primaryActions: renderPrimaryActions(), ...header }}
      table={{ ref: table, cols, cellStyle: 'read-only', fillWidth: true, rowSelection: false, rowNumbers:true, pageWidth: PAGE_WIDTH, onViewChange, none: 'No attendances', ...tableProps }}
      views={views} queryHook={query} queryOptions={{variables}} sortFilterType='v2'
    />
  }

  function renderPrimaryActions() {
    return [
      <DatePicker type='range' value={dateRange} onChange={onChangeDate} placeholder='Enter date range' />,
      <Button onClick={downloadCsv} icon='Download' iconPosition='left' autoLoader kind='secondary'>CSV</Button>,
      <Button onClick={downloadPdf} icon='Download' iconPosition='left' autoLoader>PDF</Button>
    ]
  }

  function getLastAndCurrentMonthRange() {
    return [moment().subtract(1, 'month').startOf('month'), moment().endOf('month')]
  }
  
  function onViewChange(table:DataTable<Attendance>) {
    const col = table.getColumn('sessionDateInTz');
    const dates = col.filter;

    if (compareDateArrays(dates, dateRange)) {
      return;
    }

    setDateRange(dates || []);
  }

  function onChangeDate(event:React.ChangeEvent<DatePicker>) {
    const dates = event.target.value as DateInput[];

    setDateRange(dates);
    table.current.setColFilter('sessionDateInTz', dates?.length ? dates : undefined);
  }

  function downloadCsv() {
    return downloadReport('Homeroom students', ContentType.CSV, createReportViewFromTableV2(document, variables, table.current));
  }

  function downloadPdf() {
    return downloadReport('Homeroom students', ContentType.PDF, createReportViewFromTableV2(document, variables, table.current));
  }

  return render();
}

FamilyAttendanceTable.colNamesWoStudent = ['sessionDateInTz', 'enrollment.course.name', 'formattedSessionStartTime', 'formattedSessionEndTime', 'checkedIn', 'formattedCheckedInTime', 'checkedInBy.name', 'checkedOut', 'formattedCheckedOutTime', 'checkedOutBy.name', 'usageInstance.priceConfig.timeInCourse',  'usageInstance.priceConfig.timeBilled', 'usageInstance.priceConfig.formattedNonBillableTime', 'usageInstance.priceConfig.listRate', 'usageInstance.listPrice', 'usageInstance.discountAmount', 'usageInstance.purchaseAmount', 'usageInstance.refundsTotal', 'usageInstance.balance', 'paymentStatus', 'usageInstance.priceConfig.billingDate']
FamilyAttendanceTable.colNames = ['student.nameWithNickname'].concat(FamilyAttendanceTable.colNamesWoStudent);
const colProps = {role: 'public'};

FamilyAttendanceTable.defaultProps = {
  cols: FamilyAttendanceTable.colNames
}

