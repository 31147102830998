import * as React from 'react';
import { omit } from 'lodash-es';

import { authorizedPreferences, authorizedCoursePreferenceKeys, CourseKind, CourseUtils } from 'app2/api';
import { Button, findCol, mergeCols } from 'app2/components';
import { CoursesTable, useCourseCols, useCourseTableState, nonCancelledStatuses, removePathSegment } from 'app2/views/shared';

import { finalizeCourses } from '../../course';

import { CourseSelections } from '../generated';

const PREFS_VERSION = "1"

interface Props {
  course: CourseSelections;
}

export function TimeSlots(props:Props) {
  const course = props.course;
  const state = useCourseTableState();
  const queryVars = {courseId: course?.id, kinds: [CourseKind.TimeSlot], statuses:nonCancelledStatuses};

  const entityInfo = CourseUtils.getEntityInfo(props.course);
  const views = entityInfo ? {...entityInfo, table: 'courses-time-slots'} : undefined;

  function render() {
    return <CoursesTable
      header={{icon:'Clock', title:'Time slots', options: [], primaryActions:renderPrimaryActions()}} kind={CourseKind.TimeSlot}
      queryVars={{...queryVars, totals: [nonCancelledStatuses]}}
      courseModel={getCourseModel()}
      state={state}
      showAppend={true}
      defaultValues={getCourseDefaults()}
      prefs={authorizedPreferences.coursePreferences(course?.id)}
      prefsKey={authorizedCoursePreferenceKeys.courseVariantsTable}
      prefsVersion={PREFS_VERSION}
      views={views}
    />
  }

  function renderPrimaryActions() {
    const abilities = CourseUtils.getCourseAbilities(course);

    return abilities.finalizable && <Button onClick={handleFinalize}>Send to organizer</Button>
  }

  function getCourseModel() {
    const courseModel = useCourseCols({site:course?.site?.id, courses:state.coursesCollection, queryVars, timezone: course?.site?.timezone, defaultSort: {name: 'courseDays'}});

    return React.useMemo(() => {
      const courseDaysCol = findCol(courseModel.cols, 'courseDays');
      return {
        ...courseModel, 
        cols: mergeCols([{name:'name', tab:'/enrollments'}, {...courseDaysCol, edit:{...courseDaysCol.edit, add:false}}, 'teacher', 'enrolledCount', 'waitlistedCount'], courseModel.cols)
      }
    }, [courseModel])
  }

  function getCourseDefaults() {
    if (!course) {
      return {};
    }

    return {
      ...omit(course, 'id', 'courseDays', 'children', 'kind'),
      searchable: true,
    }
  }

  function handleFinalize() {
    finalizeCourses(actionOptions('Activities sent to the organizer'));
  }

  function actionOptions(successMsg: string) {
    return {variables:{ids:[course.id]}, successMsg, error:{transform: removePathSegment('ids.0')}};
  }

  return render();
}
