import * as React from 'react';

import { MultiContext, MultiContextProvider } from 'app2/components';

import { useRouteInfo } from './useRouteInfo';

interface Props {
  children:React.ReactNode;
}

export function RouteContainer(props:Props) {
  const info = useRouteInfo();

  return <MultiContextProvider<RouteContext> routeInfo={info}>
    {props.children}
  </MultiContextProvider>
}

export interface RouteContext {
  routeInfo:ReturnType<typeof useRouteInfo>
}

export function useRouteContext() {
  return React.useContext<RouteContext>(MultiContext).routeInfo;
}
