import * as React from 'react'
import { useLocation, useParams } from 'react-router';
import { groupBy } from 'lodash-es';

import { CourseFilterOptions, CourseSortFilterUtils } from 'app2/api';
import { Box, hasAppMode, Link, HBox, Tabs, Text, useForm, VBox } from 'app2/components';
import { PublicPage, InactiveWarning, useCart } from 'app2/views/shared-public';

import { PublicSiteParams } from '../../publicRoutes';
import { SiteHeader } from '../shared';

import { Filter } from './Filter';
import { Season } from './Season';
import { PublicSeason } from './PublicSeason';
import { usePublicSiteQuery } from './generated';

export function Site() {
  const location = useLocation();
  const privateSeasons = location.pathname.startsWith('/sites/private');

  const filterOptions = useForm<CourseFilterOptions>({}, []);
  const site = getSite();
  const seasons = getSeasons();
  const anySeasons = seasons?.filter(s => s.seasons?.length > 0)?.length != 0;
  const { cart } = useCart();

  const embedded = hasAppMode('embed');

  function render() {
    return <PublicPage above={renderSiteHeader()} useMaxWidth pt={null} markingLinksWhenLoggedIn={false}>
      {!site
      ? <HBox minHeight='200px' />
      : !site.recentSeasons.length
        ? renderNone()
        : renderListings()}
    </PublicPage>
  }

  function renderSiteHeader() {
    return !embedded ? <SiteHeader site={site} /> : undefined;
  }

  function renderNone(type:string = '') {
    return <HBox minHeight='200px'><Text text='subtitle1' mb='$60'>{site.name} doesn't have any {type.toLocaleLowerCase()} activities ready for enrollment. Check back soon or let us know if something is missing!</Text></HBox>
  }

  function renderListings() {
    if (!anySeasons) {
      return renderNone();
    }

    const prefix = privateSeasons ? '/sites/private' : '/sites';
    const mode = location.pathname.includes('/group/') ? 'hide-tabs' : undefined;

    return <>
      <InactiveWarning site={site} />
      <Tabs arrows width='100%' baseUrl={`${prefix}/${site.slug}/:tab`} urlParameter='tab' urlHideSuffix='-listing' preserveQuery={false} mode={mode}
        tabs={seasons.filter(s => s.seasons?.length).map((s, index) => ({label:s.label, name: s.name, content: renderTab(s, index)}))}/>
    </>
  }

  function renderTab(group:ReturnType<typeof getSeasons>[0], index:number) {
    if (!group.seasons?.length) {
      return renderNone(group.label);
    }

    return <div key={index}>
      {!embedded && <Filter filterOptions={filterOptions} site={site.id} seasons={group.seasons.map(s => s.season)} />}
      <VBox gap='$40'>
        {renderSeasonsNav(group)}
        {group.seasons?.map(s => <Season key={s.season.id} filterOptions={filterOptions} site={site} season={s.season} slug={s.slug} courses={s.courses} cart={cart} />)}
        </VBox>
      </div>
  }

  function renderSeasonsNav(group:ReturnType<typeof getSeasons>[0]) {
    if (group.seasons?.length < 2) {
      return;
    }

    const prefix = findCommonSeasonNamePrefix(group);

    return <HBox gap='0px 12px' flexWrap='wrap'>{group.seasons.map(s => <Link key={s.season.id} to={`#${s.slug}`}>{s.season.name.substring(prefix.length)}</Link>)}</HBox>;
  }

  function findCommonSeasonNamePrefix(group:ReturnType<typeof getSeasons>[0]): string {
    if (group.seasons?.length === 0) {
      return '';
    }

    const names = group.seasons.map(s => s.season.name);
    const firstNameWords = names[0].split(/\s+/);
    let commonPrefix = '';

    for (let i = 0; i < firstNameWords.length; i++) {
      // don't cross word boundaries and have common prefixes that include just part of 
      // words, such as is seasons are - 2025 summer and 2025 spring, dont allow
      // a common prefix of 2025 s (that would lead to links of ummer and pring)
      // prefix should be 2025 , and links should be summer and spring
      const testPrefix = firstNameWords.slice(0, i + 1).join(' ') + ' ';
      
      if (names.every(name => name.startsWith(testPrefix))) {
        commonPrefix = testPrefix;
      }
      else {
        break;
      }
    }

    return commonPrefix;
  }

  function getSite() {
    const { site:siteId } = useParams<PublicSiteParams>();
    const [result] = usePublicSiteQuery({variables:{id:siteId, private: privateSeasons}});

    return result?.data?.site;
  }

  function getSeasons() {
    return React.useMemo(() => {
      const seasons = site?.recentSeasons;
      const groupedByLabel = groupBy(seasons, 'registrationGroup');

      return Object.keys(groupedByLabel).sort().map(label => {
        return {
          seasons: groupedByLabel[label].map(filterSeason),
          name: encodeURIComponent(label.split(/[ \(\)\/\.]/).map(w => w.toLowerCase()).join('-')),
          label
        }
      });
    }, [site, filterOptions.version])

    function filterSeason(season:PublicSeason) {
      return {
        season,
        slug: season.name.replace(/\s+/g, '-'),
        courses: CourseSortFilterUtils.filterCourses(season.courses.filter(c => c.searchable), filterOptions.values) 
      }
    }  
  }

  return render();
}
