import * as React from 'react';

import { ActionButton, DataTable, Modal } from 'app2/components';

import { CourseSelections } from '../../../generated';
import { RefundModal } from './RefundModal';

interface Props {
  course: CourseSelections;
  table: DataTable;
}

export function RefundButton(props:Props) {
  const {course, table} = props;

  function render() {
    if (!course?.behavior?.enrollmentsRefund) {
      return <></>;
    }
    
    return <ActionButton icon="Refund" autoLoader={false} tooltip="Refund and optionally remove" onClick={onClick}>
      Refund
    </ActionButton>
  }

  function onClick() {
    return Modal.show(<RefundModal course={course} enrollments={table.selections.selectedItems} />);
  }
  
  return render();
}
