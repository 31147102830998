import * as React from 'react'

import { Tabs } from 'app2/views/shared-public';

import { GeneralInfo } from '../general-info';
import { PaymentTools } from '../payment-tools';
import { Team } from '../team';

import { CompanyPage, useBasicCompany } from '../shared'

export function Setup() {
  const { company } = useBasicCompany();

  return <CompanyPage title='Setup'>
    <Tabs baseUrl={`/provider/${company?.slug}/setup/:tab`} urlParameter='tab' arrows tabs={[{
      label: 'General info',
      content: <GeneralInfo />,
      name: 'general-info',
    }, {
      label: 'Payment tools',
      content: <PaymentTools />,
      name: 'payment',
    }, {
      label: 'Team',
      content: <Team />,
      name: 'team',
    } ]} />
    
  </CompanyPage>
}