import * as React from 'react';

import { hasAppMode } from 'app2/components/AppModes'
import { Maintenance } from 'app2/views/shared-public/maintenance';

import { IdleReloader } from 'app2/components';
import { AppStatus, getStatus } from './AppStatus';

interface Props {
  children:React.ReactNode;
}

interface State {
  status: AppStatus;
  maintenanceRequestCount: number;
}

export class AppStatusMonitor extends React.Component<Props, State> {
  static REQUEST_FREQUENCY = 1000 * 60 * 2; //every 2 minutes
  static REQUEST_FREQUENCY_MAINTENANCE = 1000 * 60 * .25; //every 15 seconds
  static idleReloader = new IdleReloader();

  requestInterval: any;
  state: State = { status: AppStatus.ok, maintenanceRequestCount: 0 };

  constructor(props:Props) {
    super(props);

    // this is currently turned off
    // note that the idle detector is still on though
    
    // this.onRequest();
    // this.createInterval(this.state.status);
  }

  render() {
    if (this.state.status != AppStatus.maintenance || this.state.maintenanceRequestCount < 3) {
      return this.props.children;
    }

    return <Maintenance />
  }

  createInterval(status:AppStatus) {
    if (hasAppMode('test')) {
      return;
    }

    if (this.requestInterval !== undefined) {
      clearInterval(this.requestInterval);
      this.requestInterval = undefined;
    }

    const frequency = status == AppStatus.maintenance ? AppStatusMonitor.REQUEST_FREQUENCY_MAINTENANCE : AppStatusMonitor.REQUEST_FREQUENCY;
    this.requestInterval = setInterval(this.onRequest, frequency);
  }

  onRequest = async () => {
    const status = await getStatus();

    if (this.state.status != status) {
      this.createInterval(status);
    }

    this.setState({ status, maintenanceRequestCount: status == AppStatus.maintenance ? this.state.maintenanceRequestCount + 1 : 0 });
  };
}
