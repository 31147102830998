import * as React from 'react'
import { useHistory } from 'react-router';

import { login } from 'app2/api';
import { Panel, FormModel, HBox, EmailField, Link, InputField, Section, Text, VBox } from 'app2/components';
import { PublicPage, useRestrictedSiteAccountRegistration } from 'app2/views/shared-public';

import { GoogleOAuthButton } from './GoogleOAuthButton';
import { useExternalAuthRequired } from './useExternalAuthRequired';
import { handleAuthResponse } from './handleAuthResponse';

export function Login() {
  const history = useHistory();
  const restricted = useRestrictedSiteAccountRegistration();
  const {authProvider, checkDomain} = useExternalAuthRequired()

  function render() {
    return <PublicPage title='Log in' useMaxWidth>
      <VBox gap='$30'>
        {renderAuthProviders()}
        <VBox gap='$8'>
          <Text text='subtitle2'>Log in with email</Text>
          <Panel type='edit' onOk={!authProvider ? submit : null} ok={renderSubmit()} footerActions={renderFooter()} onNavigation='nothing'>
            <Section name='email' label='Email address' component={EmailField} onChange={checkDomain} />
            <Section name='password' label='Password' component={InputField} type='password' disabled={!!authProvider} />
            <Link to='/password_reset'>Forgot password?</Link>
          </Panel>
        </VBox>
      </VBox>
    </PublicPage>
  }

  function renderAuthProviders() {
    return renderGoogleOauth()
  }

  function renderSubmit() {
    return !authProvider ? 'Login' : renderGoogleOauth()
  }

  function renderGoogleOauth() {
    return <GoogleOAuthButton type='login' />
  }

  function renderFooter() {
    if (restricted) {
      return;
    }

    return <HBox text='body' vAlign='center' gap='$8'>New to Homeroom? <Link to={`/register`+ window.location.search + window.location.hash}>Sign up here</Link></HBox>
  }

  async function submit(form:FormModel) {
    const response = await login(form.values)
    return handleAuthResponse(form, response, history);
  }

  return render();
}
