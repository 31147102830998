export function getFirstVisibleHorizontally(parent:HTMLElement) {
  const parentRect = parent.getBoundingClientRect();
  let child = parent.firstElementChild as HTMLElement;

  while (child) {  
    const rect = child.getBoundingClientRect();
    
    if (rect.right > parentRect.left && rect.left < parentRect.right) {
      return child;
    }

    child = child.nextElementSibling as HTMLElement;
  }

  return null;
}
