import * as React from 'react';

import { NavigationSection, NavigationLink } from 'app2/views/shared-public';

import { getParentPath } from './parentRoutes';

export const parentLinks = <NavigationSection icon='Users' name='Family'>
  <NavigationLink to={getParentPath('schedule')} label='Schedule' />
  <NavigationLink to={getParentPath('attendance')} label='Attendance' />
  <NavigationLink to={getParentPath('payment')} label='Payment' />
  <NavigationLink to={getParentPath('family')} label='Family' />
  <NavigationLink to={getParentPath('messages')} label='Messages' />
</NavigationSection>
