import moment from 'moment'
import { startCase } from 'lodash-es';

import { CourseDay } from "../graphql";

import { DAY_SORT_KEY, WEEKDAYS_SET, WEEKDAYS, WEEKDAYS_ABBR } from './constants';
import { CourseDayName } from './CourseDayName';

export const COURSE_TIME_FORMAT = 'h:mm A'

export class CourseDayUtils {
  static isValid(day:CourseDay) {
    return day.days.filter(day => WEEKDAYS_SET.has(day as CourseDayName)).length && CourseDayUtils.isValidTime(day.start) && CourseDayUtils.isValidTime(day.finish);
  }

  static isValidTime(time:string) {
    const m = moment(time, COURSE_TIME_FORMAT);

    return m.isValid();  
  }

  static parseCourseDay(day:string) {
    day = startCase(day.trim());

    const pos = WEEKDAYS_ABBR.indexOf(day);

    if (pos == -1) {
      return day;
    }

    return WEEKDAYS[pos];
  }

  // this probaly functions similar to both compareCourseDays2 and compareCourseDays
  // but is much clearer - it gets the earliest time of week in the course days
  // and compares those

  static compareCourseDaysTimeOfWeek(a: Partial<CourseDay>[], b: Partial<CourseDay>[]) {
    const earliestA = a.reduce((earliest, day) => Math.min(earliest, CourseDayUtils.parseTimeOfWeek(day)?.valueOf() || 0), Infinity);
    const earliestB = b.reduce((earliest, day) => Math.min(earliest, CourseDayUtils.parseTimeOfWeek(day)?.valueOf() || 0), Infinity);

    return earliestA - earliestB;
  }

  // TODO remove in favor of compareCourseDaysTimeOfWeek

  static compareCourseDays2(a:Partial<CourseDay>[], b:Partial<CourseDay>[]):number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareCourseDayTime(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static compareCourseDayTime(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    return CourseDayUtils.compareWeekdays(a?.days, b?.days) || CourseDayUtils.compareTimeOfDay(a?.start, b?.start)
  }

  // TODO remove in favor of compareCourseDaysTimeOfWeek

  static compareCourseDays(a:Partial<CourseDay>[], b:Partial<CourseDay>[]):number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareCourseDay(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static compareCourseDay(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    return CourseDayUtils.compareCourseDayPrioritizeTime(a, b);
    //return CourseDayUtils.compareCourseDayPrioritizeDay(a, b);
  }

  static compareCourseDayPrioritizeTime(a:Partial<CourseDay>, b:Partial<CourseDay>):number {
    const result = CourseDayUtils.compareTimeOfDay(a?.start, b?.start)
  
    return result
      ? result
      : CourseDayUtils.compareWeekdays(a?.days, b?.days)
  }

  static compareCourseDayPrioritizeDay(a:CourseDay, b:CourseDay):number {
    const result = CourseDayUtils.compareWeekdays(a?.days, b?.days);
  
    return result
      ? result
      : CourseDayUtils.compareTimeOfDay(a?.start, b?.start)
  }

  // this assumes the courseday.days are sorted
  static parseTimeOfWeek(day: CourseDay) {
    return moment(`${day.days[0]} ${day.start}`, 'dddd h:mm A');
  }

  static parseTime(time:string) {
    return moment(time, COURSE_TIME_FORMAT);
  }

  static compareTimeOfDay(a:string, b:string) {
    return (CourseDayUtils.parseTime(a).valueOf() - CourseDayUtils.parseTime(b).valueOf())
  }

  static compareWeekdays(a: string[], b: string[]): number {
    const max = Math.max(a?.length, b?.length) || 0;
    let index = 0;
  
    while (index < max) {
      const result = CourseDayUtils.compareDays(a[index], b[index]);
  
      if (result) {
        return result;
      }
  
      ++index;
    }
  
    return 0;
  }

  static sortCourseDays(courseDays:CourseDay[]) {
    return (courseDays || []).slice().sort(CourseDayUtils.compareCourseDay);
  }  
  
  static compareDays(a: string, b: string): number {
    if (!a && !b) {
      return 0;
    }
  
    if (!a) {
      return -1;
    }
  
    if (!b) {
      return 1;
    }
  
    const aValue = DAY_SORT_KEY[a.toLowerCase()];
    const bValue = DAY_SORT_KEY[b.toLowerCase()];

    return aValue - bValue;
  }
  
  static sortDays(days:string[]) {
    return days.slice().sort(this.compareDays);
  }
}
