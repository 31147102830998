import * as React from 'react';
import { useHistory } from 'react-router';

import { CourseKind, CoursesTableWithTabsPreferences } from 'app2/api';
import { colId, Tabs, TabsHandle, showCols, orderCols, scrollIntoView, sleep, VBox, Text, Point } from 'app2/components';
import { courseKindBehavior } from 'app2/views/shared-public';

import { CoursesQueryVars } from '../course';

import { CoursesTableTab } from './CoursesTableTab';
import { CoursesTableTabLabel } from './CoursesTableTabLabel';
import { CoursesTable, CourseTableProps } from './CoursesTable';
import { useCourseCols, UseCourseColsProps, allCourseCols } from './useCourseCols';
import { ShowAdvanced } from './ShowAdvanced';
import { CreateActivities } from './CreateActivities';

interface Props extends Omit<CourseTableProps, 'cols' | 'courseModel' | 'queryVars' | 'prefs'>, Partial<Omit<UseCourseColsProps, 'courses' | 'draft' | 'queryVars' | 'defaultStatusFilter'>> {
  kind:CourseKind;
  tabs:CoursesTableTab[];
  queryVars:Partial<Pick<CoursesQueryVars, 'seasonId' | 'companyId' | 'courseId' | 'kinds'>>;
}

export function CoursesTableWithTabs(props:Props) {
  const { kind, tabs, state, timezone, site, course, queryVars:propsQueryVars, header, ...remainingTableProps } = props;

  const behavior = courseKindBehavior[props.kind];

  const {tab, tabProps} = getTab();
  const queryVars = {kinds: behavior.kinds, statuses: tab.statuses, totals: tabs.map(tab => tab.statuses), ...propsQueryVars};
  const {courseModel, showAdvanced, showOrgProvider} = getCourseModel();

  const history = useHistory();
  checkForEdit();

  function render() {
    const {tabs, ...remainingTabProps} = tabProps;

    return <Tabs width='100%' selected={state.tab} {...remainingTabProps} tabs={tabs.map(renderTab)} />
  }

  function renderTab(tab:CoursesTableTab, index:number) {
    const total = state.coursesQueryResult?.totals?.[index]
    const currentTab = state.tab == index;
    const hide = !total && tab.hideEmpty && (!state.editing || !currentTab) && !state.loading;

    if (hide && currentTab) {
      setTimeout(() => state.setTab(state.tab + 1), 0);
    }

    return {
      label: <CoursesTableTabLabel name={tab.label} count={total} />,
      name: tab.name,
      display: hide ? 'none' : undefined,
      content: <CoursesTable tableRef={state.table} prefs={state.prefs[tab.name]} prefsKey='base' kind={kind}
        saveChanges={tab.save} none={tab.none || renderNone()} state={state} queryVars={queryVars} 
        header={{primaryActions: tab?.primaryActions, saveActions: tab?.saveActions, secondaryActions:renderSecondaryActions(), title:behavior.label, icon:behavior.icon, total, onStopEditing, ...header}}
        courseModel={courseModel} {...remainingTableProps} 
      />
    }
  }

  function renderSecondaryActions() {
    const secondaryActions = state.editing 
      ? tab.secondaryActions?.editing || tab.secondaryActions?.display 
      : tab.secondaryActions?.display || tab.secondaryActions?.editing;

    return [...secondaryActions || [], tab?.useAdvanced !== false ? <ShowAdvanced state={state} /> : undefined];
  }

  function renderNone() {
    return <VBox gap='$20'>
      <Text>Nothing here yet!</Text>
      {props.table?.editable !== false && <CreateActivities kind={kind} />}
    </VBox>
  }

  function getTab() {
    const tabProps = {
      tabsRef: state.tabs || React.useRef<TabsHandle>(),
      pref: state.prefs,
      prefName: 'tab' as keyof CoursesTableWithTabsPreferences,
      tabs,
      onChange: onTabChange
    }

    const tab = tabProps.tabs[Math.min(Math.max(0, state.tab), tabProps.tabs.length - 1)];

    return {tab, tabProps};
  }

  function getCourseModel() {
    const {cols, onFormReset, courseCounts} = useCourseCols({courses: state.coursesCollection, timezone, site, course, defaultStatusFilter: tab.defaultStatusFilter, queryVars});
    const supportsOrgProvider = tab?.cols?.orgProvider?.length > 0;
    const showOrgProvider = supportsOrgProvider && courseCounts.orgProvider > 0;
    const showAdvanced = state.showAdvanced;

    const courseModel = React.useMemo(() => {
      const colsToShow = state.showAdvanced
          ? showOrgProvider
            ? tab.cols.orgProvider.concat(tab.cols.orgProviderAdvanced)
            : tab.cols.base.concat(tab.cols.advanced || allCourseCols)
          : showOrgProvider
            ? tab.cols.orgProvider
            : tab.cols.base;

      // we can't override the base hidden, so remove those
      const filteredColsToShow = colsToShow.filter(c => !cols.find(c2 => colId(c2) == colId(c))?.hidden);
      const orderedCols = orderCols(showOrgProvider ? tab.cols.orgProvider : tab.cols.base, showCols(cols, filteredColsToShow, true), false);

      return {cols: orderedCols, onFormReset, courseCounts}
    }, [cols, showOrgProvider, showAdvanced]);

    return {courseModel, showOrgProvider, showAdvanced};
  }

  function checkForEdit() {
    const hasEditMode = hasUrlEditMode()

    React.useEffect(() => {
      if (!hasEditMode || state.editing) {
        return;
      }

      navigateToDraftTabAndEdit();
    }, [hasEditMode]);
  }

  function hasUrlEditMode() {
    const params = new URLSearchParams(history.location.search);
    const editTable = params.get('edit');
    const editThisTab = editTable?.toLocaleLowerCase() == behavior?.name?.toLocaleLowerCase();

    return editThisTab;
  }

  function removeEditMode() {
    if (hasUrlEditMode()) {
      history.replace(history.location.pathname);
    }
  }

  async function navigateToDraftTabAndEdit() {
    // hack to wait for history updates to complete
    await sleep(250);

    const draftTab = tabs.length - 1;
    
    if (state.tab != draftTab) {
      state.setTab(draftTab);
    }

    // hack fix to deal with the fact that we freeze the courses query on edit
    // mode so we need to allow the tab's course query to fire and complete
    // before we go into edit and we can't check the loading state immediately
    // because the tab might not have rendered yet

    const start = Date.now();
    let startedLoading = state.loading;
    let duration = Date.now() - start;

    while ((!startedLoading && duration < 1000) || state.loading) {
      await sleep(100);

      startedLoading = state.loading;
      duration = Date.now() - start;
    }

    if (!state?.table?.current?.rootElement) {
      return;
    }

    scrollIntoView(state.table.current.rootElement, 'smooth', new Point(20, -40));
    state.setEditing(true);
  }

  async function onStopEditing() {
    if (!hasUrlEditMode()) {
      return;
    }

    while (state.editing) {
      await sleep(100);
    }

    removeEditMode();
  }

  function onTabChange(tab:number) {
    state.setEditing(false);
    state.setTab(tab);
    onStopEditing();
  }

  return render();
}
