import * as React from 'react';

import { DataTable } from '../DataTable';

export function useTableEditing(table:DataTable):[boolean, (val: boolean) => void] {
  const [editing, setEditingInternal] = React.useState(false);

  function setEditing(val:boolean) {
    setEditingInternal(val);

    if (val) {
      setTimeout(() => table?.focus?.());
    }
  }

  return [editing, setEditing];
}
