import * as React from 'react';

import { useParams } from 'react-router';

import { Modal, MenuItem, OptionsMenu, VBox } from 'app2/components';
import { CfQuestionsEditor, Module, removePath } from 'app2/views/shared';
import { Tabs, Tab } from 'app2/views/shared-public';

import { SeasonRouteParams } from '../organizerRoutes';
import { PromotionalDiscounts, StudentDiscounts, SeasonDropdown, useCurrentSite, useOrganizerSeasonQuery } from '../shared';

import { SeasonCourses } from './SeasonCourses';
import { SeasonForm } from './SeasonForm';
import { AdvancedSeasonSettings } from './advanced';
import { organizerDeleteSeason } from './gql';

export function CurrentSeason() {
  const { season: seasonId } = useParams<SeasonRouteParams>();
  const { site } = useCurrentSite();
  const [seasonResult] = useOrganizerSeasonQuery({ variables: { seasonId } });
  const season = seasonResult.data?.season;

  function render() {
    return <Module title="Scheduled seasons" actions={<OptionsMenu><MenuItem icon="Trash2" iconSize='small' onClick={handleDeleteClick} autoLoader={false}>Delete season</MenuItem></OptionsMenu>}>
      <SeasonDropdown />
      {seasonId && <Tabs baseUrl={`/organizer/${site?.slug}/seasons/${seasonId}/:tab`} urlParameter='tab' arrows tabs={[
        {label: 'Details', name:'details', content:<VBox gap='$30'><SeasonForm title="Season details" site={site} season={season} /><SeasonCourses /></VBox>},
        {label: 'Registration questions', name:'questions', content:<CfQuestionsEditor owner={season} courseKinds={season?.courseKindGroups} />},
        {label: 'Discounts', name:'discounts', content:<PromotionalDiscounts ownerId={seasonId} ownerType="season" />},
        {label: 'Student discounts', name:'student-discounts', content:<StudentDiscounts ownerId={seasonId} ownerType="season" />},
        {label: 'Advanced', name:'advanced', content:<AdvancedSeasonSettings season={season} />}
      ]}
      />}
    </Module>
  }

  async function handleDeleteClick() {
    const warningResult = await Modal.warning({
      title: "This action can't be undone",
      content: 'Are you sure you want to delete this season?',
      ok: 'Continue'
    });
    if (!warningResult.action) {
      return;
    }

    const [success] = await organizerDeleteSeason({ variables: { siteId: site.id, ids: season.id }, error: {transform: removePath}, successMsg: 'Season deleted.' });

    return success;
  }

  return render();
}
