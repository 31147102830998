import { useEffect, useRef } from 'react';
import { IdleDetector } from './IdleDetector';

/**
 * useIdleDetector
 * @param onIdle Called when inactivity reached the duration
 * @param idleDuration default 1 hour if not specified
 * @param element The DOM element to attach events to (defaults to window)
 */
export function useIdleDetector(
  onIdle: () => void,
  idleDuration: number = IdleDetector.HOUR,
  element: EventTarget = window
) {
  const idleDetectorRef = useRef<IdleDetector | null>(null);

  useEffect(() => {
    idleDetectorRef.current = new IdleDetector(onIdle, element, idleDuration);

    return () => {
      if (idleDetectorRef.current) {
        idleDetectorRef.current.stop();
        idleDetectorRef.current = null;
      }
    };
  }, [onIdle, idleDuration, element]);
} 