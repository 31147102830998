import { Roles } from './Route';

import { useCurrentUser } from '../user';

import { isRoleAllowed } from './useRouteInfo';

// this will determine if the user is allowed based on the users
// roles for the current site, company, course...these are derived
// from the current url (which useCurrentUser looks at)

export function useIsRoleAllowed(role:Roles[] | Roles) {
  const { user } = useCurrentUser();

  return isRoleAllowed(role, user);
}
