import { useHistory } from 'react-router';
import queryString from 'query-string';

import { ApiResponse } from 'app2/api';
import { FormModel } from 'app2/components';
import { handleErrors } from 'app2/views/shared';

import { oauthRedirectUrl } from './oauthRedirectUrl';

export function handleAuthResponse(form:FormModel, response:ApiResponse, history:ReturnType<typeof useHistory>, defaultRedirect?:string) {
  const {success, errors} = response;

  if (success) {
    const {redirect} = queryString.parse(history.location.search);

    history.replace(redirect || defaultRedirect || '/');
  }
  else
  if (errors?.[0]?.code == "OAUTH_REQUIRED") {
    window.location.href = oauthRedirectUrl((errors[0] as any).provider);
  }
  else {
    handleErrors(form, errors);
    return false;
  }
}