import * as React from 'react'

import { Roles } from './Route';
import { useIsRoleAllowed } from './useIsRoleAllowed';

interface Props {
  role:Roles[] | Roles;
  children:React.ReactElement;
}

export function RoleRestricted(props:Props) {
  const allowed = useIsRoleAllowed(props.role);

  if (!allowed) {
    return <></>;
  }

  return props.children;
}
