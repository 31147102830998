import { ErrorWithStringPath } from 'app2/api';
import { ErrorWithPath, errorToString } from 'app2/components';

import { closeErrorsModal, showErrors } from './showErrors';
import { showForcedRefreshModal } from './showForcedRefreshModal';

export function addPathToMessage(error:ErrorWithStringPath):ErrorWithStringPath {
  return {...error, message: error.message + errorPathToString(error)};
}

export function errorPathToString(error:ErrorWithStringPath) {
  return error.path && error.path.length ? ' (' + error.path.join('.') + ')' : '';
}

export function modalErrorHandler(errors:ErrorWithPath[]):ErrorWithPath[] {
  const authError = errors.find(e => e.code === "AUTHENTICATION_ERROR");

  if (authError) {
    closeErrorsModal();
    loginRequiredRedirect();
    return [];
  }

  const networkError = errors.find(e => e.code === "TIMEOUT_ERROR" || e.code === "NETWORK_ERROR" || e.message === "Failed to fetch");

  if (networkError) {
    showForcedRefreshModal();
    return [];
  }

  showErrors(errors.map(errorToString));

  return [];
}

export function loginRequiredRedirect() {
  window.location.href = `/login?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`
}