import { Action, History, Location, LocationDescriptorObject, LocationState, Path, TransitionPromptHook, LocationListener, UnregisterCallback, Href } from 'history';
import { RouteProps } from 'react-router-dom';

import { findRoute } from './findRoute';

export class HistoryProxy<HistoryLocationState = LocationState, RP = RouteProps> implements History<HistoryLocationState> {
  routes:React.ReactElement<RP>[];
  proxied:History<HistoryLocationState>;
  navigating:boolean;;

  constructor(proxied?:History<HistoryLocationState>, routes?:React.ReactElement<RP>[]) {
    this.proxied = proxied;
    this.routes = routes;

    this.proxied.listen(this.onAction);
  }

  get currentRoute() {
    return this.findRoute(this.location.pathname);
  }

  findRoute(path:string) {
    return findRoute(path, this.routes);
  }

  get length(): number {
    return this.proxied.length;
  }

  get action(): Action {
    return this.proxied.action;
  }

  get location(): Location<HistoryLocationState> {
    return this.proxied.location;
  }

  push = (locationOrPath: Path | LocationDescriptorObject<HistoryLocationState>, state?: HistoryLocationState): void => {
    const location = this.onNavigate(this.createLocation(locationOrPath, state));

    if (!location) {
      return;
    }

    if (this.isSameAsCurrent(location) && !location.state) {
      return;
    }

    this.navigating = true;

    const result = this.proxied.push(location);

    this.navigating = false;

    return result;
  }
  
  replace = (locationOrPath: Path | LocationDescriptorObject<HistoryLocationState>, state?: HistoryLocationState): void => {
    const location = this.onNavigate(this.createLocation(locationOrPath, state));

    if (!location) {
      return;
    }

    if (this.isSameAsCurrent(location) && !location.state) {
      return;
    }

    this.navigating = true;

    const replace = this.proxied.replace(location);

    this.navigating = false;

    return replace;
  }

  go = (n: number): void => {
    this.navigating = true;

    this.proxied.go(n);

    this.navigating = false;
  }

  goBack = (): void => {
    this.proxied.goBack();
}

  goForward = (): void => {
    this.proxied.goForward();
  }

  block = (prompt?: boolean | string | TransitionPromptHook): UnregisterCallback => {
    return this.proxied.block(prompt);
  }

  listen = (listener: LocationListener): UnregisterCallback => {
    return this.proxied.listen(listener);
  }

  createHref = (location: LocationDescriptorObject<HistoryLocationState>): Href => {
    return this.proxied.createHref(location);
  }

  onAction = (location: Location, action: Action):void => {
    if (this.navigating) {
      return;
    }

    this.onNavigate(this.createLocation(location, location.state));
  }

  isSameAsCurrent(location:LocationDescriptorObject<HistoryLocationState>) {
    return location.pathname == this.proxied.location.pathname && location.search == this.proxied.location.search && location.hash == this.proxied.location.hash;
  }

  // note that onNavigate currently can only update push & replace calls coming into the history
  // it can not update browser forward/backward, but will get called
  onNavigate(location: LocationDescriptorObject<HistoryLocationState>):LocationDescriptorObject<HistoryLocationState> {
    return location;
  }

  createLocation(locationOrPath: Path | LocationDescriptorObject<HistoryLocationState>, state?: HistoryLocationState):LocationDescriptorObject<HistoryLocationState> {
    let location:LocationDescriptorObject<HistoryLocationState>

    if (typeof locationOrPath == 'string') {
      let url:URL;

      try {
        url = new URL(locationOrPath);
      }
      catch(e) {
        if (locationOrPath.startsWith('#')) {
          locationOrPath = this.location.pathname + this.location.search + locationOrPath;
        }
        else
        if (!locationOrPath.startsWith('/')) {
          const path = this.location.pathname.split('/');
          path.pop();

          locationOrPath = path.join('/') + '/' + locationOrPath
        }

        url = new URL(window.location.origin + locationOrPath);
      }

      location = {pathname:url.pathname, search:url.search, hash:url.hash, state};
    }
    else {
      location = locationOrPath;
    }
    
    return location;
  }
}
