import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteWithTrackingParametersQueryVariables = Types.Exact<{
  site: Types.Scalars['ID'];
}>;

export type SiteWithTrackingParametersQuery = {
  site?: { usingTrackingParameters?: boolean | null | undefined; trackingParameters?: Array<{ name?: string | null | undefined; value?: string | null | undefined }> | null | undefined } | null | undefined;
};

export const SiteWithTrackingParametersDocument = gql`
  query SiteWithTrackingParameters($site: ID!) {
    site(site: $site) {
      usingTrackingParameters
      trackingParameters {
        name
        value
      }
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SiteWithTrackingParametersArgs = MakeOptional<UseQueryArgs<SiteWithTrackingParametersQueryVariables, SiteWithTrackingParametersQuery>, 'query'>;

export function useSiteWithTrackingParametersQuery(options: SiteWithTrackingParametersArgs = {}) {
  return useQuery<SiteWithTrackingParametersQueryVariables, SiteWithTrackingParametersQuery>({ query: SiteWithTrackingParametersDocument, ...options });
}

export type SiteWithTrackingParametersOptions = ExecQueryOptions<SiteWithTrackingParametersQueryVariables>;

export function siteWithTrackingParameters(options: SiteWithTrackingParametersOptions) {
  return executeQuery<SiteWithTrackingParametersQuery, SiteWithTrackingParametersQueryVariables>(SiteWithTrackingParametersDocument, options);
}

export type SiteWithTrackingParametersSelections = SiteWithTrackingParametersQuery['site'];
