import * as React from 'react';

import { StudentUtils, EnrollmentUtils, Enrollment } from 'app2/api';
import { FieldValue, FieldInfo, HBox, Link, Modal, VBox, Icon, Tag, Text, Img } from 'app2/components';
import { useRouteContext, RouteRole } from 'app2/views/shared-public';
import { DiscountTag, EnrollmentStatus } from 'app2/views/shared'
import scholarshipIcon from 'images/scholarship-lg.svg'

interface Props {
  value?: string;
  info?: FieldInfo<Enrollment>;
  nameKind?: 'first' | 'last' | 'full';
  studentModal?:React.ComponentType<{id:string}>;
  link?:boolean;
  showStudentDiscount?:boolean;
}

export function StudentNameField(props: Props) {
  // length - 1 is the name, length - 2 is the student, length - 3 is enrollment
  // unless there's no student present, then its shifted by 1
  // also this has been hacked to support student records (vs. student via enrollment)
  const rosterItem = props.info.values?.[props.info.values.length - (props.info.value ? 3 : 2)] as (Enrollment)
  const isStudent = (props.info.record as any)?.['__typename'] == 'Student' && (!rosterItem || !((rosterItem as any)['__typename'] == 'RosterItem' || (rosterItem as any)['__typename'] == 'Enrollment'));
  const enrollment = isStudent ? {student: props.info.record} as unknown as Enrollment : rosterItem;
  const invite = enrollment.isInvitation
  const scholarship = enrollment.isScholarshipHold;
  const special = invite || scholarship;

  const route = useRouteContext();

  function render() {
    return route.routeRole == RouteRole.family ? renderFamily() : !special ? renderRegularStudent() : renderSpecialStudent();
  }

  function renderFamily() {
    return <HBox vAlign="center" gap='$4' flexWrap='wrap'>
      <Link to={`/family/family/${enrollment.student.id}`}>{renderStudentName()}</Link>
    </HBox>
  }

  function renderRegularStudent() {
    const linkProps = props.link !== false ? {link:true, underline:true,  onClick: handleClick} : null;

    return <HBox vAlign="center" gap='$4' flexWrap='wrap'>
      <FieldValue {...linkProps}>{renderStudentName()}</FieldValue>
      {renderSpecialTags()}
    </HBox>
  }

  function renderSpecialStudent() {
    const indicator = invite
      ? <EnrollmentStatus enrollment={enrollment as Enrollment} ml="$12" />
      : <Img src={scholarshipIcon} ml="$12" />

    return props.nameKind != 'last'
      ? <HBox hAlign="left" vAlign="center" height="15px" cursor="default" flexWrap='wrap'>
          <FieldValue>{renderStudentName()}</FieldValue>
          {indicator}
        </HBox>
      : <FieldValue>{renderStudentName()}</FieldValue>
  }

  function renderStudentName() {
    return props.nameKind == 'first'
      ? EnrollmentUtils.getStudentFirstName(enrollment as Enrollment)
      : props.nameKind == 'last'
        ? EnrollmentUtils.getStudentLastName(enrollment as Enrollment)
        : EnrollmentUtils.getStudentName(enrollment as Enrollment)
  }

  function handleClick(event: React.MouseEvent) {
    const StudentModal = props.studentModal;
    
    Modal.show(<StudentModal id={enrollment.student.id} />);
  }

  function renderSpecialTags() {
    if (!enrollment.student || props.nameKind == 'last') {
      return;
    }

    return <>
      {renderEpiPenBadge()}
      {renderInfo()}
      {renderStudentDiscount()}
    </>
  }

  function renderEpiPenBadge() {
    if (!enrollment.student.medical?.epiPen) {
      return;
    }

    return <Tag label="EpiPen" bg='errorFill' />
  }

  function renderInfo() {
    const items = [renderAllergies(), renderEpiPen(), renderMedications(), renderConditions(), renderNotes()].filter(item => !!item);

    if (!items.length) {
      return;
    }

    return <Icon size='small' name="Info" tooltip={<VBox gap='$8'>{items}</VBox>} />
  }

  function renderAllergies() {
    if (!enrollment.student.medical?.allergies?.length) {
      return;
    }

    return renderTipEntry('Allergies', StudentUtils.getFormattedAllergies(enrollment.student.medical?.allergies));
  }

  function renderEpiPen() {
    if (!enrollment.student.medical?.epiPen) {
      return;
    }

    return renderTipEntry('EpiPen', 'Yes');
  }

  function renderMedications() {
    if (!enrollment.student.medical?.medications?.length) {
      return;
    }

    return renderTipEntry('Medications', StudentUtils.getFormattedMedications(enrollment.student.medical?.medications));
  }

  function renderConditions() {
    if (!StudentUtils.getHasSpecialNeed(enrollment.student.medical?.conditions)) {
      return;
    }

    return renderTipEntry('Conditions', enrollment.student.medical.conditions);
  }

  function renderNotes() {
    if (!StudentUtils.getHasSpecialNeed(enrollment.student.notes)) {
      return;
    }

    return renderTipEntry('Comments', enrollment.student.notes);
  }

  function renderTipEntry(title:string, content:string | string[]) {
    return <VBox key={title}>
      <Text text='subtitle2'>{title}</Text>
      <VBox><Text text='body'>{content}</Text></VBox>
    </VBox>
  }

  function renderStudentDiscount() {
    if (!props.showStudentDiscount) {
      return;
    }

    if (!enrollment.discountUses?.length) {
      return;
    }

    return enrollment.discountUses.filter(du => du.isStudent).map((du, i) => <DiscountTag key={i} {...du} />)
  }

  return render();
}

StudentNameField.defaultProps = {
  nameKind: 'first',
  showStudentDiscount: false
}

StudentNameField.fieldProps = {
  valueProperty: 'value',
  fieldProperty: 'info',
  none: false
};
