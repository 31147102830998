import { Attendance } from 'app2/api';
import { CourseKindBehavior, CourseLink } from 'app2/views/shared-public'

interface Props<T> {
  behavior?:CourseKindBehavior;
}

export function courseFields<T extends Attendance = any>(props:Props<T>) {
  const nameField = {
    name: 'name' as keyof T,
    width: 250,
    label: props?.behavior?.terms?.name || 'Activity',
    display: CourseLink,
    role: null as string //this is explicitly set to null so its determined by route
  };

  return [
    nameField, {
      ...nameField,
      name: 'disambiguatedName',
  }, {
    name: 'formattedDaysAndTimes',
    label: 'Days and times',
    width: 200
}]
}
