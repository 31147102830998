import * as React from 'react'

import { ThemeProvider, useHistory } from 'app2/components'

import { PopupManager, NotificationManager } from 'app2/components'

import { CurrentUser, CurrentUserProps } from '../user/CurrentUser';

import { RouteContainer } from './RouteContainer';

// this ensures that all things needed for the app
// are setup before any routes render.  it should eventually
// be merged with the old Root & App.

export function PageRoot(props:CurrentUserProps) {
  const {onChangeUser} = props;
  const history = useHistory();

  return <ThemeProvider shield>
    <CurrentUser onChangeUser={onChangeUser}>
      <RouteContainer>
        <NotificationManager />
        {props.children}
        <PopupManager history={history} />
      </RouteContainer>
    </CurrentUser>
  </ThemeProvider>
}
