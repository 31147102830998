import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonProcessPayoutsMutationVariables = Types.Exact<{
  season: Types.Scalars['ID'];
}>;

export type SeasonProcessPayoutsMutation = { seasonProcessPayouts?: { success?: boolean | null | undefined } | null | undefined };

export const SeasonProcessPayoutsDocument = gql`
  mutation SeasonProcessPayouts($season: ID!) {
    seasonProcessPayouts(season: $season) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type SeasonProcessPayoutsOptions = ExecMutationOptions<SeasonProcessPayoutsMutationVariables>;

export function seasonProcessPayouts(options: SeasonProcessPayoutsOptions) {
  return executeMutation<SeasonProcessPayoutsMutation, SeasonProcessPayoutsMutationVariables>(SeasonProcessPayoutsDocument, options);
}
