import { eventManager, StandardEvents } from '../events';
import { ApiResponse, standardizeAxiosApiError } from "../error";
import { GuestCart } from '../parent';
import { urql } from '../urql';

import { axios } from './axios';
import { checkForExternalAuthRequiredResult } from './checkForExternalAuthRequiredResult';
import { getUserFromResponse } from './getUserFromResponse';

export interface RegisterArgs {
  email:string;
  password:string;
  firstName:string;
  lastName:string;
  role:'parent' | 'organizer' | 'provider';
}

export async function register(args:RegisterArgs):Promise<ApiResponse> {
  try {
    const serverArgs = {
      first_name: args.firstName,
      last_name: args.lastName,
      email: args.email,
      password: args.password,
      current_role: args.role,
      // why? the client can just lie about this.
      tos_accepted_at: true,
    }

    const response = await axios.post('/auth', serverArgs);

    if (checkForExternalAuthRequiredResult(response.data)) {
      return response.data;
    }

    urql.reset();
    GuestCart.clearToken();
  
    const user = getUserFromResponse(response.data.data)
    eventManager.logStandard(StandardEvents.CompleteRegistration, {userRole: args.role});
    eventManager.log('login');  
  
    return {success: true, errors: []};
  }
  catch(e) {
    eventManager.log('createNewUserFailed', {
      userEmail: args.email,
      userRole: args.role,
      serverError: e.response.message
    })

    return standardizeAxiosApiError(e, ['first_name', 'last_name', 'email', 'password'], true);
  }
}
