import * as React from 'react'

import { useForceUpdate } from './useForceUpdate'
import { useResizeObserver } from './useResizeObserver'

export function useUpdateOnResize(elementOrRef:HTMLElement | React.RefObject<any>) {
  const update = useForceUpdate();

  function onResize() {
    update();
  }

  useResizeObserver(elementOrRef, onResize);
}
