import { localStorageHelper } from "app/helpers";

export class Preferences {
  container:Preferences;
  name:string;

  constructor(container:Preferences = null, name:string = null) {
    this.container = container;
    this.name = name;
  }

  load<T = any>(name:string, defaultValue?:any):T {
    const val = localStorageHelper.getItem(this.pathName(name));
    try {
      return val !== undefined ? JSON.parse(val) : defaultValue;
    }
    catch(e) {
      return defaultValue;
    }
  }

  save<T = any>(name:string, value:T):void {
    localStorageHelper.setItem(this.pathName(name), JSON.stringify(value));
  }

  clear<T = any>(name:string):void {
    this.save(name, '')
  }

  pathName(name:string) {
    return this.path.join(':') + ':' + name;
  }

  // path prefix to preferences 
  get path():(string | number)[] {
    const p = this.container?.path || [];

    if (this.name) {
      p.push(this.name);
    }

    return p;
  }

  // typesafe way to refer to a preferene prop
  prop<K extends keyof this>(key: K): K {
    return key;
  }

  pref<K extends keyof this>(key: K):Preference {
    return {prefs:this, key};
  }
}

// typesafe to combine a prefs and prop as a pair 
// for compactness when passing things arround
export interface Preference<T extends Preferences = any, K extends keyof T = any> {
  prefs:T
  key:K;
}

export const preferences = new Preferences();

export function preference(target: any, propName: string): void {
  Object.defineProperty(target, propName, {
    get() {
      return this.load(propName);
    },
    set(value: any) {
      this.save(propName, value);
    },
    enumerable: true,
    configurable: true,
  });
}

export function preference_folder<T extends Preferences>(PreferenceType: { new (container:Preferences, name:string): T }) {
  return function(target: any, propName: string):void {
    Object.defineProperty(target, propName, {
      get() {
        return new PreferenceType(this, propName);
      },
      enumerable: true,
      configurable: true,
    });  
  }
}
