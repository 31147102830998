import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentMedicalFieldsFragmentDoc } from '../../../../shared/student/tabs/gql/generated/studentMedicalFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizerSeasonEnrollmentsQueryVariables = Types.Exact<{
  groupingId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.RosterFilters>;
  sort?: Types.InputMaybe<Types.RosterSort>;
  date?: Types.InputMaybe<Types.Scalars['ISO8601Date']>;
}>;

export type OrganizerSeasonEnrollmentsQuery = {
  rosterByGrouping?:
    | {
        total: number;
        items: Array<
          | {
              id: string;
              dismissal?: string | null | undefined;
              pickup?: string | null | undefined;
              overlaps?: boolean | null | undefined;
              balance: number;
              added?: string | null | undefined;
              paymentStatus: Types.PaymentStatus;
              startDate?: string | null | undefined;
              endDate?: string | null | undefined;
              rosterPeriod?: string | null | undefined;
              weekday: string;
              formattedDays: string;
              formattedTimes: string;
              cfSeasonAnswers?: { [key: string]: any } | null | undefined;
              groups?: Array<string> | null | undefined;
              trackingParameters?: string | null | undefined;
              otherEnrollments: Array<{ id: string; formattedDays: string; formattedTimes: string; course: { id: string; name?: string | null | undefined } }>;
              student?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    name: string;
                    grade: string;
                    formattedMedicalAndNotes: string;
                    nickname?: string | null | undefined;
                    dob?: string | null | undefined;
                    formattedContacts: string;
                    notes?: string | null | undefined;
                    classroom?: { siteId: string; displayName: string } | null | undefined;
                    emergencyContacts: Array<{ name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined }>;
                    authorizedPickupContacts: Array<{ name?: string | null | undefined; email?: string | null | undefined; phone?: string | null | undefined } | null | undefined>;
                    medical?:
                      | {
                          epiPen?: boolean | null | undefined;
                          conditions?: string | null | undefined;
                          allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
                          medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              parent?: { id: string; name?: string | null | undefined; phone?: string | null | undefined; email: string; ccContacts?: Array<{ email?: string | null | undefined }> | null | undefined } | null | undefined;
              family?: { id: string } | null | undefined;
              course: {
                id: string;
                name?: string | null | undefined;
                disambiguatedName?: string | null | undefined;
                room?: string | null | undefined;
                startDate?: string | null | undefined;
                endDate?: string | null | undefined;
                vendor?: { email?: string | null | undefined } | null | undefined;
              };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export const OrganizerSeasonEnrollmentsDocument = gql`
  query OrganizerSeasonEnrollments($groupingId: ID!, $filters: RosterFilters, $sort: RosterSort, $date: ISO8601Date) {
    rosterByGrouping(groupingId: $groupingId, groupingKind: SEASON, enrollmentStatus: ROSTERED, filters: $filters, sort: $sort, date: $date) {
      items {
        id
        dismissal
        pickup
        overlaps
        balance
        added
        paymentStatus
        startDate
        endDate
        rosterPeriod
        weekday
        otherEnrollments {
          id
          formattedDays
          formattedTimes
          course {
            id
            name
          }
        }
        student {
          id
          firstName
          lastName
          name
          grade
          ...studentMedicalFields
          formattedMedicalAndNotes
          nickname
          classroom {
            siteId
            displayName
          }
          dob
          formattedContacts
          emergencyContacts {
            name
            email
            phone
          }
          authorizedPickupContacts {
            name
            email
            phone
          }
        }
        parent {
          id
          name
          phone
          email
          ccContacts {
            email
          }
        }
        family {
          id
        }
        formattedDays
        formattedTimes
        course {
          id
          name
          disambiguatedName
          room
          startDate
          endDate
          vendor {
            email
          }
        }
        cfSeasonAnswers
        groups
        trackingParameters
      }
      total
    }
  }
  ${StudentMedicalFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type OrganizerSeasonEnrollmentsArgs = MakeOptional<UseQueryArgs<OrganizerSeasonEnrollmentsQueryVariables, OrganizerSeasonEnrollmentsQuery>, 'query'>;

export function useOrganizerSeasonEnrollmentsQuery(options: OrganizerSeasonEnrollmentsArgs = {}) {
  return useQuery<OrganizerSeasonEnrollmentsQueryVariables, OrganizerSeasonEnrollmentsQuery>({ query: OrganizerSeasonEnrollmentsDocument, ...options });
}

export type OrganizerSeasonEnrollmentsOptions = ExecQueryOptions<OrganizerSeasonEnrollmentsQueryVariables>;

export function organizerSeasonEnrollments(options: OrganizerSeasonEnrollmentsOptions) {
  return executeQuery<OrganizerSeasonEnrollmentsQuery, OrganizerSeasonEnrollmentsQueryVariables>(OrganizerSeasonEnrollmentsDocument, options);
}

export type OrganizerSeasonEnrollmentsSelections = OrganizerSeasonEnrollmentsQuery['rosterByGrouping']['items'][0];
