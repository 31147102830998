import * as React from 'react';
import moment from 'moment';
import { debounce } from "lodash";

import { CourseUtils, DiscountFormat, EnrollmentStatusFilter, authorizedCoursePreferenceKeys } from 'app2/api';
import { Body, DataTable, DataTableColumn, ChangeMap, iso8601Date, Icon, Link, PercentField, VBox, formatDate, useRef } from 'app2/components';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { BilledLaterUsageEnrollmentsSelections, useBilledLaterUsageEnrollmentsQuery, rosterItemsSetAdditionalDiscount } from './gql';
import { BillNowAction } from './BillNowAction';
import { RoundingIncrement } from './RoundingIncrement';
import { GracePeriod } from './GracePeriod';

const TABLE_PREFS_VERSION = '4';

interface Props {
  course: CourseSelections;
}

export function BilledLaterUsage(props: Props) {
  const cols = getCols();
  const tableRef = useRef<DataTable<BilledLaterUsageEnrollmentsSelections>>();

  function render() {
    if (!CourseUtils.usingUsageRateOrPrice(props.course?.rates)) {
      return null;
    }

    return (
      <EnrollmentsTable<BilledLaterUsageEnrollmentsSelections>
        course={props.course}
        header={{ id:'billed-later', title: <>Billed later<Link ml='$20' text='body' to='billing#billed'>Billed<Icon name='ArrowUp' size='small' /></Link></>, subtitle: renderSubtitle(), units: 'attendances', editing: true, options: renderOptions() }}
        table={{ cols, ref: tableRef, none: 'No attendances', cellStyle: 'read-only', editable: true, atLeastOneEditableRow: false, sortFilterWhenEditable: true, appendable: false, keepDirty: true, onDataUpdate: onOverriddenRateChange }}
        view={{table:'course-billed-later-usage'}}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.billedLaterUsageEnrollmentsTable}
        queryHook={useBilledLaterUsageEnrollmentsQuery}
        queryOptions={{ variables: { limit: 1000 } }}
      />
    );
  }

  function renderSubtitle() {
    const usageBillingDate = props.course?.usageBillingDate;
    if (!usageBillingDate) {
      return null;
    }

    return (
      <VBox>
        <Body>Billing date: {formatDate(moment.utc(usageBillingDate), 'long')}</Body>
        <RoundingIncrement course={props.course} />
        <GracePeriod course={props.course} />
      </VBox>
    );
  }

  function renderOptions() {
    return [<BillNowAction course={props.course} />]
  }

  function getCols() {
    const baseCols = React.useMemo(() => {
      return [
        'student.firstName',
        'student.lastName',
        'usageDate',
        'priceConfig.sessionStart',
        'priceConfig.sessionEnd',
        'usageCheckedInAt',
        'usageCheckedOutAt',
        'priceConfig.timeInCourse',
        'priceConfig.timeBilled',
        'usageRate',
        'listPrice',
        'discountAmount',
        'purchaseAmount',
        'roundingIncrement',
        'gracePeriod',
        'priceConfig.formattedOverlapHandling',
        'nonBillableTime',
        'discountCodes',
        {
          ...PercentField,
          name: 'additionalDiscount.rate' as keyof BilledLaterUsageEnrollmentsSelections,
          label: 'Attendance discount (%)',
          width: 220,
          readOnly: false,
          placeholder: 'Enter discount %',
        } as DataTableColumn<BilledLaterUsageEnrollmentsSelections>,
        'priceTier',
        'student.grade',
        'groups'
      ];
    }, [props.course]);
    const cols = useCourseEnrollmentCols<BilledLaterUsageEnrollmentsSelections>(baseCols, props.course, EnrollmentStatusFilter.BilledLaterUsage, false);

    return cols;
  }

  const onOverriddenRateChange = debounce(async () => {
    tableRef.current.save(async (changes:ChangeMap<BilledLaterUsageEnrollmentsSelections>) => {
      const discounts = Object.keys(changes).map(enrollmentId => {
        const billedLater = changes[enrollmentId].item;

        return {
          course: props.course.id,
          student:billedLater.student.id,
          date:iso8601Date(billedLater.priceConfig.attendance.date),
          discount: !billedLater.additionalDiscount || !billedLater.additionalDiscount.rate
          ? null
          : {
            rate: billedLater.additionalDiscount.rate,
            format: DiscountFormat.Percentage,
          }
        }
      });

      if (!discounts.length) {
        return true;
      }

      const [success] = await rosterItemsSetAdditionalDiscount({variables:{discounts}});

      return success;
    })
  }, 1000);

  return render();
}

