import * as React from 'react'

import { CourseFilterOptions, CourseSortFilterUtils, NestedGroupedCourses } from 'app2/api';
import { FormModel, hasAppMode, HBox, VBox, Text, Body } from 'app2/components';
import { Cart, CourseCard, CourseCardCourse, CourseKindBehavior, courseKindBehavior } from 'app2/views/shared-public'

import { SeasonHeader } from './SeasonHeader'
import { PublicSiteSelections } from './generated';
import { PublicSeason } from './PublicSeason';

type Course = PublicSeason['courses'][0];

interface Props {
  filterOptions:FormModel<CourseFilterOptions>;
  site:PublicSiteSelections;
  season:PublicSeason;
  slug:string;
  courses:Course[];//filtered courses, not the same as season.courses
  cart:Cart;
}

export function Season(props:Props) {
  const hideHeader = hasAppMode('embed') && !hasAppMode('season-header');

  function render() {
    return <VBox width='100%' id={props.slug}>
      {props.site?.partner && !hideHeader && <SeasonHeader site={props.site} season={props.season} />}
      {renderNone()}
      {renderCourses()}
    </VBox>
  }

  function renderNone() {
    if (!props.season.courses.length) {
      return <Text text='subtitle2' mb='$8'>There are no enrolling activities yet</Text>
    }

    if (!props.courses.length) {
      return <Text text='subtitle2' mb='$8'>There are no activities that match your filters</Text>
    }

    return;
  }

  function renderCourses() {
    if (!props.courses?.length) {
      return;
    }

    const byGrade = props.filterOptions.values.byGrade || props.filterOptions.values.byAge;
    const groups = byGrade ? ['grade'] : props.season.registrationDisplayConfig?.groups;
    const sorts = props.season.registrationDisplayConfig?.sorts;
    const grouped = CourseSortFilterUtils.groupCourses(groups, sorts, props.courses, {siteGrades: props.site.grades});

    return <VBox gap='$30'>{grouped.map(group => renderCourseGroup(group))}</VBox>
  }

  function variantIsOnDay(course:CourseCardCourse, day:string) {
    return course.courseDays.find(cd => cd.days.indexOf(day) != -1) != null
  }

  const groupRenderers = {
    'day': renderCourseDayGroup
  }

  function renderCourseGroup(group:NestedGroupedCourses<PublicSeason['courses'][0]>, variantFilter?:(c:CourseCardCourse) => boolean) {
    const fnName = group.group as keyof typeof groupRenderers;
    const fn = groupRenderers[fnName] || renderCourseGroupDefault;

    return fn(group, variantFilter);
  }

  function renderCourseDayGroup(group:NestedGroupedCourses<PublicSeason['courses'][0]>) {
    const day = group.label;
    const isWeekend = day == 'Saturday' || day == 'Sunday';
    const noCourses = !group.courses?.length;

    if (isWeekend && noCourses) {
      return '';
    }

    return renderCourseGroupDefault(group, c => variantIsOnDay(c, day))
  }

  function renderCourseGroupDefault(group:NestedGroupedCourses<PublicSeason['courses'][0]>, variantFilter?:(c:CourseCardCourse) => boolean) {
    const title = group.label;

    return <VBox key={title}>
      {title && <Text text='subtitle2' mb="$4">{title}</Text>}
      <HBox flexWrap='wrap' gap='$16'>{
        group.groups?.length
          ? group.groups.map(g => renderCourseGroup(g, variantFilter))
          : renderCourseGroupCourses(group.courses as PublicSeason['courses'], variantFilter)
      }
      </HBox>
    </VBox>
  }

  function renderCourseGroupCourses(courses:PublicSeason['courses'], variantFilter?:(c:CourseCardCourse) => boolean) {
    return !courses?.length 
      ? <Body>No activities</Body>
      : courses.map(c => renderCourse(c, variantFilter))
  }

  function renderCourse(c:Course, variantFilter?:(c:CourseCardCourse) => boolean) {
    const cart = props.cart;

    return <CourseCard key={c.id} course={c} displayConfig={props.season.registrationDisplayConfig} siteCompanies={props.site.siteCompanies} cart={cart} partner={props.site.partner} variantFilter={variantFilter} />;
  }

  return render();
}
