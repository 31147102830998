import * as React from 'react';
import { OperationContext, UseQueryResponse, UseQueryState } from 'urql';
import { castArray } from 'lodash-es';

import { Preferences, Preference, SortDirection } from 'app2/api';
import { GroupedDataTable, GroupedDataTableProps, DataTable, DataTableHeader, DataTableColumn, DataTableColumnSort, DataTableHeaderProps, ExpressionNode, MenuItem, SkipColPrefs, HBox, VBox, Subtitle2, noopFormatter, colId, useTablePrefs } from 'app2/components';

import { autoPauseQuery, UseQueryArgs } from '../urql';

import { useVersionedSortFilter } from './useVersionedSortFilter';
import { GetStandardFilterOptionsQuery } from './getStandardFilterOptions';
import { TableView, TableViewsDropdown, TableViewProps } from './table-views';

export type GeneratedQueryArgs<V = any, D = any> = Omit<UseQueryArgs<V, D>, 'query'> & {query?:UseQueryArgs<V, D>['query']};
export type GeneratedQueryHook<V = any, D = any> = ((args: GeneratedQueryArgs) => UseQueryResponse<V, D>);
export type QueryReexecute = (opts?: Partial<OperationContext>) => void;

export type HrDataTableHeaderProps = Omit<DataTableHeaderProps, 'children'>;
export type DataTableProps<T> = Omit<GroupedDataTableProps<T>, 'data'> & Partial<Pick<GroupedDataTableProps<T>, 'data'>>;

export const MIN_COL_WIDTH = 138;
export type TableViewsProps = Omit<TableViewProps, 'tableRef' | 'prefs' | 'onChange' | 'resetToDefaultView'>;

export interface HrDataQueryResult<T> {
  total?:number;
  count?:number;
  items:T[];
  totals?:number[];
}

export interface HrDataTableProps<T> {
  views?:TableViewsProps;
  header: HrDataTableHeaderProps;
  table: DataTableProps<T>;
  // deprecated, use pref
  prefs?: Preferences;
  // deprecated, use pref
  prefsKey?: string;
  pref?: Preference;
  prefsVersion?: string;
  prefsSkipAttributes?:SkipColPrefs;
  query?: GeneratedQueryHook;
  queryHook?: GeneratedQueryHook; // deprecated, use query
  queryOptions?: GeneratedQueryArgs;
  queryResponseHandler?:(response:UseQueryState<any, any>, reexecute:QueryReexecute) => HrDataQueryResult<T>;
  sortFilterType: 'v1' | 'v2';
  filterOptionsQuery?:GetStandardFilterOptionsQuery; //applies only to v2
  totals?:DataTableColumn[];
  filterSummaries?:string[];
}

// a version of above that has things partial so that other base components can fill in defaults
export interface PartialHrDataTableProps<T> extends Omit<HrDataTableProps<T>, 'header' | 'table'> {
  header?: Partial<HrDataTableHeaderProps>;
  table?: Partial<DataTableProps<T>>;
}

export function HrDataTable<T>(props: HrDataTableProps<T>) {
  let {pref, prefs, prefsKey, prefsVersion} = props;

  if (pref) {
    prefs = pref.prefs;
    prefsKey = pref.key;
  }

  // currently we don't have any ui to show/hide columns, so when cols are hidden its code
  // defined and we shouldn't save that attribute for now
  const prefsSkipAttributes = (props.prefsSkipAttributes || []);//.concat('hidden');
  const { loadedPrefs, savePrefs, mergePrefs, resetToDefaultView } = useTablePrefs(props.table, prefs, prefsKey, prefsVersion, prefsSkipAttributes);
  const { cols, queryVars, handleSortFilter } = useVersionedSortFilter(props.sortFilterType, loadedPrefs.cols, props.queryOptions, loadedPrefs.advancedFilter, props.filterOptionsQuery);
  const queryOptions = { ...props.queryOptions, pause: props.queryOptions?.pause || (props.queryOptions?.autoPause === undefined ? autoPauseQuery(props.queryOptions) : false), variables: { ...props.queryOptions?.variables, ...queryVars } };

  const [result, reexecute] = (props.query || props.queryHook)?.(queryOptions as UseQueryArgs<any, any>) || []; // Codegen inserts query document.
  const info = React.useMemo(() => props.queryResponseHandler(result, reexecute), [result]);
  const tableRef = props.table.ref as any || React.useRef<DataTable<T>>();
  const header = {...headerDefaults, total: total(), aboveShowing: renderTableViews(), totals: renderTotalsAndFilterSummaries(), ...props.header, primaryActions: renderPrimaryActions(), options: renderOptions()};
  const Table = header.groupByEnabled ? GroupedDataTable : DataTable;

  function render() {
    return (
      <DataTableHeader {...header}>
        {/* ts wont like that we toggle between GroupedDataTable and DataTable, but it works
        //@ts-ignore */}
        <Table<T> data={info?.items} onSortFilter={handleSortFilter} hideable={!!props.views} {...tableDefaults} {...props.table} ref={tableRef} lockedCol={loadedPrefs.lockedCol} cols={cols} none={result?.fetching ? undefined : props.table?.none} onViewChange={onDataTableViewChange} />
      </DataTableHeader>
    );
  }

  function renderPrimaryActions() {
    const actions = castArray(props.header.primaryActions)?.slice() || [];

    return actions;
  }

  function renderOptions() {
    const options = props.header.options?.slice() || [];

    if ((props.prefs || props.pref) && !props.views) {
      options.push(<MenuItem onClick={resetToDefaultView}>Reset table settings</MenuItem>);
    }
  
    return options;
  }

  function renderTableViews() {
    if (!props.views) {
      return;
    }

    return <TableViewsDropdown {...props.views} prefs={loadedPrefs} tableRef={tableRef} advancedFilter={loadedPrefs.advancedFilter} resetToDefaultView={resetToDefaultView} onChange={onTableViewChange} onChangeAdvancedFilter={onChangeAdvancedFilter} />;
  }

  function renderTotalsAndFilterSummaries() {
    const totals = renderTotals();
    const filters = renderFilterSummaries();

    if (!totals && !filters) {
      return;
    }

    return <VBox width='100%' gap='$12'>
      {totals}
      {filters}
    </VBox>
  }

  function renderTotals() {
    if (!props.totals) {
      return null;
    }

    const nameValues = props.totals.map((nameValue, index) => [nameValue.label, (nameValue.format || noopFormatter)(info?.totals?.[index])]);

    return renderSubtitleTable(nameValues);
  }

  function renderFilterSummaries() {
    if (!props.filterSummaries) {
      return;
    }

    const nameValues = props.filterSummaries.filter(c => c !== undefined).map((summary) => {
      const col = (cols as DataTableColumn[]).find(col => colId(col) == summary);
      return [typeof col?.label == 'string' ? `${col?.label}(s)` : col?.label, col ? (col.filter ? col.filter?.join(', ') : 'All') : '']
    })

    return renderSubtitleTable(nameValues);
  }

  function renderSubtitleTable(nameValues:any[][]) {
    if (!nameValues?.length) {
      return;
    }

    const widths = [1, 'unset', ((1 / nameValues.length) * 100)]
    return <HBox gap='$8' flexWrap='wrap' hAlign='justify' layout={['vbox', 'hbox', 'hbox']} width='100%'>{nameValues.map((nameValue, index) =>
      <VBox key={index} flex={widths} layout={['hbox', 'vbox', 'vbox']} hAlign={['justify', 'left', 'left']} gap='2px'>
        <Subtitle2 whiteSpace='nowrap'>{nameValue[0]}</Subtitle2>
        <Subtitle2 fontWeight='normal' maxLines={1}>{(nameValue[1])}</Subtitle2>
      </VBox>
    )}
    </HBox>
  }

  function total() {
    return info?.total ?? info?.count;
  }

  function onDataTableViewChange(table:DataTable) {
    savePrefs(table);

    props.table?.onViewChange?.(table);
  }

  function onTableViewChange(view:TableView) {
    // the datatable stores sort and filter on the cols so we have to move them out
    // of the view and put them on the col.  this is basically the inverse of useSortFilterV2.

    const cols = view.cols.map(c => ({...c, filter: undefined, sort: undefined} as Partial<DataTableColumn>));
    view.filters?.forEach(f => {
      const col = cols.find(c => c.name == f.name)

      if (!col) {
        return;
      }

      col.filter ||= [];
      col.filter = col.filter.concat(castArray(f.value));
    });

    view.sorts?.forEach(s => {
      const col = cols.find(c => c.name == s.name)

      if (!col) {
        return;
      }

      col.sort = s.direction == SortDirection.Asc ? DataTableColumnSort.ascending : DataTableColumnSort.descending;
    });

    savePrefs({id: view.id, cols, lockedCol: view.lockedCol})
  }

  function onChangeAdvancedFilter(filter:ExpressionNode): void {
    savePrefs({cols, advancedFilter:filter})
  }

  return render();
}

HrDataTable.defaultProps = {
  queryResponseHandler: (response:UseQueryState<any, any>) => {
    const result:any = getItemsValue(response?.data)

    return !result || result?.items !== undefined ? result : {items:result};
  },
  sortFilterType: 'v1'
}

type ArrayOrItems = any[] | {items:any[]};

function getItemsValue(obj:any):ArrayOrItems {
  if (obj === undefined || obj === null) {
    return;
  }

  const val = Object.values(obj)[0] as ArrayOrItems;

  if (Array.isArray(val)) {
    return val;
  }

  if (val === undefined || val === null) {
    return;
  }

  if ('items' in val) {
    return val;
  }

  return getItemsValue(val);
}

const headerDefaults = {
  width: '100%',
  mb: '$15'
}

const tableDefaults:Partial<GroupedDataTableProps<any>> = {
  editable: false,
  defaultColWidth: MIN_COL_WIDTH,
  minHeight: '370px',
  fillWidth: true,
  colHeaderHeight: 54,
  rowHeight:64,
  unstickyX: true
}
