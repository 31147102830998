import { DocumentNode } from 'graphql';
import { OperationContext, OperationResult } from 'urql';

import { urql } from 'app2/api';
import { ErrorWithPath, Modal, ModalProps, NotificationManager, NotificationOptions, errorToString, ShieldConsumer } from 'app2/components';
import { handleErrors, ErrorInfo, ErrorHandlerInfoOrHandlers, showErrors } from 'app2/views/shared/error';

import { fixDateVars } from './fixDateVars';

export interface ExecMutationOptions<V = any> {
  variables: V;
  context?: Partial<OperationContext>;
  successMsg?: NotificationOptions;
  error?: ErrorHandlerInfoOrHandlers;
  warning?: ModalProps;
  shield?: ShieldConsumer;
}

export type ExecMutationResult<D = any, V = any> = Promise<[boolean, OperationResult<D, V>, ErrorWithPath[]]>

export async function executeMutation<D = any, V extends object = {}>(query: DocumentNode, options: ExecMutationOptions<V>):ExecMutationResult<D, V> {
  if (options.warning) {
    const result = await Modal.warning(options.warning);
    if (!result.action) {
      return [false, null, null];
    }
  }

  options.shield?.loader?.(true);
  
  const variables = fixDateVars(options.variables);
  const result = await urql.client.mutation<D, V>(query, variables, options.context).toPromise();

  options.shield?.loader?.(false);

  const errorInfo: ErrorInfo = handleErrors(options.error, result);

  if (errorInfo.success && options.successMsg) {
    NotificationManager.add(options.successMsg);
  }

  return [errorInfo.success, result, errorInfo.unhandled];
}

export type MutationFunction<V, D> = (options: ExecMutationOptions<V>) => ExecMutationResult<D, V>;