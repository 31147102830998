import * as React from 'react'
import { useParams } from 'react-router';

import { EntityKind, organizerPreferences, Student } from 'app2/api';
import { DataTable, MenuItem, VBox } from 'app2/components';
import { ContentType, createReportViewFromTableV2, downloadReport, HrDataTable, useCols } from 'app2/views/shared'

import { EnrollmentRouteParams } from '../organizerRoutes';

import { SiteStudentsDocument, useSiteStudentsQuery } from './generated';

const PREFS_VERSION = '1'

export function Students() {
  const { site } = useParams<EnrollmentRouteParams>();

  const colProps = React.useMemo(() => ({site}), [site]);
  const cols = useCols<Student>('Student', allCols, colProps);

  const variables = {site};

  const table = React.useRef<DataTable>();

  function render() {
    return <VBox>
      <HrDataTable
        header={{ icon: 'Users', title: 'Students', options: renderOptions() }}
        table={{ ref: table, cols, cellStyle: 'read-only', fillWidth: true, editable: true, atLeastOneEditableRow: false, rowNumbers:false }}
        views={{entityKind:EntityKind.Site, entityId: site, table: `site-family-students`}}
        prefsVersion={PREFS_VERSION} pref={organizerPreferences.pref('familyStudentsTable')}
        queryHook={useSiteStudentsQuery} queryOptions={{variables}} sortFilterType='v2'
      />
    </VBox>
  }

  function renderOptions() {
    return [
      <MenuItem onClick={downloadCsv}>Download CSV</MenuItem>,
      <MenuItem onClick={downloadPdf}>Download PDF</MenuItem>
    ]
  }

  function downloadCsv() {
    return downloadReport('Homeroom students', ContentType.CSV, createReportViewFromTableV2(SiteStudentsDocument, variables, table.current));
  }

  function downloadPdf() {
    return downloadReport('Homeroom students', ContentType.CSV, createReportViewFromTableV2(SiteStudentsDocument, variables, table.current));
  }

  return render();
}

const allCols = ['id', 'firstName', 'lastName', 'name', 'grade', 'age', 'dob', 'enrollmentCount', 'classroom.displayName', 'medical.allergies', 
  'medical.epiPen', 'medical.medications', 'medical.conditions', 'notes', 'parents.0.name', 'parents.0.phone', 'parents.0.email', 'contacts', 'emergencyContacts', 'authorizedPickupContacts']