import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateDomainAuthsMutationVariables = Types.Exact<{
  domains: Array<Types.DomainAuthInput> | Types.DomainAuthInput;
}>;

export type UpdateDomainAuthsMutation = {
  homeroomUpdateDomainAuths?: { homeroom?: { domainAuths?: Array<{ domain: string; provider: Types.DomainOauthProvider }> | null | undefined } | null | undefined } | null | undefined;
};

export const UpdateDomainAuthsDocument = gql`
  mutation updateDomainAuths($domains: [DomainAuthInput!]!) {
    homeroomUpdateDomainAuths(domains: $domains) {
      homeroom {
        domainAuths {
          domain
          provider
        }
      }
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type UpdateDomainAuthsOptions = ExecMutationOptions<UpdateDomainAuthsMutationVariables>;

export function updateDomainAuths(options: UpdateDomainAuthsOptions) {
  return executeMutation<UpdateDomainAuthsMutation, UpdateDomainAuthsMutationVariables>(UpdateDomainAuthsDocument, options);
}
