import * as React from 'react';

import { HBox, Icon } from 'app2/components';

import { CourseSelections } from '../../generated';
import { useCourseFields } from '../../..';

interface Props {
  course: CourseSelections;
}

export function RoundingIncrement(props: Props) {
  const { fields } = useCourseFields(props.course as any)

  const roundingIncrement = props.course?.rates?.usage?.[0]?.roundingIncrement;
  if (!roundingIncrement) {
    return null;
  }

  const tooltip = fields['rates.usage.roundingIncrement'].infoTip;
  return (
    <HBox text="body" vAlign="center">
      Billing increment (round-up): {roundingIncrement} minutes
      <Icon name="Info" size="small" ml="$4" tooltip={tooltip} />
    </HBox>
  );
}
