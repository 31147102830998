import * as React from 'react'
import { useParams } from 'react-router';

import { Contact, EntityKind, organizerPreferences } from 'app2/api';
import { VBox } from 'app2/components';
import { HrDataTable, useCols } from 'app2/views/shared'

import { EnrollmentRouteParams } from '../organizerRoutes';

import { useSiteParticipantsQuery } from './generated';

const PREFS_VERSION = '1'

export function Participants() {
  const { site } = useParams<EnrollmentRouteParams>();
  const cols = useCols<Contact>('Contact', allCols);

  return <VBox>
      <HrDataTable
        header={{ icon: 'Users', title: 'Contacts' }}
        table={{ cols, cellStyle: 'read-only', fillWidth: true, editable: true, atLeastOneEditableRow: false, rowNumbers:false }}
        views={{entityKind:EntityKind.Site, entityId: site, table: `site-family-participants`}}
        prefsVersion={PREFS_VERSION} pref={organizerPreferences.pref('familyParticipantsTable')}
      queryHook={useSiteParticipantsQuery} queryOptions={{variables:{site}}} sortFilterType='v2'
      />
  </VBox>
}

const allCols = ['family.name', 'name', 'email', 'phone', 'position', 'relation', 'attendanceCodeCode']