import * as React from 'react';
import moment from 'moment';
import { get, first, last } from 'lodash-es';

import { CourseDay, Course, CourseKind, CourseFramework, CourseUtils, CourseTag, DeepPartial, Site, Teacher, Vendor, CourseStatus, RateType, RecurringUnit, UsageUnit, OverlapHandling } from 'app2/api'
import { clearOther, CurrencyField, DateField, Dropdown, DropdownField, DropdownProps, arrayToString, EmailLabelField, FieldInfo, FieldPlaceholder, iso8601Date, MultipleDateField, FormModel, NumberField, PhoneLabelField, CheckboxField, relatedEndDate, relatedEndTime, onlyString, onlyStringArray, palette, DateInput, validateDateRange, validateTimeRange, smallTagProps, TimeField } from 'app2/components'
import { AgeField, BetaLabel, CourseTagsDropdown, OptionalCourseTag, MultipleGradeField, courseKindBehavior, useCurrentUser } from 'app2/views/shared-public'
// use these only if you need to pull the timezone from the site from the course record
// if no timezone is needed (such pure date pickers, vs. date/time pickers), then the regular date fields
import { CourseStatusTag, SiteOrCompanyDateField, SiteOrCompanyTimeField } from 'app2/views/shared-public'
import { AthleticsCourseKindField, CourseKindField, CourseDayField, CompanySitePicker, NonPartnerSitePicker, CourseDaysPicker, CourseFrameworkPicker, CoursePricesWithBreakdowns, END_DATE, END_TIME, formatCourseDays, parseCourseDays, SiteVendorPicker, START_DATE, START_TIME, TeacherPicker } from 'app2/views/shared'

import { CourseName } from '../CourseName';

import { CoursesQueryVars } from './CoursesQueryVars';
import { getPermissions } from './permissions';
import { courseFeeFields } from './SchoolFeeCombo';
import { TeamField } from './TeamField';

type CourseKey = any;

export const nonCancelledStatuses = Object.values(CourseStatus).filter(val => val !== 'CANCELLED' && val !== 'DELETED');

export interface CourseFieldProps {
  // specify a site if a) if you are using the vendor column, b) if all courses will be formatted in that sites timezone
  site?:string;

  // specify a course if this is fields for a course and only that
  course?:DeepPartial<Course>;

  queryVars?:CoursesQueryVars;
}

export function useCourseFields(props:CourseFieldProps) {
  const {site, course} = props;

  // note that this kind is not necessarilly for the course kind
  // it can be kinds for a table (why there can be more than one)
  // and this method is not called once per row...see permissions
  // for individual row enable/required
  const kind = course?.kind || props.queryVars?.kinds?.[0];
  const behavior = courseKindBehavior[kind];
  
  // anytime the data changes that causes the permissions to update
  // and we need a way of forcing the memoized fields to update
  const permissionsUpdateCounter = React.useRef(0);
  const homeroomStaff = useCurrentUser().user?.homeroom;

  return React.useMemo(() => {
    function getFields() {
      const homeroomFeeRoundingIncrement = {
        label: <BetaLabel>Homeroom fee round-up</BetaLabel>,
        infoTip: "Choose the cent increment (e.g., 5 cents for nickel) to which Homeroom's fees are rounded up. Setting the increment to zero will round the fee to the nearest cent.",
        disabled: false,
        assignIds: false,
        hidden: !homeroomStaff,
        onChange: onChangeRates,
        validators: validateRates,
        format: convertToInt,
        parse: convertToInt,
        min: 0,
        max: 100,
        ...NumberField
      }

      const regFees = behavior?.kinds.find(k => k == CourseKind.AthleticIndividualReg || k == CourseKind.AthleticReg)

      return {
        site: {
          name: 'site',
          label: 'School',
          display: { format:(item:{name:string}) => item?.name },
          edit: { component: <NonPartnerSitePicker />, placeholder: FieldPlaceholder.select },
          paste: (nameOrObj:string | Site, _:any, info:FieldInfo<Course, 'site'>) => CompanySitePicker.findSite(nameOrObj, {company: info.form.getValue([], 'company')?.id}),
          disabled: false
        },
        status: {
          name: 'status' as CourseKey,
          label: 'Status',
          display: { component: CourseStatusTag, small: true },
          disabled: true
        },
        kind: {
          name: 'kind' as CourseKey,
          label: 'Type',
          ...(regFees ? AthleticsCourseKindField : CourseKindField),
          none: false,
          disabled: false,
          onChange: onChangeKind,
          parse: (val:CourseKind) => Object.values(CourseKind).indexOf(val) == - 1 ? undefined : val
        },
        hasParentOptionCourses: {
          name: 'hasParentOptionCourses' as CourseKey,
          label: 'Option',
          format: (v:boolean) => v ? <OptionalCourseTag /> : '',
          disabled: true
        },
        courseFramework: {
          name: 'courseFramework' as CourseKey,
          label: 'Activity template',
          onChange: onChangeFramework,
          display: { format:(item:{name:string}) => item?.name },
          edit: { component: <CourseFrameworkPicker addPosition='top' additions='activity template' autoShowModal />, placeholder: FieldPlaceholder.select },
          paste: (nameOrObj:string | CourseFramework, _:any, info:FieldInfo<Course, 'courseFramework'>) => CourseFrameworkPicker.findFramework(nameOrObj, {course: info.form.values}),
          disabled: false,
          hidden: behavior.fields.framework === false
        },
        name: {
          name: 'name' as CourseKey,
          label: behavior?.terms?.name,
          display: CourseName,
          parse: onlyString,
          disabled: false
        },
        description: {
          name: 'description' as CourseKey,
          label: 'Description',
          parse: onlyString,
          disabled: false
        },
        courseCardImage: {
          name: 'courseCardImage' as CourseKey,
          label: 'Activity card image',
          disabled: false
        },
        courseImage: {
          name: 'courseImage' as CourseKey,
          label: 'Background image',
          disabled: false
        },
        courseTags: {
          name: 'courseTags' as CourseKey,
          label: 'Category',
          display: {format:(tags:CourseTag[]) => tags?.[0]?.name},
          edit: { component: <CourseTagsDropdown valueType='object' clearable />, format:(tags:CourseTag[]) => tags?.[0], parse: (tag:CourseTag) => [tag], placeholder: FieldPlaceholder.select},
          paste: (nameOrObj:string | CourseTag | string[] | CourseTag[]) => CourseTagsDropdown.findTag(nameOrObj),
          disabled: false
        },
        vendor: {
          name: 'vendor' as CourseKey,
          label: 'Provider name',
          onChange: onChangeProvider,
          display: { format:(item:{displayName:string}) => item?.displayName },
          edit: { component: <SiteVendorPicker site={site} />, placeholder:FieldPlaceholder.select},
          paste: (nameOrObj:string | Vendor) => SiteVendorPicker.findVendor(nameOrObj, {site}),
          disabled: false
        },
        vendorEmail: {
          name: 'vendor.email' as CourseKey,
          label: 'Provider email',
          disabled: false,
          readOnly: true
        },
        teacher: {
          name: 'teacher' as CourseKey,
          label: behavior?.terms?.teacher,
          display: { format:(item:Teacher) => item?.name },
          edit: { component: <TeacherPicker clearable />, placeholder:FieldPlaceholder.select },
          paste: (nameOrObj:string | Teacher, _:any, info:FieldInfo<Course, 'teacher'>) => TeacherPicker.findTeacher(nameOrObj, {course: info.form.values}),
          disabled: false            
        },
        'teacher.email': {
          name: 'teacher.email' as CourseKey,
          label: `${behavior?.terms?.teacher} email`,
          display: EmailLabelField,
          readOnly: true,
        },
        'teacher.phone': {
          name: 'teacher.phone' as CourseKey,
          label: `${behavior?.terms?.teacher} phone`,
          display: PhoneLabelField,
          readOnly: true,
        },
        room: {
          name: 'room' as CourseKey,
          label: 'Location',
          parse: onlyString,
          disabled: false
        },
        grades: {
          name: 'grades' as CourseKey,
          label: 'Grades',
          ...MultipleGradeField,
          edit: {
            ...MultipleGradeField.edit,
            hideNA: true
          },
          site,
          disabled: false,
          onChange: clearOther(['ageMin', 'ageMax']),
          hidden: behavior.fields.grades === false
        },
        ageMin: {
          ...AgeField,
          name: 'ageMin' as CourseKey,
          label: 'Minimum',
          disabled: false,
          onChange: clearOther(['grades']),
          hidden: behavior.fields.grades === false
        },
        ageMax: {
          ...AgeField,
          name: 'ageMax' as CourseKey,
          label: 'Maximum',
          disabled: false,
          onChange: clearOther(['grades']),
          hidden: behavior.fields.grades === false
        },
        courseDays: {
          name: 'courseDays' as CourseKey,
          label: 'Days and times',
          display: CourseDayField,
          edit: {component: CourseDaysPicker, placeholder: 'Enter day and time'},
          copy: formatCourseDays,
          paste: parseCourseDays,
          assignIds: false,
          onChange: onChangeCourseDays,
          disabled: false,
          hidden: behavior.fields.courseDays === false,
          validators: validateTimes
        },
        startTime: {
          ...TimeField,
          edit: {...TimeField, valueFormat: 'time'},
          id: 'startTime',
          name: 'courseDays.0.start' as CourseKey,
          label: 'Start time',
          disabled: false,
          hidden: behavior.fields.courseTimes === false,
          onChange: relatedEndTime('courseDays.0.finish'),
          validators: validateTimes
        },
        endTime: {
          ...TimeField,
          edit: {...TimeField, valueFormat: 'time'},
          id: 'endTime',
          name: 'courseDays.0.finish' as CourseKey,
          label: 'End time',
          disabled: false,
          hidden: behavior.fields.courseTimes === false
        },
        startDate: {
          ...DateField,
          name: 'startDate' as CourseKey,
          label: START_DATE,
          validators: validateSeasonDates,
          onChange: onChangeStartDate,
          disabled: false
        },
        endDate: {
          ...DateField,
          name: 'endDate' as CourseKey,
          label: END_DATE,
          validators: validateSeasonDates,
          onChange: onChangeEndDate,
          disabled: false
        },
        classesCount: {
          name: 'classesCount' as CourseKey,
          label: 'Classes',
          disabled: true
        },
        'enrollmentOpens': {
          date: {
            ...SiteOrCompanyDateField,
            name: 'enrollmentOpens' as CourseKey,
            label: START_DATE,
            onChange: relatedEndDate('enrollmentCloses'),
            required: true,
            validators: validateEnrollmentDates,
            disabled: false,
            hidden: behavior.fields.enrollmentPeriod === false
          },
          time: {
            ...SiteOrCompanyTimeField,
            name: 'enrollmentOpens' as CourseKey,
            label: START_TIME,
            onChange: relatedEndDate('enrollmentCloses'),
            required: true,
            validators: validateEnrollmentDates,
            disabled: false,
            hidden: behavior.fields.enrollmentPeriod === false
          }
        },
        'enrollmentCloses': {
          date: {
            ...SiteOrCompanyDateField,
            name: 'enrollmentCloses' as CourseKey,
            label: END_DATE,
            onChange: relatedEndDate('startDate', true),
            required: true,
            validators: validateEnrollmentDates,
            disabled: false,
            hidden: behavior.fields.enrollmentPeriod === false
          },
          time: {
            ...SiteOrCompanyTimeField,
            name: 'enrollmentCloses' as CourseKey,
            label: END_TIME,
            onChange: relatedEndDate('startDate', true),
            required: true,
            validators: validateEnrollmentDates,
            disabled: false,
            hidden: behavior.fields.enrollmentPeriod === false
          },
        },
        autoCheckoutStudents: {
          name: 'autoCheckoutStudents' as CourseKey,
          label: 'Auto check-out students at activity end',
          hidden: behavior.management.attendance !== true,
          infoTip: 'If a student is still checked in when the activity ends, they’ll be automatically checked out.',
          ...CheckboxField,
        },
        minCapacity: {
          name: 'minCapacity' as CourseKey,
          label: 'Min. capacity',
          ...NumberField,
          edit: { ...NumberField.edit,  min: 0, max: 10000 },
          disabled: false
        },
        maxCapacity: {
          name: 'maxCapacity' as CourseKey,
          label: 'Max. capacity',
          ...NumberField,
          edit: { ...NumberField.edit,  min: 0, max: 10000 },
          disabled: false
        },
        teamMaxCapacity: {
          name: 'teamMaxCapacity' as CourseKey,
          label: 'Team max. capacity',
          ...NumberField,
          edit: { ...NumberField.edit,  min: 0, max: 10000 },
          required: kind !== CourseKind.AthleticReg,
          disabled: kind !== CourseKind.AthleticReg,
          hidden: behavior?.fields?.teamMaxCapacity === false,
        },
        // this just exists for the onChange in the field defs
        rates: {
          name: 'rates' as CourseKey,
          onChange: onChangeRates,
          assignIds: false
        },
        ['rates.season.rate']: {
          name: 'rates.season.rate' as CourseKey,
          label: behavior?.terms?.price,
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.season?.basic === false,
          ...CurrencyField
        },
        ['rates.season.homeroomFeeRoundingIncrementCents']: {
          ...homeroomFeeRoundingIncrement,
          name: 'rates.season.homeroomFeeRoundingIncrementCents' as CourseKey,
          hidden: homeroomFeeRoundingIncrement.hidden || behavior?.fields?.pricing?.season?.basic === false,
        },
        ['rates.season.materialsRate']: {
          name: 'rates.season.materialsRate' as CourseKey,
          label: 'Materials fee',
          disabled: false,
          onChange: onChangeRates,
          validators: validateMaterialsRate,
          hidden: behavior?.fields?.pricing?.season?.basic === false,
          ...CurrencyField
        },
        ['rates.season.depositAmount']: {
          name: 'rates.season.depositAmount' as CourseKey,
          label: 'Deposit',
          disabled: false,
          onChange: onChangeRates,
          hidden: behavior?.fields?.pricing?.season?.installments === false,
          ...CurrencyField
        },
        ['rates.season.installmentDates']: {
          name: 'rates.season.installmentDates' as CourseKey,
          label: 'Installment dates',
          component: MultipleDateField,
          onChange: onChangeRates,
          disabled: false,
          hidden: behavior?.fields?.pricing?.season?.installments === false
        },
        ['rates.seasons']: {
          name: 'rates.seasons' as CourseKey,
          assignIds: false,
          onChange: onChangeRates,
          hidden: behavior?.fields?.pricing?.season?.days === false
        },
        // these two are not used directly by activities/activityinfo, because they are repeating, but used by ConfigurableSeasonRates and permissions/required code
        ['rates.seasons.days']: {
          name: 'days' as CourseKey,
          label: 'Days',
          placeholder: 'Select number of days',
          options: [{label:'1 day/week', value:1}, {label:'2 days/week', value:2}, {label:'3 days/week', value:3}, {label:'4 days/week', value:4}, {label:'5 days/week', value:5}],
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.season?.days === false,
          ...DropdownField
        },
        ['rates.seasons.rate']: {
          name: 'rate' as CourseKey,
          label: 'Price',
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.season?.days === false,
          ...CurrencyField
        },
        ['rates.seasons.homeroomFeeRoundingIncrementCents']: {
          ...homeroomFeeRoundingIncrement,
          name: 'homeroomFeeRoundingIncrementCents' as CourseKey,
          hidden: homeroomFeeRoundingIncrement.hidden || behavior?.fields?.pricing?.season?.days === false,
        },
        ['rates.dropIn.rate']: {
          name: 'rates.dropIn.rate' as CourseKey,
          label: 'Daily drop-in price',
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.dropIn === false,
          ...CurrencyField
        },
        ['rates.dropIn.homeroomFeeRoundingIncrementCents']: {
          ...homeroomFeeRoundingIncrement,
          name: 'rates.dropIn.homeroomFeeRoundingIncrementCents' as CourseKey,
          hidden: homeroomFeeRoundingIncrement.hidden || behavior?.fields?.pricing?.dropIn === false,
        },
        ['rates.recurring']: {
          name: 'rates.recurring' as CourseKey,
          assignIds: false,
          onChange: onChangeRates,
          hidden: behavior?.fields?.pricing?.recurring === false,
        },
        // these three are not used directly by activities/activityinfo, because they are repeating, but used by CourseRecurringRates and permissions/required code
        ['rates.recurring.days']: {
          name: 'days' as CourseKey,
          label: 'Days',
          placeholder: 'Select number of days',
          options: [{label:'1 day/week', value:1}, {label:'2 days/week', value:2}, {label:'3 days/week', value:3}, {label:'4 days/week', value:4}, {label:'5 days/week', value:5}],
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.recurring === false,
          ...DropdownField
        },
        ['rates.recurring.rate']: {
          name: 'rate' as CourseKey,
          label: 'Price',
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.recurring === false,
          ...CurrencyField
        },
        ['rates.recurring.homeroomFeeRoundingIncrementCents']: {
          ...homeroomFeeRoundingIncrement,
          name: 'homeroomFeeRoundingIncrementCents' as CourseKey,
          hidden: homeroomFeeRoundingIncrement.hidden || behavior?.fields?.pricing?.recurring === false
        },
        ['rates.recurring.unit']: {
          name: 'unit' as CourseKey,
          label: 'Period',
          options: [{label: 'per week', value:RecurringUnit.Week}, {label:'per month', value:RecurringUnit.Month}],
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.recurring === false,
          ...DropdownField
        },
        ['rates.usage.rate']: {
          name: 'rates.usage.0.rate' as CourseKey,
          label: 'Usage price',
          placeholder: 'eg. $10',
          infoTip: 'The base price you charge per unit (e.g. $10/hr)',
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.usage === false,
          ...CurrencyField
        },
        ['rates.usage.homeroomFeeRoundingIncrementCents']: {
          ...homeroomFeeRoundingIncrement,
          name: 'rates.usage.0.homeroomFeeRoundingIncrementCents' as CourseKey,
          hidden: homeroomFeeRoundingIncrement.hidden || behavior?.fields?.pricing?.usage === false,
        },
        ['rates.usage.unit']: {
          name: 'rates.usage.0.unit' as CourseKey,
          label: 'Usage type',
          options: [{label: 'daily', value:UsageUnit.Day}, {label:'hourly', value:UsageUnit.Hour}],
          placeholder: 'eg. hourly',
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.usage === false,
          ...DropdownField
        },
        ['rates.usage.roundingIncrement']: {
          name: 'rates.usage.0.roundingIncrement' as CourseKey,
          label: 'Usage billing increment (round-up)',
          options: [
            { label: '1 minute', value: 1 },
            { label: '5 minutes', value: 5 },
            { label: '15 minutes', value: 15 },
            { label: '30 minutes', value: 30 },
            { label: '1 hour', value: 60 }
          ],
          placeholder: 'eg. 30 minutes',
          infoTip: "The time unit you bill in (e.g. 15, 30, or 60 minutes). If your increment is set to 1 minute, no rounding will occur.",
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.usage === false,
          ...DropdownField,
          edit: {
            ...DropdownField.edit,
            clearable: true
          },
        },
        ['rates.usage.gracePeriod']: {
          name: 'rates.usage.0.gracePeriod' as CourseKey,
          label: 'Usage billing grace period',
          options: [
            { label: 'None', value: 0 },
            { label: '5 minutes', value: 5 },
            { label: '10 minutes', value: 10 },
            { label: '15 minutes', value: 15 },
            { label: '20 minutes', value: 20 },
            { label: '25 minutes', value: 25 },
            { label: '30 minutes', value: 30 }
          ],
          placeholder: 'eg., 5 minutes',
          infoTip: "A small window of time where you don't round up to the next billing increment.",
          disabled: false,
          assignIds: false,
          onChange: onChangeRates,
          validators: validateRates,
          hidden: behavior?.fields?.pricing?.usage === false,
          ...DropdownField,
          edit: {
            ...DropdownField.edit,
            clearable: true
          },
        },
        ['rates.usage.overlapHandling']: {
          name: 'rates.usage.0.overlapHandling' as CourseKey,
          label: 'Overlap policy',
          infoTip: 'Overlapping charges occur when other activities are scheduled at the same time. This setting lets you decide how to handle billing for students who are enrolled in overlapping activities.',
          options: [{label: 'Charge for overlap', value: OverlapHandling.ChargeOverlap}, {label: 'Deduct overlap', value: OverlapHandling.DeductOverlap}, {label: 'Start charge after overlap', value: OverlapHandling.DeductOverlapBefore}],
          disabled: false,
          ...DropdownField
        },
        noEnrichmentDays: {
          ...MultipleDateField,
          name: 'noEnrichmentDays' as CourseKey,
          label: 'No activity days',
          onChange: onChangenoEnrichmentDays,
          disabled: false
        },
        supplies: {
          name: 'supplies' as CourseKey,
          label: 'Supplies',
          display: {format: arrayToString},
          edit: {component: (props:DropdownProps) => <Dropdown {...props} options={props.value} />, multiple: true, additions: true, placeholder: true, enterToggleSelects: false},
          parse: onlyStringArray,
          disabled: false,
          hidden: behavior.fields.supplies === false
        },
        ...courseFeeFields,
        prices: {
          name: 'priceBreakdowns' as CourseKey,
          id: 'prices',
          display: CoursePricesWithBreakdowns,
          label: 'Family pays',
          readOnly: true,
          ...CurrencyField,
          disabled: false,
          hidden: behavior?.fields?.pricing?.none === true
        },
        hideable: {
          name: 'searchable' as CourseKey,
          label: 'Hide activity on registration page',
          format: (v: boolean) => !v,
          parse: (v: boolean) => !v,
          ...CheckboxField
        },
        homeTeam: {
          name: 'homeTeam' as CourseKey,
          label: 'Home team',
          ...TeamField
        },
        awayTeam: {
          name: 'awayTeam' as CourseKey,
          label: 'Away team',
          ...TeamField
        },
        homeScore: {
          name: 'homeScore' as CourseKey,
          label: 'Home score',
          ...NumberField
        },
        awayScore: {
          name: 'awayScore' as CourseKey,
          label: 'Away score',
          ...NumberField
        },
        dailyCapacity: {
          name: 'dailyCapacity' as CourseKey,
          label: <BetaLabel>Daily capacity</BetaLabel>,
          hidden: !(homeroomStaff && (!course || CourseUtils.usingConfigurableDayRates(course?.rates))),
          infoTip: 'When enabled, the max capacity is applied to each day individually. If all days are full, the activity will be added to the waitlist. If some days are full and others aren\'t, and the user picks days that are full, the activity will not be added to the cart. Enrollments with non-configurable pricing are counted towards every day\'s capacity. Drop-ins are not counted until a waitlist forms. NOTE: If Tuesday is full and Thursday is not, a student may not enroll with non-configurable pricing; they must choose Thursday to enroll.',
          ...CheckboxField
        },
      }
    }

    function convertToInt(value:any) {
      const num = Number(value);
      if (!Number.isInteger(num)) {
        return num * 100;
      }
      return num;
    }

    function onChangeKind(val:any, info:FieldInfo<Partial<Course>, 'kind'>, settingMultipleValues:boolean, existingValue:CourseKind) {
      // you can't make an activity a timeslot, you can only create them
      // and in general we don't want you to change activity kind (its really
      // only there for reg fees), so past draft status we dont allow this
      const course = info.form.values;

      if (existingValue && !courseKindBehavior[existingValue].changeableTo.includes(val)) {
        return false;
      }

      const behavior = courseKindBehavior[course.kind];
      info.form.setValue('teamMaxCapacity', behavior?.defaults?.teamMaxCapacity, {bypassDisabled: true});

      updateCoursePermissions(info.form);
    }

    function onChangeProvider(_vendor:Vendor, info:FieldInfo<Partial<Course>, 'vendor'>, settingMultipleValues:boolean) {
      const form = info.form;
    
      // ignore when setting multiple values because the set might contain the framework
      if (!settingMultipleValues) {
        // this will trigger a call to onChangeFramework and clear framework fields
        // if there was a prev framework (else the fields remain untouched)
    
        form.setValue('courseFramework', null, {bypassDisabled: true});
        form.setValue('teacher', null, {bypassDisabled: true})
      }
    
      updateCoursePermissions(info.form);
    }
    
    function onChangeFramework(framework:CourseFramework, info:FieldInfo<Course, 'courseFramework'>, settingMultipleValues:boolean) {
      // ignore when setting multiple values because the set might contain framework fields (name, etc)
      if (settingMultipleValues) {
        return;
      }
    
      const form = info.form;
    
      setCourseValue(form, 'name', framework?.name || '');
      setCourseValue(form, 'description', framework?.description || '');
      setCourseValue(form, 'supplies', framework?.supplies);
      setCourseValue(form, 'courseTags', framework?.courseTags);
      setCourseValue(form, 'courseCardImage', framework?.courseCardImage || '');
      setCourseValue(form, 'courseImage', framework?.courseImage || '');
    }
    
    function onChangeCourseDays(courseDays:CourseDay[], info:FieldInfo<Course, 'courseDays'>, settingMultipleValues:boolean) {
      const sessionDays = updateClassCount(info.form, info.record, true);
    
      if (!sessionDays.length) {
        return;
      }
    
      if (behavior.sessionType != 'days') {
        return;
      }

      setCourseValue(info.form, 'startDate', iso8601Date(first(sessionDays)));
      setCourseValue(info.form, 'endDate', iso8601Date(last(sessionDays)));
    }

    function onChangeStartDate(startDate:DateInput, info:FieldInfo<Course, 'startDate'>, settingMultipleValues:boolean) {
      relatedEndDate('endDate')(startDate, info);
      updateClassCount(info.form, info.record);
      onChangeStartOrEndDate(info);
    }
    
    function onChangeEndDate(endDate:DateInput, info:FieldInfo<Course, 'startDate'>, settingMultipleValues:boolean) {
      updateClassCount(info.form, info.record);
      onChangeStartOrEndDate(info);
    }

    function onChangeStartOrEndDate(info:FieldInfo<Course>) {
      if (behavior.sessionType == 'none') {
        const day = moment(info.form.values.startDate).format('dddd');
        setCourseValue(info.form, 'courseDays.0.days' as keyof Course, [day]);
      }
    }
    
    function onChangenoEnrichmentDays(noEnrichmentDays:DateInput[], info:FieldInfo<Course, 'noEnrichmentDays'>, settingMultipleValues:boolean) {
      updateClassCount(info.form, info.record);
    }
    
    function onChangeRates(_:any, info:FieldInfo<Partial<Course>>, _2:boolean) {
      updateCoursePermissions(info.form);
    }

    function validateRates(_:any, info: FieldInfo<Partial<Course>>) {
      return CourseUtils.usingIncompatibleRates(info.form.values.rates)
        ? 'You can not use usage pricing with any other pricing type'
        : false
    }

    function validateMaterialsRate(value:any, info: FieldInfo<Partial<Course>>) {
      return value && CourseUtils.getRateOrPriceType(info.form.values.rates) == RateType.advanced
        ? 'You can not use materials fees with advanced pricing'
        : validateRates(value, info)
    }
    
    function updateClassCount(form:FormModel<Course>, course:Course, updateStartEnd?:boolean) {
      const startDate = updateStartEnd && course.season ? iso8601Date(course.season.coursesBegin, course.site.timezone) : undefined;
      const endDate = updateStartEnd && course.season ? iso8601Date(course.season.coursesFinish, course.site.timezone) : undefined;

      const sessionDays = CourseUtils.getSessionDays(course, startDate, endDate);
      setCourseValue(form, 'classesCount', sessionDays.length, false);
    
      return sessionDays;
    }
        
    function setCourseValue(form:FormModel<Course>, field:keyof Course, value:any, dirty:boolean = true) {
      form.setValue(field, value, {bypassDisabled: true, dirty});
    }
    
    function validateSeasonDates(value:DateInput, info:FieldInfo<Course, 'startDate' | 'endDate'>) {
      return validateDateRange('startDate', 'endDate', info, 'Season');
    }
    
    function validateEnrollmentDates(value:DateInput, info:FieldInfo<Course, 'enrollmentOpens' | 'enrollmentCloses'>) {
      return validateDateRange('enrollmentOpens', 'enrollmentCloses', info, 'Enrollment');
    }

    function validateTimes(value:DateInput, info:FieldInfo<Course, any>) {
      return validateTimeRange<any>('courseDays.0.start', 'courseDays.0.finish', info, 'Start time', false);
    }

    function onFormReset(form:FormModel<Course>) {
      updateClassCount(form, form.values);
      updateCoursePermissions(form as FormModel<Partial<Course>>);
    }
    
    // why we have to update permissions - its because for the initial permissions
    // we dont have a form yet
    function updateCourseFieldPermissions(course:DeepPartial<Course>, fields:any) {
      const permissions = getPermissions(course);
    
      for (const prop in permissions) {
        const name = prop as keyof Course;
        const field = get(fields, name);
    
        if (field) {
          field.required = permissions[name].required;
          field.disabled = permissions[name].disabled;
        }
      }
    }
    
    function updateCoursePermissions(form:FormModel<Partial<Course>>) {
      form.validateRequired = form.values.status != CourseStatus.Draft;
    
      const permissions = getPermissions(form.values);
      let madeChange = false;
    
      for (const prop in permissions) {
        const name = prop as keyof Course;
    
        // the permissions maps covers all fields, but the table and
        // form don't show all the fields, so first verify if the field is there
        const field = form.getField([], name);

        // also if we make a change, it will cause completely new columns to render
        // which will cause the table to clear errors and often times we this code
        // path is called when there's no change in permissions, including when saving 
        // and there are errors to show
        if (field && (field.required != permissions[name].required || field.disabled != permissions[name].disabled)) {
          madeChange = true;
          form.updateField([], name, {
            required: permissions[name].required,
            disabled: permissions[name].disabled
          });
        }
      }

      // only force an update if just using this on activity page and not the table
      // because if we force it on the table it will reset the columns which will cause
      // validations to run which will cause existing errors to clear and since things like
      // required and disabled apply to each row, not columns, the permissions will
      // constantly be changing causing columns to change which we don't want.
      if (madeChange && course) {
        ++permissionsUpdateCounter.current;
      }
    }
    
    const fields = getFields();

    if (course) {
      updateCourseFieldPermissions(course, fields);
    }

    return {
      fields,
      onChangeProvider,
      onFormReset
    }
  }, [site, course, behavior, permissionsUpdateCounter.current]);
}

export const courseRegularTag = {
  ...smallTagProps, bg: palette.primary.pink.hex, color: 'white', whiteSpace: 'nowrap' as any
}

export const courseVariantTag = {
  ...smallTagProps, bg: 'brand', color: 'white', whiteSpace: 'nowrap' as any
}
