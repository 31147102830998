import { omit } from 'lodash-es';

import { gqlTypes } from 'app2/api';
import { colId, colName, DataTableColumn } from 'app2/components';

import { MIN_COL_WIDTH } from '../datatable';

import { allSchemaFields } from './schemaFields';
import { EntityType } from './EntityType';
import { FieldWithOverrides } from './FieldWithOverrides';
import { fieldRegistry } from './fieldRegistry';

// getFields will take a list of field/column names and return
// and array of expanded field definitions back. in addition
// to field names, you can pass in field attribute overrides.
// 
// the expansion from field name/overrides to full field merges three
// possible layers of field definitions:
//  - schema fields - for each entity type, we auto-generate basic fields from the gql schema.
//   such as a date field will use a date picker
//
//  - base fields - for each entity type, we define the core field attributes such
//  as the default label for the fields
//
//  - view fields - the specific view can define a list of column names, in which case
//  the schema + base field will be merged for each column name, or it can also
//  specify field attributes to override schema + base

export function getFields(typeName:EntityType, props:any, fieldNames?:string[], viewFields?:Record<EntityType, FieldWithOverrides>) {
  const fieldsOrFn = fieldRegistry[typeName];
  const baseFieldsList = (typeof fieldsOrFn == 'function' ? fieldsOrFn?.(props) : fieldsOrFn) || [];
  const baseFields = Object.fromEntries(baseFieldsList.map(f => [colId(f), f]));
  const schemaFields = allSchemaFields[typeName];
  const schemaType = gqlTypes[typeName];
  const result:DataTableColumn[] = [];

  fieldNames ||= viewFields ? Object.keys(viewFields) : baseFieldsList.map(c => colId(c));

  fieldNames.forEach(path => {
    // some names have array notation in them for when the prop is an array type - skip these
    const parts = path.split('.').filter(part => !Number.isFinite(Number(part)));
    const id = parts[0];
    const baseFieldOverrides = baseFields?.[id]?.fields;
    const baseField = baseFieldOverrides ? omit(baseFields?.[id], 'fields') : baseFields?.[id];

    const name = colName(baseField) || id;
    const hasId = id != name;

    const schemaField = schemaFields[id] || schemaFields[name];
    const schemaTypeProp = schemaType[id] || schemaType[name];
    const viewField = viewFields?.[id] || viewFields?.[name];

    const consolidated = {...schemaField, ...baseField, ...viewField};

    let field:DataTableColumn = null;

    if (parts.length > 1) {
      const remainingParts = parts.slice(1);
      const childNames = [remainingParts.join('.')];
      const childField = getFields(schemaTypeProp.type, props, childNames, baseFieldOverrides)[0]

      field = {...consolidated, ...childField, name: name + '.' + (childField.name as string)} as DataTableColumn;

      if (childField.id) {
        field.id = id + '.' + childField.id;
      }
    }
    else {
      field = consolidated as DataTableColumn;

      if (!field.name) {
        field.name = name;
      }
  
      if (hasId) {
        field.id = path;
      }  
    }

    if (props.timezone && schemaTypeProp.format == 'local') {
      field.timezone = props.timezone;
    }

    if (!field.width) {
      field.width = MIN_COL_WIDTH;
    }
  
    result.push(field);
  });

  return result;
}
