import * as React from 'react';
import moment from 'moment';

import { ChangePricingPeriod, compact, PriceConfigKind } from 'app2/api';
import { Collapsible, VBox, BoxProps, formatCurrency, formatShortDate, useFormInfo, Tabs, Text } from 'app2/components';
import { CartCourse, coursePriceUnitLabels, EnrollmentConfig } from 'app2/views/shared-public'

import { CourseSelections } from '../../../generated';
import { FeeItem, FeeTotal } from '../../../../currency';
import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

import { AddEditEnrollmentBreakdown, useEnrollmentBreakdown } from './useEnrollmentBreakdown';
import { formatPriceConfig, formatDiscountForPriceConfig } from './formatPriceConfig'
import { EnrollmentDiscountForm } from './EnrollmentDiscountForm';
import { FeeBreakdown } from 'app/app2/views/shared/course/breakdowns';

export interface EnrollmentBreakdownProps extends BoxProps {
  // this is the course that is being displayed, generally it 
  // should be the same as the one being added to, except in the
  // case of lessons, the parent will be the lesson and course
  // will be the time slot
  parentCourse:CourseSelections;
  // this is the course that the enrollment is being added to
  course:CartCourse;
  config?:EnrollmentConfig;
  invite?:boolean;
  changingEnrollment?:DistinctEnrollmentsSelections;
  effective?:ChangePricingPeriod;
  student?:string;
  vendorFeeLabel?:string;
  enrollments?:DistinctEnrollmentsSelections[];
  summary?:boolean;
}

export function EnrollmentBreakdown(props:EnrollmentBreakdownProps) {
  const { course, config, invite, changingEnrollment, effective, parentCourse, vendorFeeLabel, student, enrollments, summary, ...remaining } = props;

  const formInfo = useFormInfo<EnrollmentDiscountForm>();
  const form = formInfo.form;

  const breakdown = useEnrollmentBreakdown({course: course.id, discount: form.values.discount, format: form.values.format, rate: form.values.rate, config, changingEnrollment, effective, student});

  function render() {
    return <VBox width="100%" {...remaining}>
      {renderAmounts()}
    </VBox>
  }

  function renderAmounts() {
    return <VBox gap='$12'>
      {summary && renderSummary()}
      {renderDetails()}
    </VBox>
  }

  function renderSummary() {
    return <VBox>
      <VBox width="100%" borderRadius="standard" bg="backgroundMediumGrey" p="$16" vItemSpace="$16" flex={1}>
        <FeeItem label="Regular price" amount={formatPriceConfig(course, breakdown?.standard?.priceConfig, breakdown?.standard?.listPriceBeforeDiscount)} />
        {breakdown?.standard?.discounts?.map((du, i) => (
          <FeeItem key={i} label={`${(du.isStudent || !du.oneOff) ? du.code : 'Additional'} discount`} amount={formatDiscountForPriceConfig(course?.prices, breakdown?.standard?.priceConfig, du.amount)} />
        ))}
        {renderTotal()}
      </VBox>
      {enrollments?.length > 1 && changingEnrollment && <Text color="disabled" italic fontSize="12px" mt="$4">{`Preview shown for ${changingEnrollment.student.name}`}</Text>}
    </VBox>
  }

  function renderDetails() {
    const firstAndNextDiff = (breakdown?.next as any)?.homeroomCharges != breakdown?.first?.homeroomCharges;
    const first = (breakdown?.first && ('amount' in breakdown?.first) && (breakdown?.first as any).amount) ? {label: formatShortDate((breakdown.first as any).date), content: <FeeBreakdown {...breakdown?.first} vendorFeeLabel={props.vendorFeeLabel} />} : undefined;
    const next = (breakdown?.next && ('amount' in breakdown?.next) && (breakdown?.next as any).amount && firstAndNextDiff) ? {label: formatShortDate((breakdown.next as any).date), content: <FeeBreakdown {...breakdown?.next} vendorFeeLabel={props.vendorFeeLabel} />} : undefined;
    const breakdowns = compact([first, next]);

    const standard = (breakdown?.standard && ('amount' in breakdown?.standard) && (breakdown?.standard as any).amount) ? {label: '', content: <FeeBreakdown {...breakdown?.standard} vendorFeeLabel={props.vendorFeeLabel} />} : undefined;

    if (!breakdowns.length && standard) {
      breakdowns.push(standard);
    }

    if (!breakdowns.length) {
      return <Collapsible type="box" label="See charge details" disabled>
        <Text text='body'>Please enter an amount</Text>
      </Collapsible>
    }

    return <Collapsible type="box" label="See charge details">
      <Tabs tabs={breakdowns} mode='hide-single-tab' />
    </Collapsible>
  }

  function renderTotal() {
    return ![PriceConfigKind.Recurring].includes(config?.kind) && !changingEnrollment
      ? <FeeTotal label="Family's price" amount={formatPriceConfig(course, breakdown.standard?.priceConfig, breakdown.standard?.listPrice)} />
      : renderRecurringOrConfigurableSeasonTotal();
  }

  function renderRecurringOrConfigurableSeasonTotal() {
    return config?.kind === PriceConfigKind.Recurring
      ? renderRecurringTotal()
      : renderConfigurableSeasonTotal();
  }

  function renderConfigurableSeasonTotal() {
    return (
      <>
        {breakdown?.first?.refund ? <FeeItem label={`Refunded today`} amount={-breakdown?.first?.refund} /> : null}
        <FeeItem label="Family's price" amount={breakdown?.first?.listPrice} />
      </>
    );
  }

  function renderRecurringTotal() {
    const {first, next} = getRecurringPricingFromBreakdown(breakdown, invite);

    return <>
      {first && breakdown?.first?.refund ? <FeeItem label={`Refunded today`} amount={-breakdown?.first?.refund} /> : ''}
      {first && <FeeItem label={`Charged ${first.date}${first?.prorated ? ' (prorated)' : ''}`} amount={first.listPrice} />}
      {next?.date && <FeeTotal label={`Next charge (${next.date})${next?.prorated ? ' (prorated)' : ''}`} amount={`${next.listPrice}/${coursePriceUnitLabels[(config.recurring).unit]}`} />}
    </>
  }

  return render();
}

export function getRecurringPricingFromBreakdown(breakdown:AddEditEnrollmentBreakdown, invite?:boolean) {
  const dueToday = moment(breakdown?.first?.date).isSame(moment(), 'day');
  const proratedChargeDate = !dueToday
    ? `on ${formatShortDate(breakdown?.first?.date)}`
    : invite
      ? 'at registration'
      : 'today';
  const first = breakdown?.first;
  const next = breakdown?.next;

  return {
    first: first && {
      date: proratedChargeDate,
      listPrice: formatCurrency(first.listPrice),
      prorated: first.prorated,
      amount: formatCurrency(breakdown?.first?.listPrice),
    },
    next: next && {
      date: formatShortDate(next.date),
      listPrice: formatCurrency(next.listPrice),
      prorated: next.prorated
    },
    date:breakdown?.ongoing?.date ? formatShortDate(breakdown?.ongoing?.date) : undefined,
    amount:formatCurrency(breakdown?.standard?.listPrice)
  }
}

EnrollmentBreakdown.defaultProps = {
  summary: true
}