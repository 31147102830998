import * as React from 'react';

import { formatAge, Student, StudentUtils } from 'app2/api';
import { Body, BooleanRadioField, DateField, HBox, EmailLabelField, FieldInfo, InputField, Link, PhoneLabelField } from 'app2/components';
import { GradeField } from 'app2/views/shared-public';
import { StudentModal } from 'app2/views/shared';

import { MIN_COL_WIDTH } from '../datatable';

import { StudentNameField } from '../roster/StudentNameField';

interface Props {
  site:string;
}

export function studentFields<T extends Student = any>(props:Props) {
  return [{
    label: "ID",
    name: 'id' as keyof T,
  }, {
    label: "First name",
    name: 'firstName' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='first' />,
  }, {
    label: "Last name",
    name: 'lastName' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='last' />,
  }, {
    label: "Name",
    name: 'name' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='full' />,
    width: 200
  }, {
    label: "Name",
    name: 'nameWithNickname' as keyof T,
    required: true,
    edit: InputField,
    display: <StudentNameField studentModal={StudentModal} nameKind='full' />,
    width: 200
  }, {
    // note that the student's grade will not display customized
    // grade labels if you do not also download the classroom siteId
    label: 'Grade',
    name: 'grade' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    component: GradeField,
    site: props.site
  }, {
    label: 'Age',
    name: 'age' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true,
    format: formatAge,
  }, {
    ...DateField,
    label: 'Date of birth',
    name: 'dob' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true
  }, {
    label: 'Enrollment count',
    name: 'enrollmentCount' as keyof T,
    width: MIN_COL_WIDTH,
    readOnly: true
  }, {
    label: 'Classroom',
    name: 'classroom' as keyof T,
    fields: {
      displayName: {
        width: MIN_COL_WIDTH,
        readOnly: true,
      }
    }
  }, {
    name: 'medical',
    fields: {
      allergies: {
        label: 'Allergies',
        width: 250,
        readOnly: true,
        format: (allergies:Student['medical']['allergies']) => StudentUtils.getFormattedAllergies(allergies),
      },
      epiPen: {
        label: 'EpiPen',
        width: 250,
        readOnly: true,
        component: BooleanRadioField,
      },
      medications: {
        label: 'Medications',
        width: 250,
        readOnly: true,
        format: (meds:Student['medical']['medications']) => StudentUtils.getFormattedMedications(meds),
      },
      conditions: {
        label: 'Conditions',
        width: 250,
        readOnly: true,
      }
    }
  }, {
    label: 'Comments',
    name: 'notes',
    width: 250,
    readOnly: true
  }, {
    label: 'Health & comments',
    name: 'formattedMedicalAndNotes',
    width: 250,
    readOnly: true
  }, {
    name: 'parents' as keyof T,
    fields: {
      name: {
        label: 'Family name',
        width: MIN_COL_WIDTH,
        readOnly: true
      },
      phone: {
        label: 'Family phone',
        width: 150,
        component: PhoneLabelField, 
        readOnly: true
      },
      email: {
        label: 'Family email', 
        name: 'email' as keyof T, 
        width: MIN_COL_WIDTH, 
        component: EmailLabelField, 
        readOnly: true
      }
    }
  }, {
    label: 'Contacts',
    name: 'formattedContacts',
    width: 300,
    readOnly: true
  }, {
    label: 'Emergency contact',
    name: 'emergencyContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }, {
    label: 'Authorized pickup contacts',
    name: 'authorizedPickupContacts',
    width: 300,
    format: formatContacts,
    copy: copyContactsCell,
    readOnly: true
  }]
}

type StudentContact = Student['emergencyContacts'][0] | Student['authorizedPickupContacts'][0];

export function formatContacts(contacts:StudentContact[]) {
  return contacts?.map((contact, index) => {
    return <HBox key={index} maxLines={1}>
      <span>
        {contact.name && <Body mr="$8">{contact.name}</Body>}
        {contact.phone && <Link mr="$8" text="body" to={`tel:${contact.phone}`}>{contact.phone}</Link>}
        {contact.email && <Link text="body" to={`mailto:${contact.email}`}>{contact.email}</Link>}
      </span>
    </HBox>
  })
}

export function copyContactsCell(contacts:StudentContact[]) {
  if (!contacts) {
    return '';
  }

  return contacts.map(contact => contact.name + ';' + (contact.phone || '') + ';' + (contact.email || '')).join('\n')
}
