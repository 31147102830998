import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AcceptTosMutationVariables = Types.Exact<{ [key: string]: never }>;

export type AcceptTosMutation = { userAcceptTos?: { success?: boolean | null | undefined } | null | undefined };

export const AcceptTosDocument = gql`
  mutation AcceptTOS {
    userAcceptTos(accepted: true) {
      success
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type AcceptTosOptions = ExecMutationOptions<AcceptTosMutationVariables>;

export function acceptTos(options: AcceptTosOptions) {
  return executeMutation<AcceptTosMutation, AcceptTosMutationVariables>(AcceptTosDocument, options);
}
