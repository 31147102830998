import * as React from 'react';
import pluralize from 'pluralize';

import { EnrollmentInput, EnrollmentStatusEnum, EnrollmentUtils } from 'app2/api';
import { HBox, VBox, NotificationManager, Body, Button } from 'app2/components';

import { addCourseToCart, AddCourseToCartMutation, removeCourseFromCart as removeEnrollmentFromCart } from './generated';

export async function addToCart(enrollments:EnrollmentInput[]) {
  const [success, result] = await addCourseToCart({ variables: {enrollments, trackingParameters: getTrackingParameters() } });

  if (!success) {
    return false;
  }
  
  const selections = result.data.cartAddEnrollments;
  displayCourseAddToCartNotification(selections);

  return true;
}

function getTrackingParameters() {
  const params = new URLSearchParams(window.location.search);

  params.delete('hr_sharer');
  
  // remove embed params
  params.delete('back');
  params.delete('embed');
  params.delete('resize');
  params.delete('support');
  params.delete('gtm');

  const tracking = params.toString();

  return tracking?.length ? tracking : undefined;
}

function displayCourseAddToCartNotification(addToCartSelections:AddCourseToCartMutation['cartAddEnrollments']) {
  // This at most will show two notifications no matter how many enrollments are added
  // One for success and one for wiatlist. It will group successes and waitlists together in the notifications.
  // If there's more than one waitlist, or if the waitlist is an option, then it removes the "remove from waitlist" button.

  // do the waitlist one first, so its shown second in the ui
  const waitlisted = addToCartSelections.enrollments.filter(e => EnrollmentUtils.scheduled(e) && e.status != EnrollmentStatusEnum.Unpaid);

  if (waitlisted.length) {
    displayCourseWaitlistedNotification(addToCartSelections, waitlisted);
  }

  const acquired = addToCartSelections.enrollments.filter(e => EnrollmentUtils.scheduled(e) && e.status == EnrollmentStatusEnum.Unpaid);
  
  if (acquired.length > 0) {
    displayCourseSpotAcquiredNotification(addToCartSelections, acquired);
  }
}

function displayCourseSpotAcquiredNotification(addToCartSelections:AddCourseToCartMutation['cartAddEnrollments'], acquired:AddCourseToCartMutation['cartAddEnrollments']['enrollments']) {
  NotificationManager.add({ type: 'success', message: <>
    {pluralize('Activity', acquired.length)} added to your cart.
      {addToCartSelections.expiryRefreshed && (
        <>
          <br />
          <br />
          Your cart will expire in {addToCartSelections.expiresInMinutes} minutes.
        </>
      )}
    </>
  });
}

function displayCourseWaitlistedNotification(addToCartSelections:AddCourseToCartMutation['cartAddEnrollments'], waitlisted:AddCourseToCartMutation['cartAddEnrollments']['enrollments']) {
  let handle: React.Key;
  
  function removeNotification() {
    NotificationManager.remove(handle);
  }

  const waitlistMessage = waitlisted.length > 1 ? 'Several activities are currently full. Proceed to checkout to join the waitlist.' : 'This activity is currently full. Proceed to checkout to join the waitlist.';

  const message = (
    <VBox>
      <Body>
        {waitlistMessage}
        {addToCartSelections.expiryRefreshed && (
          <>
            <br />
            <br />
            Your cart will expire in {addToCartSelections.expiresInMinutes} minutes.
          </>
        )}
      </Body>
      {waitlisted.length == 1 && !waitlisted[0].optionParent?.id
        ? <HBox hAlign="justify" mt="$20" vAlign="center">
          <Button
            kind="tertiary"
            onClick={() => {
              removeEnrollmentFromCart({variables: { id: addToCartSelections.enrollments[0].id}});
              removeNotification();
            }}
          >
            Remove from cart
          </Button>
          <Button onClick={removeNotification}>OK</Button>
        </HBox>
        : null}
    </VBox>
  );
  handle = NotificationManager.add({ type: 'warning', message });
}
