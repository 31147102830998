import * as Types from '../../../../../../api/graphql';

import gql from 'graphql-tag';
import { StudentMedicalFieldsFragmentDoc } from '../../../../student/tabs/gql/generated/studentMedicalFields.generated';
export type EnrollmentFieldsFragment = {
  id: string;
  createdAt: string;
  discountAmount: number;
  discountCodes: Array<string>;
  balance: number;
  pickup?: string | null | undefined;
  dismissal?: string | null | undefined;
  groups?: Array<string> | null | undefined;
  parent?: { id: string; name?: string | null | undefined; email: string; phone?: string | null | undefined } | null | undefined;
  family?: { id: string; parents?: Array<{ id: string; name?: string | null | undefined; email: string }> | null | undefined } | null | undefined;
  enrollmentPriceTierUse?: { cartPriceTierUse: { id: string; name: string } } | null | undefined;
  student?:
    | {
        id: string;
        firstName: string;
        lastName: string;
        firstNameWithNickname: string;
        name: string;
        grade: string;
        age?: number | null | undefined;
        dob?: string | null | undefined;
        nickname?: string | null | undefined;
        notes?: string | null | undefined;
        classroom?: { siteId: string; displayName: string } | null | undefined;
        medical?:
          | {
              epiPen?: boolean | null | undefined;
              conditions?: string | null | undefined;
              allergies?: Array<{ allergicTo: string; notes?: string | null | undefined }> | null | undefined;
              medications?: Array<{ medication: string; dose: string; notes?: string | null | undefined }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const EnrollmentFieldsFragmentDoc = gql`
  fragment enrollmentFields on RosterItem {
    id
    createdAt
    discountAmount
    discountCodes
    balance
    pickup
    dismissal
    parent {
      id
      name
      email
      phone
    }
    family {
      id
      parents {
        id
        name
        email
      }
    }
    enrollmentPriceTierUse {
      cartPriceTierUse {
        id
        name
      }
    }
    student {
      id
      firstName
      lastName
      firstNameWithNickname
      name
      grade
      age
      dob
      ...studentMedicalFields
      nickname
      classroom {
        siteId
        displayName
      }
    }
    groups
  }
  ${StudentMedicalFieldsFragmentDoc}
`;
