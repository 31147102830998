export function toSentence(arr:string[]) {
  if (arr.length <= 1) {
    return arr.join('')
  }

  if (arr.length === 2) {
    return arr.join(' and ')
  }

  return arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1)
}
