import * as React from 'react'

import { Modal, PopupHandleWithPromise, Text, Link, VBox } from 'app2/components';

import { closeErrorsModal } from './showErrors';

let modalHandle:PopupHandleWithPromise;

export async function showForcedRefreshModal(message?:string) {
  closeErrorsModal();

  if (modalHandle) {
    return;
  }

  modalHandle = Modal.show(<Modal ok={null} cancel={null} cancelable={false}> 
    <VBox gap='$12'>
      <Text text='subtitle1'>{message || 'An error has occured'}</Text>
      <Link to={window.location.href} onClick={() => window.location.reload()}>Refresh the page</Link>
      <Text text='body'>If you continue to experience this error, please <Link text='body' email='hello@homeroom.com'>contact us</Link>.</Text>
    </VBox>
  </Modal>);

  await modalHandle;
  modalHandle = null;
}

