import * as React from 'react';

import { Order, PaymentStatus } from 'app2/api';
import { Body, Button, formatCurrency, Modal, NotificationManager, NotificationType, VBox } from 'app2/components';

import { parentProcessPayment } from './generated';

type ProcessNowOrder = Pick<Order, 'id' | 'canProcessNow' | 'total'>;

interface Props {
  order: ProcessNowOrder;
}

export function PayNowAction(props: Props) {
  const order = props.order;

  function render() {
    if (!order?.canProcessNow) {
      return <></>;
    }

    return <VBox hAlign="center" vAlign="center"><Button small onClick={renderModal}>Pay now</Button></VBox>
  }

  function renderModal() {
    return <Modal title="Pay invoice" ok="Confirm payment" onOk={onClickPayNow}>
      <Body>You're about to pay {formatCurrency(order.total)} using the payment method on file.</Body>
      <Body>Would you like to proceed?</Body>
    </Modal>
  }

  async function onClickPayNow() {
    const [success, result] = await parentProcessPayment({ variables: { id: order.id } });

    if (success) {
      let type: NotificationType;
      
      switch (result.data.parentProcessPayment.status) {
        case PaymentStatus.Paid:
          type = 'success';
          break;
        case PaymentStatus.RetriableError:
        case PaymentStatus.Failed:
          type = 'error';
          break;
        default:
          type = 'warning';
          break;
      };
      NotificationManager.add({ type, message: result.data.parentProcessPayment.message });
    }

    return success;
  }

  return render();
}

PayNowAction.fieldProps = {
  valueProperty: 'order'
};
