import * as React from 'react';

import { PaymentStatus } from 'app2/api';
import { ActionButton, DataTable, Modal, NotificationManager } from 'app2/components';

import { CourseSelections } from '../../../generated';
import { DistinctEnrollmentsSelections } from '../../enrolled/gql';

import { RefundModal } from './RefundModal';

interface Props {
  course: CourseSelections;
  table: DataTable<DistinctEnrollmentsSelections>;
}

export function VoidButton(props:Props) {
  const {course, table} = props;

  function render() {
    if (!course?.behavior?.enrollmentsRefund) {
      return <></>;
    }
    
    return <ActionButton icon="Refund" autoLoader={false} tooltip="Void a failed or invoiced bill and optionally remove" onClick={onClick}>
      Void
    </ActionButton>
  }

  function onClick() {
    const statuses = [PaymentStatus.RetriableError, PaymentStatus.Invoiced];
    const hasRefundable = table.selections.selectedItems.some(e => !statuses.includes(e.paymentStatus));

    if (hasRefundable) {
      NotificationManager.error('You can only void invoiced or failed purchases.');
      return;
    }

    return Modal.show(<RefundModal course={course} enrollments={table.selections.selectedItems} void={true} />);
  }
  
  return render();
}
