import * as React from 'react'
import { pick, debounce } from 'lodash-es';

import { InputField, Panel, Part, Section, FieldInfo, FormModel, validateZipcode, PhoneField, CheckboxField } from 'app2/components'
import { removePathSegment, SiteSelections } from 'app2/views/shared';
import { Beta } from 'app2/views/shared-public';

import { useCurrentSite } from '../shared';
import { organizerSiteUpdateGeneralInfo, OrganizerSiteUpdateGeneralInfoMutationVariables } from './gql'
import { organizerZipcode } from './gql'

export function SchoolInfo() {
  const { site } = useCurrentSite();

  function render() {
    return <Panel icon='FileText' title='School information' initialValues={site} onOk={onOk} type='toggle'>
      <Section label='School name' name='name' />
      <Section label='School address' name='streetAddress' required component={InputField} />
      <Section continued>
        <Part name='city' mode='display' format={renderAddress} />
        <Part label='Zip code' name='zipcode' required validators={validateZipcode} onChange={handleZipCodeChange} component={InputField} mode='edit' />
      </Section>
      <Section continued name='timezoneDisplay' mode='display' />
      <Section label='Phone number' name='phone' component={PhoneField} />
      <Section label='Hide school from search' name='searchable' tooltip='When checked, the school will be hidden from search results and can only be found using its direct link.' format={(val:boolean) => !val} parse={(val:boolean) => !val} component={CheckboxField} />
      <Beta><Section label='Use pay by check' name='usingAch' component={CheckboxField} /></Beta>
      <Beta><Section label='Use Alipay' name='usingAlipay' component={CheckboxField} /></Beta>
      <Beta><Section label='Restrict account registration' name='restrictedAccountRegistration' component={CheckboxField} /></Beta>
    </Panel>
  }

  function renderAddress(_: any, _2:any, info:FieldInfo<SiteSelections>) {
    const site = info.record;

    return `${site.city}, ${site.state} ${site.zipcode}`;
  }

  async function onOk(form:FormModel) {
    const contactInfo = pick(form.values,['streetAddress','phone','zipcode'])
    const variables: OrganizerSiteUpdateGeneralInfoMutationVariables = { siteId: site.id, contactInfo, searchable: form.values.searchable, usingAch: form.values.usingAch, usingAlipay: form.values.usingAlipay, restrictedAccountRegistration: form.values.restrictedAccountRegistration }
    const [success] = await organizerSiteUpdateGeneralInfo({variables, successMsg: "Site information updated successfully.", error:{handler: form, transform: removePathSegment('contactInfo')}})
    return success;
  }

  const handleZipCodeChange = debounce (async (value:string, info: FieldInfo<SiteSelections>) => {
    const [, zipcodeQueryResult] = await organizerZipcode({ variables: { zipcode: value } });
    const currZipcodeQueryResult = zipcodeQueryResult.data?.zipCodeSearch;

    if(currZipcodeQueryResult) {
      info.form.setValue([], "city", currZipcodeQueryResult.city);
      info.form.setValue([], "state", currZipcodeQueryResult.state);
      info.form.setValue([], "timezone", currZipcodeQueryResult.timezone);
    }
  }, 250);

  return render();
}