import * as React from 'react'
import { useLocation, useHistory } from 'react-router-dom';

import { DeepPartial, Enrollment, MessageServiceKind } from 'app2/api';
import { ActionButton, dataTable, DataTableHandle, NotificationManager } from 'app2/components';

import { ThreadRecipient, ThreadStudentInfo } from '../../messages';
import { CourseSelections } from '../../course-page/generated';

interface Props<T> {
  course?:CourseSelections;
  table:React.MutableRefObject<DataTableHandle<T>>;
  to?:string;
}

type MessageEnrollment = DeepPartial<Pick<Enrollment, 'isInvitation' | 'parent' | 'family'>> & {student?:ThreadStudentInfo};

export function CreateMessageAction<T extends MessageEnrollment>(props:Props<T>) {
  const history = useHistory();
  const location = useLocation();

  function render() {
    if (!props.table || (props.course && !props.course?.behavior?.messages)) {
      return <></>
    }

    return <ActionButton icon="MessageCircle" autoLoader={false} tooltip="Send a message" onClick={onMessage}>Message</ActionButton>
  }

  function onMessage() {
    const table = dataTable(props.table.current);
    const enrollments = table.selectedItems.filter(e => !e.isInvitation);
    const hasInvites = table.selectedItems.length != enrollments.length;
    
    if (hasInvites && !enrollments.length) {
      NotificationManager.warning('Messages can not be sent to invites.');
      return
    }

    if (hasInvites) {
      NotificationManager.warning('Messages can not be sent to invites and have been filtered out of the recipient list');
    }

    const folders = props.to ? props.to.split('/') : location.pathname.split('/').slice(0, -1);
    folders.push('messages', 'compose');
    
    const to = folders.join('/');
    const parents = new Map<string, ThreadRecipient>();
    enrollments.forEach(e => {
      const familyParents = e.family?.parents?.length ? e.family?.parents : e.parent ? [e.parent] : [];
      familyParents.forEach(p => {
        if (!p.id) {
          return;
        }
        
        let parent = parents.get(p.id);

        if (!parent) {
          parent = {...p, students:[]};

          if (e.family?.id) {
            parent['entity'] = [{type: 'Family', id: e.family.id}]
          }

          parents.set(p.id, parent);
        }

        if (!parent.students.find(s => s.id == e.student.id)) {
          parent.students.push(e.student);
        }
      });
    });
    
    const recipients = Array.from(parents.values());
    history.push(to, {to: recipients, hideReplyAll: false, hideService: false, service: MessageServiceKind.Email})
  }

  return render();
}
