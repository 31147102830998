import { cloneDeep } from "lodash-es";

import { Command } from "../../undo";
import { Point } from "../../dom-utils";

import { DataTable, DataCellPositionIds } from "..";
import { ObservableCollectionCommand, CollectionEvent, CollectionEventType } from "../collection";

export class EditCellCommand<T> implements Command, ObservableCollectionCommand<T> {
  table:DataTable;
  ids:DataCellPositionIds;
  // because this is a datatable that works on objects, we save
  // the entire object for undoing because changing one value
  // can cause multiple values to change (for business logic reasons)
  item:T;
  oldItem:T;
  wasDirty:boolean;

  constructor(table:DataTable, pos:Point, item:T, oldItem:T, wasDirty:boolean) {
    this.table = table;
    this.ids = table.posToIds(pos);
    this.item = item;
    this.oldItem = oldItem;
    this.wasDirty = wasDirty;
  }

  get isCollectionCommand() {
    return true;
  }

  get collectionEvents():CollectionEvent<T>[] {
    const position = this.table.idsToPos(this.ids).row;

    return [{
      collection: this.table.data,
      type: CollectionEventType.update,
      position,
      id: this.ids.rowId,
      item: position != -1 ? this.table.data.getItem(position) : this.item
    }]
  }

  focus():void {
    const table = this.table;
    table.focus();
    table.setSelection(table.selection.clone().moveTo(table.idsToPos(this.ids)), true);
  }

  do():void {
    this.oldItem = this.setItem(this.item, true);
  }

  undo():void {
    this.item = this.setItem(this.oldItem, this.wasDirty);
  }

  redo():void {
    this.do();
  }

  setItem(item:T, dirty:boolean) {
    const table = this.table;
    const pos = table.idsToPos(this.ids, true);
    const col = table.allCols[pos.col];
    const oldItem = cloneDeep(table.data.getItem(pos.row));

    if (!col) {
      return oldItem;
    }

    if (!col.disabled) {
      this.table.editableForm.reset(pos.row, {values:item, dirty});
    }

    return oldItem;
  }
}
