import * as React from 'react'

import { ActionButton, DataTableHandle, dataTable, Modal } from 'app2/components';

import { GroupActionModal } from './GroupActionModal';

interface Props<T> {
  table:React.MutableRefObject<DataTableHandle<T>>;
  season:string;
}

export function GroupActionButton<T>(props:Props<T>) {
  const table = dataTable(props.table.current);

  return <ActionButton icon="Users" autoLoader={false} tooltip="Assign enrollments to a group" 
    onClick={() => Modal.show(<GroupActionModal enrollments={table.selections.selectedItems} season={props.season} />)}>Group</ActionButton>
}
