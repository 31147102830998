import { eventManager } from '../events';
import { ApiResponse, standardizeAxiosApiError } from "../error";
import { GuestCart } from '../parent';
import { urql } from '../urql';

import { axios } from './axios';
import { getUserFromResponse } from './getUserFromResponse';
import { checkForExternalAuthRequiredResult } from './checkForExternalAuthRequiredResult';

export interface LoginArgs {
  email:string;
  password:string;
}

export async function login(args:LoginArgs):Promise<ApiResponse> {
  try {
    const response = await axios.post('/auth/sign_in', args);

    if (checkForExternalAuthRequiredResult(response.data)) {
      return response.data;
    }

    urql.reset();
    GuestCart.clearToken();

    const user = getUserFromResponse(response.data.data)
    eventManager.identifyUser(user);
    eventManager.log('login');  
  
    return {success: true, errors: []};
  }
  catch(e) {
    return e?.response?.status === 404
      ? {success: false, errors: [{message: 'Incorrect email or password', path:['email']}]}
      : standardizeAxiosApiError(e)
  }
}
