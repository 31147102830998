import * as React from 'react'
import { debounce } from 'lodash-es';

import { checkForExternalAuthRequired } from 'app2/api';

export function useExternalAuthRequired() {
  const [authProvider, setAuthProvider] = React.useState<string>(null)

  const checkDomain = debounce(async (email:string) => {
    const domain = email.split('@')[1];
    let provider:string = null;

    if (domain) {
      provider = await checkForExternalAuthRequired(domain);
    }

    setAuthProvider(provider);
  }, 750)

  return { authProvider, checkDomain };
}
