import * as React from 'react';

import { DomainAuth, DomainOauthProvider } from 'app2/api'
import { DropdownField, enumToOptions, FormModel, InputField, Field, Panel, RepeatingSection, validateEmailDomain } from 'app2/components'

import { useDomainAuthsQuery, updateDomainAuths } from './generated'

export function DomainAuth() {
  const [result] = useDomainAuthsQuery();
  const domainAuths = result.data?.homeroom;

  function render() {
    return <Panel title='Domain authentication' type='toggle' onOk={onOk} initialValues={domainAuths}>
      <RepeatingSection name='domainAuths' add='Add domain' onRemove='archived' fields={[
        <Field label='Domain' name='domain' required component={InputField} validators={validateEmailDomain} />,
        <Field label='Provider' name='provider' required component={DropdownField} options={providerOptions} />,
        'remove'
      ]} />
    </Panel>
  }

  async function onOk(form:FormModel<{domainAuths:DomainAuth[]}>) {
    return await updateDomainAuths({variables: {domains: form.values.domainAuths}})
  }

  return render();
}

const providerOptions = enumToOptions(DomainOauthProvider)