import * as React from 'react';
import { flatten } from 'lodash-es';

import { EnrollmentUtils, PriceConfigKind, RecurringPriceConfig, SeasonPriceConfig } from 'app2/api';
import { Body, Box, Checkbox, Img, Field, FieldRendererProps, Form, OptionsMenu, MenuItem, Repeater, Subtitle2, useForm, VBox, NotificationManager, copyToClipboard, theme } from 'app2/components';
import { ParentPage, CourseSchedule } from 'app2/views';
import MaintenanceIllustration from 'images/maintenance_illustration.png';

import { useFamilyQuery, useParentPreference } from '../shared';

import { parentRemoveFromWaitlist, FamilyScheduleSelections, useFamilyScheduleQuery } from './generated';
import { EnrollmentSession } from './EnrollmentSession';
import { EnrollmentsTable } from './EnrollmentsTable';

const colors = [theme.colors.courseDay, '#FFDFAE', '#EBE5FF', '#BFE5DD'];

export function Schedule() {
  const [showWeekends, setShowWeekends] = useParentPreference('showWeekends');
  const {family, fetching} = useFamilyQuery(useFamilyScheduleQuery);
  const students = getStudents();
  const enrollments = getEnrollments();
  const form = useForm(() => ({students}), [students]);
  const unfilteredCurses = getCourses(false);
  const courses = getCourses(true);

  function render() {
    return <ParentPage title='Schedule' actions={renderOptions()}>
      {fetching || !family
        ? <></>
        : unfilteredCurses.length == 0
          ? renderNoEnrollments()
          : renderEnrollments()
      }
    </ParentPage>
  }

  function renderNoEnrollments() {
    return <VBox hAlign='center' mt='$40'>
      <Img src={MaintenanceIllustration} width='320px' mb='$16' />
      <Body>You don't have any enrollments yet. Your schedule will be updated when you enroll in your first activity.</Body>
    </VBox>
  }

  function renderEnrollments() {
    return <>
      <Subtitle2 mb='$12'>Show</Subtitle2>
      {renderStudentFilter()}
      {renderSchedule()}
      <EnrollmentsTable family={family} />
    </>
  }

  function renderOptions() {
    if (unfilteredCurses.length == 0) {
      return null;
    }

    return <OptionsMenu label='Add to calendar'>
      <MenuItem label="Add to my default calendar app" to={getCalendarHref()} />
      <MenuItem label="Copy calendar link" onClick={copy} />
    </OptionsMenu>
  }

  function renderStudentFilter() {
    return <Form mb='$30' form={form} onNavigation='nothing'>
      <Repeater name='students' layout='vbox' gap='$8'>
        <Field name='show' component={Checkbox} render={renderStudentCheckbox} />
      </Repeater>
    </Form>
  }

  function renderStudentCheckbox(props:FieldRendererProps) {
    const student = props.info.record.student;
    const color = props.info.record.color;

    return <Checkbox {...props.componentProps} label={<>
      <Box width='16px' height='16px' borderRadius='8px' background={color} mr='$8' />
      {student.name}
    </>} />
  }

  function renderSchedule() {
    return <CourseSchedule courses={courses} weekends={showWeekends} onChangeWeekends={setShowWeekends} week='first'
      scheduleUrlBase='/family/schedule' activityUrlBase='/activities' session={EnrollmentSession} mb='$30' />
  }

  // this returns a list of students, their associated color and if they are showing
  function getStudents() {
    const students = family?.students;

    return React.useMemo(() => {
      return students?.map((student, index) => ({student, show: true, color:colors[index % colors.length]}))
    }, [students]);
  }

  function getEnrollments() {
    const students = family?.students;
    return flatten(students?.map(student => student.enrollments.map(enrollment => ({ ...enrollment, student }))));
  }

  async function removeFromWaitlist(enrollmentId: string) {
    const enrollment = enrollments.find(enrollment => enrollment.id === enrollmentId);
    const [success] = await parentRemoveFromWaitlist({
      variables: { ids: [enrollmentId] },
      successMsg: 'Removed from waitlist',
      warning: { title: 'Remove from waitlist', content: `Are you sure you want to remove ${enrollment.student.name} from ${enrollment.course.name}?`, ok: 'Continue' }
    });
    return success;
  }

  // filters to the showing students, maps their enrollments to scheduledcourses
  function getCourses(onlyShown:boolean) {
    if (!form.values.students) {
      return [];
    }

    return flatten(form.values.students
      .filter(s => !onlyShown || s.show)
      .map(s => s.student.enrollments.map(e => {
        return {
          ...getModifiedCourseForPriceConfig(e), 
          enrollmentId: e.id,
          color: s.color,
          waitlisted: EnrollmentUtils.waitlistFinalized(e),
          removeFromWaitlist,
          priceConfig: {kind: e.priceConfig.kind}
        }
      })
    ))
  }

  // because our schedule component currently works off courses, this creates a
  // fake course with modified to match the enrollment configuration
  // - makes course days match recurring config
  // - makes start/end dates match dropin config
  function getModifiedCourseForPriceConfig(e:EnrollmentSelections) {
    if (e.priceConfig?.kind == PriceConfigKind.DropIn) {
      return {
        ...e.course,
        startDate: e.startDate,
        endDate: e.startDate,
      }
    }

    if (e.priceConfig?.kind == PriceConfigKind.Recurring || (e.priceConfig?.kind == PriceConfigKind.Season && (e.priceConfig as SeasonPriceConfig).weekdays?.length)) {
      return {
        ...e.course,
        courseDays: mapCourseDaysToEnrollmentDays(e),
      }
    }

    return e.course;
  }

  function mapCourseDaysToEnrollmentDays(e:EnrollmentSelections) {
    const enrollmentWithDays  = e.priceConfig as (RecurringPriceConfig | SeasonPriceConfig);

    return e.course.courseDays.
      filter(cd => cd.days.find(d => enrollmentWithDays.weekdays.includes(d)) != null).
      map(cd => ({
        ...cd,
        days: enrollmentWithDays.weekdays
      })
    )
  }

  function getCalendarHref() {
    const calendarUrl = new URL(`${process.env.BASE_URL}/calendar/${family?.user?.id}/${family?.user?.personalCalendar}`);
    const calendarHref = calendarUrl.toString().replace(/^https?:/, 'webcal:');

    return calendarHref;
  }

  function copy() {
    copyToClipboard(getCalendarHref())
    NotificationManager.add({type: 'success', message: 'Copied calendar link to clipboard'});
  }

  return render();
}

type StudentSelections = FamilyScheduleSelections['students'][0];
type EnrollmentSelections = StudentSelections['enrollments'][0];

interface Enrollment extends EnrollmentSelections {
  student: StudentSelections;
}