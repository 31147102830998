import { CurrencyField, formatCurrency } from 'app2/components';

export const enrollmentFields = [
  {
    ...CurrencyField,
    label: 'Subtotal',
    name: 'listPrice',
  },  
  {
    ...CurrencyField,
    label: 'Discount',
    name: 'discountAmount',
    format: (value:number) => formatCurrency(Math.abs(value)),
  },
  {
    ...CurrencyField,
    label: 'Billed amount',
    name: 'purchaseAmount',
  },
  {
    ...CurrencyField,
    label: 'Amount refunded',
    name: 'refundsTotal',
  },
  {
    ...CurrencyField,
    label: 'Final amount',
    name: 'balance',
  },
]
