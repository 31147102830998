import * as React from 'react'

import { Threads as Threads } from 'app2/views/shared'

import { UserPage } from '../UserPage';

export function UserMessages() {
  return <UserPage title='Messages'>
    <Threads role='user' labelOrParticipant={{type: 'User'}} entityMessages={false} />
  </UserPage>
}
