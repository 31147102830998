import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SpotsLeftByDayQueryVariables = Types.Exact<{
  course: Types.Scalars['ID'];
}>;

export type SpotsLeftByDayQuery = { course?: { spotsLeftByDay?: { [key: string]: any } | null | undefined; allDaysFull?: boolean | null | undefined } | null | undefined };

export const SpotsLeftByDayDocument = gql`
  query SpotsLeftByDay($course: ID!) {
    course(course: $course) {
      spotsLeftByDay
      allDaysFull
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SpotsLeftByDayArgs = MakeOptional<UseQueryArgs<SpotsLeftByDayQueryVariables, SpotsLeftByDayQuery>, 'query'>;

export function useSpotsLeftByDayQuery(options: SpotsLeftByDayArgs = {}) {
  return useQuery<SpotsLeftByDayQueryVariables, SpotsLeftByDayQuery>({ query: SpotsLeftByDayDocument, ...options });
}

export type SpotsLeftByDayOptions = ExecQueryOptions<SpotsLeftByDayQueryVariables>;

export function spotsLeftByDay(options: SpotsLeftByDayOptions) {
  return executeQuery<SpotsLeftByDayQuery, SpotsLeftByDayQueryVariables>(SpotsLeftByDayDocument, options);
}

export type SpotsLeftByDaySelections = SpotsLeftByDayQuery['course'];
