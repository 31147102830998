import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SeasonPendingPayoutsTotalQueryVariables = Types.Exact<{
  season: Types.Scalars['ID'];
}>;

export type SeasonPendingPayoutsTotalQuery = { season?: { pendingPayoutsTotal?: number | null | undefined } | null | undefined };

export const SeasonPendingPayoutsTotalDocument = gql`
  query SeasonPendingPayoutsTotal($season: ID!) {
    season(season: $season) {
      pendingPayoutsTotal
    }
  }
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type SeasonPendingPayoutsTotalArgs = MakeOptional<UseQueryArgs<SeasonPendingPayoutsTotalQueryVariables, SeasonPendingPayoutsTotalQuery>, 'query'>;

export function useSeasonPendingPayoutsTotalQuery(options: SeasonPendingPayoutsTotalArgs = {}) {
  return useQuery<SeasonPendingPayoutsTotalQueryVariables, SeasonPendingPayoutsTotalQuery>({ query: SeasonPendingPayoutsTotalDocument, ...options });
}

export type SeasonPendingPayoutsTotalOptions = ExecQueryOptions<SeasonPendingPayoutsTotalQueryVariables>;

export function seasonPendingPayoutsTotal(options: SeasonPendingPayoutsTotalOptions) {
  return executeQuery<SeasonPendingPayoutsTotalQuery, SeasonPendingPayoutsTotalQueryVariables>(SeasonPendingPayoutsTotalDocument, options);
}

export type SeasonPendingPayoutsTotalSelections = SeasonPendingPayoutsTotalQuery['season'];
