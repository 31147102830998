import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ParentCheckOutMutationVariables = Types.Exact<{
  contentsChecksum?: Types.InputMaybe<Types.Scalars['String']>;
  paymentService?: Types.InputMaybe<Types.PaymentService>;
  paymentMethod?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ParentCheckOutMutation = { cartCheckout?: { success?: boolean | null | undefined; statementDescriptor?: string | null | undefined } | null | undefined };

export const ParentCheckOutDocument = gql`
  mutation ParentCheckOut($contentsChecksum: String, $paymentService: PaymentService, $paymentMethod: String) @urql(additionalTypenames: ["Cart", "User"]) {
    cartCheckout(contentsChecksum: $contentsChecksum, paymentService: $paymentService, paymentMethod: $paymentMethod) {
      success
      statementDescriptor
    }
  }
`;

import { executeMutation, ExecMutationOptions } from 'app2/views/shared/urql';

export type ParentCheckOutOptions = ExecMutationOptions<ParentCheckOutMutationVariables>;

export function parentCheckOut(options: ParentCheckOutOptions) {
  return executeMutation<ParentCheckOutMutation, ParentCheckOutMutationVariables>(ParentCheckOutDocument, {
    ...options,
    context: { ...options.context, additionalTypenames: (options.context?.additionalTypenames || []).concat(['Cart', 'User']) }
  });
}
