import * as React from 'react'

import { Button, formatCurrency, Modal, NotificationManager, HBox, SaveableResult, VBox } from 'app2/components';

import { useCurrentSeason } from '../../shared';

import { seasonPendingPayoutsTotal, seasonProcessPayouts } from './generated';

export function ProcessPayouts() {
  const season = useCurrentSeason();
  const [requested, setRequested] = React.useState(false);

  function render() {
    return !requested ? renderNotRequested() : renderRequested();
  }

  function renderNotRequested() {
    return <Button onClick={showPending}>Request payout</Button>
  }

  function renderRequested() {
    return <Button kind='secondary' disabled>Payout requested</Button>
  }

  async function showPending() {
    const [success, result] = await seasonPendingPayoutsTotal({variables: {season: season.id}})

    if (result.data.season.pendingPayoutsTotal) {
      showPendingWithNonZero(result.data.season.pendingPayoutsTotal)
    }
    else {
      showPendingWithZero();
    }
  }

  async function showPendingWithNonZero(total:number) {
    const modalResult = await Modal.show(<Modal title='Request payout'>
      <VBox  text='body' gap='$12'>
        <HBox><b>Amount to be paid out: {formatCurrency(total)}</b></HBox>
        <span>Payouts are processed securely and may take several business days to appear in your account.</span>
        <span>Would you like to proceed with this request?</span>
      </VBox>
    </Modal>)

    if (modalResult.action == SaveableResult.cancel) {
      return;
    }

    processPayouts();
  }

  async function showPendingWithZero() {
    Modal.show(<Modal title='Request payout' text='body' ok={null} cancel='Close'>
      You currently have no available funds to request a payout.
    </Modal>)
  }
  
  async function processPayouts() {
    const [success, result] = await seasonProcessPayouts({variables: {season: season.id}})

    if (!success) {
      return;
    }

    setRequested(true);
    NotificationManager.success('Payout requested');
  }

  return render();
}
