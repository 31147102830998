import * as React from 'react';
import pluralize from 'pluralize';

import { Panel, Section, NumberField, FormModel, useForm } from 'app2/components';

import { useCurrentSite } from '../shared';
import { organizerSiteUpdateGeneralInfo, OrganizerSiteUpdateGeneralInfoMutationVariables } from '../general-info/gql';

export function RegistrationWindow() {
  const { site } = useCurrentSite();
  const form = useForm({ cartExpirationTimeMinutes: site?.cartExpirationTimeMinutes }, [site?.cartExpirationTimeMinutes]);

  function render() {
    return (
      <Panel icon="Clock" title="Registration time limit" type="toggle" form={form} subtitle="Set how long families have to complete their registration after adding the first item to their cart." onOk={onOk}>
        <Section name="cartExpirationTimeMinutes" label="Time limit (minutes)" component={NumberField} format={(value: number) => `${value} ${pluralize('minute', value)}`} validators={validateLimit} />
      </Panel>
    );
  }

  async function onOk(form: FormModel<{ cartExpirationTimeMinutes: number }>) {
    const variables: OrganizerSiteUpdateGeneralInfoMutationVariables = { siteId: site.id, cartExpirationTimeMinutes: form.values.cartExpirationTimeMinutes };
    const [success] = await organizerSiteUpdateGeneralInfo({ variables, successMsg: 'Registration time limit updated.', error: form });

    return success;
  }

  function validateLimit(value: number) {
    return value < 5 || value > 60 ? 'Must be between 5 and 60 minutes.' : null;
  }

  return render();
}
