import * as React from 'react'

import { PayoutTiming } from 'app2/api';
import { FormModel, Panel, Section } from 'app2/components';
import { SharedPayoutConfig  } from 'app2/views';

import { useCurrentSeason, useCurrentSite } from '../../shared';
import { organizerUpsertSeason } from '../gql';

import { ProcessPayouts } from './ProcessPayouts';
import { useSeasonPayoutConfigQuery } from './generated';

export function SeasonPayoutConfig() {
  const { site } = useCurrentSite();
  const season = useCurrentSeason();
  const [seasonSettings] = useSeasonPayoutConfigQuery({variables: {season: season?.id}, pause: !site?.hasSiteCompanies || !season?.id});
  
  function render() {
    if (!site?.hasSiteCompanies) {
      return <></>
    }

    return <Panel title='Payouts' type='toggle' initialValues={seasonSettings?.data?.season} onOk={onOk}>
      <SharedPayoutConfig />
      {seasonSettings?.data?.season?.payoutConfig?.timing == PayoutTiming.Pause && <Section mode='display' inner={{hAlign:'left'}}><ProcessPayouts /></Section>}
    </Panel>
  }

  async function onOk(form:FormModel) {
    await organizerUpsertSeason({ variables: {attributes:{...form.values, id: season.id}}, successMsg: 'Season saved'});
  }

  return render();
}
